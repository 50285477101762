import { ClientType } from '@/interfaces/common';
import { MaybeRef, computed, unref } from 'vue';
import airportData, { airportCodes } from '@/js/data/airports';

export const useAirportOptions = (client: MaybeRef<ClientType>) => {
	const airportOptions = computed(
		() => airportCodes[unref(client)]
			.split(',')
			.map((code: string) => {
				const { label, value } = airportData.find((airport) => airport.value === code)!;
				return {
					label,
					value
				};
			})
	);

	return {
		airportOptions
	};
};
