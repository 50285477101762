<template>
	<section class="no-offers-found">
		<p class="no-offers-found__empty">
			<span class="no-offers-found__empty-title">Ihre Suche ergab leider keine Ergebnisse.</span>
		</p>
		<div class="no-offers-found__inner-wrap">
			<div
				class="no-offers-found__empty-desc"
				@click.prevent="openSearch"
			>
				<p>Bitte ändern Sie Ihre Angaben (z.B. den Reisetermin) oder schränken Sie Ihre Suche weniger ein.</p>
			</div>
			<RoundedButton
				v-if="!isDesktop"
				:theme="'outlined'"
				class="no-offers-found__button"
				v-bind="$attrs"
				@click.prevent="openFilters"
			>
				Filter öffnen
			</RoundedButton>
		</div>
	</section>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const emit = defineEmits(['OpenFilters', 'OpenSearch']);

function openSearch() {
	emit('OpenSearch');
}

function openFilters() {
	emit('OpenFilters');
}

</script>

<style lang="scss" scoped>
.no-offers-found {
	margin-bottom: 2rem;
	padding: 2rem;
	border: 0.1rem solid $color-warning;
	border-radius: 1.9rem;
	width: 100%;

	@media screen and (min-width: $breakpoint-large){
		padding: 1.5rem 2rem;
	}

	.no-offers-found__empty {
		font-size: $font-small-3;
		display: flex;
		flex-direction: column;
		margin-bottom: 0;

	}

	.no-offers-found__empty-title {
		display: block;
		font-size: 2.6rem;
		font-weight: $font-weight-semibold;
		font-family: $font-family-special;
		margin-bottom: 1.6rem;
	}

	.no-offers-found__inner-wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.no-offers-found__empty-desc {
		color: $color-primary;
		font-family: $font-family;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-decoration: underline;
		margin-bottom: 2.6rem;
		cursor:pointer;

		@media screen and (min-width: $breakpoint-small) {
			margin-bottom: 1.5rem;
		}

		@media screen and (min-width: $breakpoint-extralarge) {
			font-size: $font-medium-3;
			font-style: italic;
			text-decoration: initial;
			margin-bottom: 0;
			color: $color-text;
			cursor: auto;
		}

		p {
			margin-bottom: 0.5rem;
		}
	}

	.no-offers-found__button {
		border-color: $color-primary-l4;
		max-width: 12.9rem;
		height: 3.3rem;
		font-size: $font-small-2;
		padding: 0;
	}

	@include media-query-up($breakpoint-large) {
		.no-offers-found__empty {
			font-size: $font-small-1;
		}

		.no-offers-found__empty-title {
			display: inline-block;
		}
	}
}
</style>
