import { computed } from 'vue';
import { useStore } from '@/components/common/store';
import { CheckoutValidationState, FieldValidation } from './checkout';

const store = useStore();

export const ignorePropertiesForCustomer = ['gender-0', 'person-field-one-0', 'person-field-two-0', 'birth-date-0'];

export const InvalidFields = computed<FieldValidation>(() => {
	const isSepaPayment: boolean = store.getters['checkout/isSepaPayment'];
	const isBankTransfer: boolean = store.getters['checkout/isBankTransfer'];
	return Object
		.entries(CheckoutValidationState.value)
		.flatMap((entry) => {
			if (entry[1].valid || !entry[1].validated) {
				return [];
			}
			if (entry[0].includes('insurance-payment')) {
				if (typeof store.getters['checkout/insuranceOffer'] === 'boolean') {
					return [];
				}
			}
			if (!isSepaPayment && entry[0].includes('sepa')) {
				return [];
			}
			if (isSepaPayment && entry[0].includes('insurance-payment')) {
				return [];
			}
			if (
				(isSepaPayment || isBankTransfer)
				&& entry[0] === 'credit-card-radio-group'
			) {
				return [];
			}
			if (store.getters['checkout/isCustomerTraveller'] && ignorePropertiesForCustomer.some((key) => entry[0] === key)) {
				return [];
			}
			return [{ name: entry[0] }];
		});
});

export const validateCheckoutState = (): void => {
	Object.entries(CheckoutValidationState.value).forEach((field) => {
		field[1].validated = true;
	});
};
