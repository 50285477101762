import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "airport-filter__not-found"
}
const _hoisted_2 = { class: "airport-filter__checkbox-label" }
const _hoisted_3 = { class: "airport-filter__checkbox-label--strong" }

import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import CheckBox from '@lmt-rpb/CheckBox/CheckBox.vue';

import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { ClientType } from '@/interfaces/common';
import { useAirportOptions } from '../../composables/airportOptions';
import { useFilterArray } from '../../composables/filterArray';

type Props = {
	disabled?: boolean
	client?: ClientType
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AirportFilter',
  props: {
    disabled: { type: Boolean },
    client: { default: 'at' }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const { airportOptions } = useAirportOptions(props.client);

const {
	filterExpression,
	filteredItems,
	notFoundMessage,
} = useFilterArray(airportOptions, ['value', 'label']);

const selectedOptions = computed(() => store.state.searchMask?.departure ?? []);

const selectedOptionsProxy = ref(selectedOptions.value);
const applyChanges = () => {
	store.commit('searchMask/updateFormData', { departure: selectedOptionsProxy.value });
};
const cancel = () => {
	selectedOptionsProxy.value = store.state.searchMask?.departure ?? [];
};

const clearSelectedOptions = () => {
	selectedOptionsProxy.value = [];
};

const selectedOptionsConcat = computed(() => airportOptions.value
	.filter((airport) => selectedOptions.value.includes(airport.value))
	.map((airport) => airport.label)
	.join(', '));

watch(selectedOptions, () => {
	selectedOptionsProxy.value = selectedOptions.value;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DropdownFilterType, {
    "search-term": _unref(filterExpression),
    "onUpdate:searchTerm": _cache[1] || (_cache[1] = ($event: any) => (_isRef(filterExpression) ? (filterExpression).value = $event : null)),
    class: "airport-filter",
    title: "Abflughafen",
    icon: "flightStart",
    "modal-title": "Flughafen",
    "apply-button-text": "Übernehmen",
    "cancel-button-text": "Verwerfen",
    "with-clear-button": selectedOptionsProxy.value.length > 0,
    "selected-value": selectedOptionsConcat.value,
    "with-menu-header": false,
    "button-disabled": !!_unref(notFoundMessage),
    "show-buttons": "",
    searchable: "",
    "emit-on-ok": "",
    "onDropdownFilterType:apply": applyChanges,
    "onDropdownFilterType:abort": cancel,
    "onDropdownFilterType:clear": clearSelectedOptions
  }, {
    default: _withCtx(() => [
      (_unref(notFoundMessage))
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_unref(notFoundMessage)), 1 /* TEXT */))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(filteredItems), (airport) => {
            return (_openBlock(), _createBlock(CheckBox, {
              key: airport.value,
              modelValue: selectedOptionsProxy.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOptionsProxy).value = $event)),
              label: airport.label,
              "custom-value": airport.value,
              class: "airport-filter__checkbox"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, [
                  _createElementVNode("b", _hoisted_3, _toDisplayString(airport.label), 1 /* TEXT */),
                  _createTextVNode("  (" + _toDisplayString(airport.value) + ") ", 1 /* TEXT */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "label", "custom-value"]))
          }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["search-term", "with-clear-button", "selected-value", "button-disabled"]))
}
}

})