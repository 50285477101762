import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mode-switch-mini" }
const _hoisted_2 = {
  class: "mode-switch-mini__icon",
  viewBox: "0 0 53 32"
}
const _hoisted_3 = { class: "mode-switch-mini__value" }
const _hoisted_4 = ["aria-label"]

import SearchModeSwitchSimple from '@lmt-rpb/SearchModeSwitchSimple/SearchModeSwitchSimple.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { computed, ref, watch } from 'vue';

interface Props {
	modelValue?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchModeSwitchMini',
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const progress = ref(0);

watch(() =>	model.value, () => {
	progress.value = model.value ? 100 : 0;
});

const checkedStyle = computed((): Record<string, any> => ({
	opacity: progress.value / 100
}));

const uncheckedStyle = computed((): Record<string, any> => ({
	opacity: (100 - progress.value) / 100
}));

const searchLabel = computed((): string => getLocaleString('package'));

const onDrag = (progressNum: number) => {
	progress.value = progressNum;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchModeSwitchSimple, _mergeProps({
      value: model.value,
      class: "mode-switch-mini__switch",
      "draggable-aria-label": 'Mini Mode Switch Handle'
    }, _ctx.$attrs, { onDrag: onDrag }), {
      left: _withCtx(() => [
        (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("title", null, _toDisplayString(searchLabel.value), 1 /* TEXT */),
          _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M52.403 15.729c-0.456-1.671-1.961-2.88-3.748-2.88-0.358 0-0.704 0.048-1.032 0.139l0.027-0.006-13.769 3.691-17.878-16.671-5.001 1.346 10.726 18.589-12.877 3.446-5.089-4.003-3.758 1.011 4.714 8.169 1.973 3.437 42.963-11.512c1.673-0.455 2.883-1.961 2.883-3.749 0-0.358-0.049-0.705-0.139-1.035l0.006 0.027z" }, null, -1 /* HOISTED */))
        ])),
        _cache[1] || (_cache[1] = _createElementVNode("svg", {
          class: "mode-switch-mini__icon-small",
          viewBox: "0 0 28 32"
        }, [
          _createElementVNode("title", null, "plus"),
          _createElementVNode("path", { d: "M16.313 27.188c0 1.438-1.125 2.563-2.563 2.563v0c-1.438 0-2.563-1.125-2.563-2.563v-22.375c0-1.438 1.125-2.563 2.563-2.563v0c1.438 0 2.563 1.125 2.563 2.563v22.375zM2.563 18.563c-1.438 0-2.563-1.125-2.563-2.563v0c0-1.438 1.125-2.563 2.563-2.563h22.375c1.438 0 2.563 1.125 2.563 2.563v0c0 1.438-1.125 2.563-2.563 2.563h-22.375z" })
        ], -1 /* HOISTED */)),
        _cache[2] || (_cache[2] = _createElementVNode("svg", {
          class: "mode-switch-mini__icon",
          viewBox: "0 0 32 32"
        }, [
          _createElementVNode("title", null, "Nur Hotel"),
          _createElementVNode("path", { d: "M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" })
        ], -1 /* HOISTED */))
      ]),
      right: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("svg", {
          class: "mode-switch-mini__icon",
          viewBox: "0 0 32 32"
        }, [
          _createElementVNode("title", null, "Nur Hotel"),
          _createElementVNode("path", { d: "M9.343 17.345c2.209-0.002 4-1.793 4-4.003 0-2.211-1.792-4.003-4.003-4.003s-4.003 1.792-4.003 4.003c0 0.001 0 0.001 0 0.002v-0c0 0.001 0 0.002 0 0.003 0 2.208 1.79 3.998 3.998 3.998 0.003 0 0.006 0 0.009 0h-0zM25.361 9.336h-10.685v9.339h-10.673v-12.004h-2.669v20.014h2.669v-3.996h24.015v4.003h2.669v-12.012c0-0.001 0-0.003 0-0.005 0-2.946-2.389-5.335-5.335-5.335-0.001 0-0.002 0-0.003 0h0z" })
        ], -1 /* HOISTED */)
      ])),
      _: 1 /* STABLE */
    }, 16 /* FULL_PROPS */, ["value"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", {
        class: "mode-switch-mini__value-label",
        style: _normalizeStyle(checkedStyle.value),
        "aria-label": "Nur Hotel"
      }, "Nur Hotel", 4 /* STYLE */),
      _createElementVNode("span", {
        class: "mode-switch-mini__value-label",
        style: _normalizeStyle(uncheckedStyle.value),
        "aria-label": searchLabel.value
      }, _toDisplayString(searchLabel.value), 13 /* TEXT, STYLE, PROPS */, _hoisted_4)
    ])
  ]))
}
}

})