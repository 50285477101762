<template>
	<div
		class="gallery-modal"
		:class="{'is-visible': model}"
	>
		<div class="gallery-modal__header">
			<img
				class="gallery-modal__logo"
				:alt="altTextForLogo"
				:src="modalLogo"
			>

			<RoundedButton
				class="gallery-modal__close-button"
				theme="outlined"
				aria-label="Close"
				@click="close"
			>
				<BaseIcon
					name="close"
					class="gallery-modal__close-icon"
				/>
			</RoundedButton>
		</div>
		<div class="gallery-modal__body">
			<div class="gallery-modal__main-slides">
				<Carousel
					id="gallery"
					ref="mainCarousel"
					v-model="carouselIndex"
					class="gallery-modal__main-carousel"
					v-bind="mainCarouselSettings"
				>
					<Slide
						v-for="(image, slideIndex) in images"
						:key="slideIndex"
					>
						<img
							class="gallery-modal__main-image"
							:src="image.URL"
							:alt="`${image.Caption} - ${slideIndex}`"
							:title="`© GIATA GmbH 1996 - ${new Date().getFullYear()}`"
						>
						<span class="gallery-modal__copyright">&copy; GIATA GmbH 1996 - {{ new Date().getFullYear() }}</span>
					</Slide>
					<template #addons>
						<CarouselNavigation>
							<template #prev>
								<span class="gallery-modal__aria-label">prev</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__prev-icon"
								/>
							</template>
							<template #next>
								<span class="gallery-modal__aria-label">next</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__next-icon"
								/>
							</template>
						</CarouselNavigation>
					</template>
				</Carousel>
			</div>

			<div
				v-if="images"
				class="gallery-modal__thumb-slides"
			>
				<Carousel
					id="thumbnails"
					ref="thumbnailCarousel"
					v-model="carouselIndex"
					class="is-thumbnail"
					v-bind="thumbCarouselSettings"
				>
					<Slide
						v-for="(image, imageIndex) in images"
						:key="imageIndex"
					>
						<img
							class="gallery-modal__image"
							:class="carouselIndex === imageIndex ? 'is-active' : ''"
							:src="image.Preview.URL"
							:alt="`${image.Caption} - ${index}`"
							@click="slideTo(imageIndex)"
						>
					</Slide>

					<template #addons>
						<CarouselNavigation>
							<template #prev>
								<span class="gallery-modal__aria-label">prev</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__prev-icon is-thumb"
								/>
							</template>
							<template #next>
								<span class="gallery-modal__aria-label">next</span>
								<BaseIcon
									name="chevron"
									class="gallery-modal__next-icon is-thumb"
								/>
							</template>
						</CarouselNavigation>
					</template>
				</Carousel>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';
import { getWebsiteLogoPath, getWebsiteName } from '@utils/environmentUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { HotelCatalogueImageType } from '@interfaces/hotel-catalogue-types';
import {
	computed, nextTick, ref, watch, onBeforeUnmount,
} from 'vue';
import type { ClientType } from '@/interfaces/common';
import 'vue3-carousel/dist/carousel.css';

interface Props {
	images: HotelCatalogueImageType[],
	index?: number,
	modelValue?: boolean,
	client?: ClientType,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: false,
	index: 0,
	client: 'at',
});

const emit = defineEmits(['slide', 'update:modelValue', 'close']);

const carouselIndex = ref(props.index);

const mainCarouselSettings = {
	snapAlign: 'start',
	breakpoints: {
		1200: { // breakpoint-large
			touchDrag: false,
		}
	}
};

const model = computed({
	get() {
		return props.modelValue;
	},

	set(value: boolean) {
		emit('update:modelValue', value);
	}
});

const thumbCarouselSettings = ref({
	itemsToShow: 3,
	snapAlign: 'center',
	breakpoints: {
		992: { // breakpoint-medium
			itemsToShow: 6,
		},
		1200: { // breakpoint-large
			itemsToShow: 10,
			touchDrag: false,
			snapAlign: ''
		},
		1500: {
			itemsToShow: 12,
			snapAlign: ''
		}
	}
});

const mainCarousel = ref<typeof Carousel>();

const thumbnailCarousel = ref<typeof Carousel>();

const altTextForLogo = getWebsiteName(props.client);

const modalLogo = computed((): string => `${getWebsiteLogoPath(props.client)}/img/logo.svg`);

const slideTo = (val: number) => {
	carouselIndex.value = val;
};

const slideNext = () => {
	slideTo(Math.min(carouselIndex.value + 1, props.images.length - 1));
};

const slidePrev = () => {
	slideTo(Math.max(carouselIndex.value - 1, 0));
};

const close = () => {
	emit('close');
};

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'ArrowRight') {
		slideNext();
	}
	if (event.key === 'ArrowLeft') {
		slidePrev();
	}
	if (event.key === 'Escape') {
		close();
		window.removeEventListener('keyup', handleKeyup);
	}
};

watch(() => model.value, () => {
	if (model.value) {
		nextTick(() => { // one tick delay
			mainCarousel.value?.updateSlideWidth();
			thumbnailCarousel.value?.updateSlideWidth();
			document.body.classList.add('gallery-open');
		});
		window.addEventListener('keyup', handleKeyup);
	} else {
		document.body.classList.remove('gallery-open');
		window.removeEventListener('keyup', handleKeyup);
	}
});

watch(() => props.index, () => {
	carouselIndex.value = props.index;
});

onBeforeUnmount(() => {
	document.body.classList.remove('gallery-open');
	window.removeEventListener('keyup', handleKeyup);
});
</script>

<style lang="scss" scoped>
.carousel {
	height: 100%;

	&.is-thumbnail {
		position: static;
	}

	:deep(.carousel__viewport),
	:deep(.carousel__track) {
		height: 100%;
	}

	:deep(.carousel__slide) {
		position: relative;
	}

	:deep(.carousel__prev),
	:deep(.carousel__next) {
		margin: 0;

		@include visible-from($breakpoint-medium);

	}

	:deep(.carousel__prev--disabled),
	:deep(.carousel__next--disabled) {
		display: none;
	}
}

.gallery-modal {
	position: fixed;
	z-index: 100000;
	inset: 0;
	display: none;
	flex-direction: column;
	background: $color-white;

	&.is-visible {
		display: flex;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5rem 1rem;
		height: 9rem;
		max-height: 12%;
		min-height: 7rem;
		border-bottom: 0.1rem solid $color-border;
	}

	&__logo {
		width: auto;
		height: 100%;
	}

	&__close-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;

		&:hover,
		&:active,
		&:focus {
			background: $color-white;
		}
	}

	&__close-icon {
		width: 2.5rem;
		height: 2.5rem;
		fill: currentcolor;
	}

	&__body {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__main-slides {
		height: 100%;

		:deep(.carousel__prev),
		:deep(.carousel__next) {
			width: 12rem;
			height: 100%;
			background: $color-white-t5;
			color: $color-primary;
		}
	}

	&__aria-label {
		@include sr-only;
	}

	&__prev-icon,
	&__next-icon {
		width: 4rem;
		height: 4rem;
		fill: $color-primary;

		&.is-thumb {
			width: 2rem;
			height: 2rem;
		}
	}

	&__prev-icon {
		transform: rotate(-90deg);
	}

	&__next-icon {
		transform: rotate(90deg);
	}

	&__main-image {
		position: absolute;
		width: 100%;
		object-fit: cover;
	}

	&__copyright {
		position: absolute;
		right: 0;
		bottom: 1rem;
		padding: 0.5rem 1rem;
		background: $color-white;
		font-size: $font-small-3;
		color: $color-dark-gray;
	}

	&__thumb-slides {
		@include media-query-up($breakpoint-large) {
			padding: 0 5rem;
		}

		& {
			position: relative;
			padding: 0;
			border-top: solid 0.1rem $color-border;
		}

		.carousel {
			height: 12rem;
		}

		.carousel__slide {
			padding: 0 0.5rem;
			text-align: center;
			cursor: pointer;

			// TODO: not working correctly. First and last item are never active
			// &:not(.carousel__slide--active) {
			// 	opacity: 0.5;
			// }
		}
	}

	&__image {
		width: 100%;
		object-fit: cover;

		&:not(.is-active) {
			opacity: 0.5;
		}
	}
}

@media screen and (min-width: $breakpoint-small) {
	.gallery-modal {
		&__thumb-slides {
			.carousel {
				height: 20rem;
				padding-bottom: 2rem;
			}
		}
	}
}

@media screen and (min-width: $breakpoint-large) {
	.gallery-modal {
		&__main-carousel {
			width: 100%;
			max-width: 100rem;
			margin-inline: auto;

			:deep(.carousel__viewport) {
			}

			:deep(.carousel__track) {
				width: 100%;
			}

			:deep(.carousel__prev),
			:deep(.carousel__next) {
				position: fixed;
				height: 75%;
			}

			:deep(.carousel__prev) {
				left: 0;
			}

			:deep(.carousel__next) {
				right: 0;
			}
		}

		&__main-image {
			width: 100%;
		}

		&__thumb-slides {
			.carousel {
				height: 12rem;
				padding-bottom: 0;
			}
		}
	}
}

</style>
