import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	label: string,
	cancelable?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterTag',
  props: {
    label: {},
    cancelable: { type: Boolean, default: true }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(RoundedButton, _mergeProps({
    class: ["filter-tag", {'is-cancelable': _ctx.cancelable, 'is-not-cancelable': !_ctx.cancelable}]
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1 /* TEXT */),
      _createVNode(BaseIcon, {
        name: "close",
        class: "filter-tag__icon"
      })
    ]),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["class"]))
}
}

})