<template>
	<div
		class="checkout-rounded-button__wrapper"
		:class="classes"
	>
		<button
			class="checkout-rounded-button"
			:type="type"
		>
			<slot></slot>
		</button>
	</div>
</template>

<script lang="ts" setup>
import type { ButtonType } from '@/interfaces/commonProps';
import { computed, PropType } from 'vue';

const props = defineProps({
	theme: {
		default: false,
		type: [String, Boolean],
		validator(value: string | boolean) {
			return ['next', 'finalize', false].includes(value);
		}
	},
	type: {
		type: String as PropType<ButtonType>,
		default: 'button'
	},
});

const classes: Record<string, any> = computed(() => ({
	...(props.theme ? { [`checkout-rounded-button__wrapper--${props.theme}`]: true } : {}),
}));

</script>

<style lang="scss" scoped>

.checkout-rounded-button {
	padding: 1rem 3rem;
	border: none;
	border-radius: $border-radius-xx-large;
	outline: none;
	background: $color-primary;
	color: $color-white;
	font-size: $font-small-1;
	font-weight: $font-weight-semibold;
	font-family: $font-family;
	line-height: $line-height-default;
	cursor: pointer;
	min-height: 5.6rem;
	width: 100%;

	&__wrapper {
		display: block;
		width: 100%;
		max-width: 96%;
		min-height: 5.6rem;
		margin: 0;
		font-weight: bold;
		border-radius: $border-radius-xxx-large;
		border: 8px solid transparent;
		transition: border 0.2s ease-in-out;

		&--next {
			&:focus-within,
			&:hover,
			&:active {
				border: 8px solid $color-checkout-hover-next-button;
			}

			.checkout-rounded-button {
				background: linear-gradient($color-checkout-button-next-1, $color-checkout-button-next-2);
				box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
				color: $color-white;
				font-size: $font-medium-3;
				font-weight: bold;
			}
		}

		&--finalize {
			&:hover, {
				border: 8px solid $color-checkout-hover-finalize-button;
			}

			.checkout-rounded-button {
				background: linear-gradient($color-extra, $color-extra-light);
				box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
				color: $color-white;
				font-size: $font-medium-3;
				font-weight: bold;
			}
		}
	}

	@media (min-width: $breakpoint-verysmall) {
		&__wrapper {
			max-width: 49.6rem;
		}
	}
}
</style>
