import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  type: "button",
  class: "collapse__button"
}
const _hoisted_2 = { class: "collapse__button-label" }
const _hoisted_3 = {
  key: 0,
  class: "collapse__button-inline-text"
}
const _hoisted_4 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 27.563 15.267",
  class: "collapse__icon"
}

import { ref, watch } from 'vue';

interface ICollapseProps {
	open?: boolean
	label?: string
	inlineBracketsText?: string
	block?: boolean
	hideArrowIcon?: boolean
	duration?: number
	collapseHeadline?: string
	customHeight?: number
	customOffset?: number
	suppressClosingTransition?: boolean
	suppressCollapseOpen?: boolean
	keepAlive?: boolean
}

type CallBackFunction = () => void;

// // force repaint https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/

export default /*@__PURE__*/_defineComponent({
  __name: 'Collapse',
  props: {
    open: { type: Boolean, default: false },
    label: { default: '' },
    inlineBracketsText: { default: '' },
    block: { type: Boolean, default: false },
    hideArrowIcon: { type: Boolean, default: false },
    duration: { default: 250 },
    collapseHeadline: { default: '' },
    customHeight: { default: 0 },
    customOffset: { default: 0 },
    suppressClosingTransition: { type: Boolean, default: false },
    suppressCollapseOpen: { type: Boolean, default: false },
    keepAlive: { type: Boolean, default: true }
  },
  emits: ['Collapse:Toggle'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const isOpen = ref(props.open);
const bodyHeight = ref('');
const collapse = ref<HTMLElement | null>(null);
const emit = __emit;
const forceRepaint = ((el: HTMLElement) => {
	// eslint-disable-next-line
	const repaint = getComputedStyle(el).height;
});

watch(() => props.open, () => {
	isOpen.value = props.open;
});

watch(() => props.customHeight, () => {
	if (collapse.value) {
		const el = collapse.value;
		const viewportheight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

		el.style.height = props.customHeight
			? ((viewportheight * props.customHeight) + props.customOffset) + 'px'
			: bodyHeight.value;
		forceRepaint(el);
	}
});

const toggle = () => {
	if (props.suppressCollapseOpen) {
		return false;
	}

	isOpen.value = !isOpen.value;
	emit('Collapse:Toggle');
	return true;
};

const detectAndCacheDimensions = (el: HTMLElement) => {
	if (bodyHeight.value) {
		return;
	}

	const visibility = el.style.visibility;
	const display = el.style.display;

	// trick to get element height
	el.style.visibility = 'hidden';
	el.style.display = '';

	bodyHeight.value = `${el.offsetHeight}px`;
	el.style.visibility = visibility;
	el.style.display = display;
};

const enter = (el: HTMLElement, done: CallBackFunction) => {
	detectAndCacheDimensions(el);

	el.style.height = '0';
	el.style.overflow = 'hidden';

	forceRepaint(el);

	const viewportheight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

	el.style.transition = `height ${props.duration / 1000}s ease-out`;
	el.style.height = props.customHeight
		? ((viewportheight * props.customHeight) + props.customOffset) + 'px'
		: bodyHeight.value;
	// wait until transition ends to trigger @after-enter
	setTimeout(done, props.duration);
};

const leave = (el: HTMLElement, done: CallBackFunction) => {
	detectAndCacheDimensions(el);
	el.style.height = bodyHeight.value;
	el.style.overflow = 'hidden';

	forceRepaint(el);

	if (props.suppressClosingTransition) {
		el.style.transition = 'height 0s ease-out';
	} else {
		el.style.transition = `height ${props.duration / 1000}s ease-out`;
	}
	el.style.height = '0';

	setTimeout(done, props.duration);
};

const afterEnter = (el: HTMLElement) => {
	if (!props.customHeight) {
		el.style.height = '';
	}

	el.style.overflow = '';
	el.style.transform = '';

	bodyHeight.value = '';
};

const afterLeave = (el: HTMLElement) => {
	el.style.height = '';
	el.style.overflow = '';
	el.style.transition = '';

	bodyHeight.value = '';
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["collapse", {'is-open': isOpen.value}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["collapse__header", {'is-block': props.block !== false}]),
      onClick: toggle
    }, [
      _renderSlot(_ctx.$slots, "header", {
        toggle: toggle,
        isOpen: isOpen.value
      }, () => [
        (props.label)
          ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
              _createElementVNode("span", _hoisted_2, [
                _createTextVNode(_toDisplayString(props.label) + " ", 1 /* TEXT */),
                (props.inlineBracketsText)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(props.inlineBracketsText) + ")", 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        (props.hideArrowIcon === false)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[0] || (_cache[0] = [
              _createElementVNode("path", { d: "M24.907 14.798L13.782 3.673 2.657 14.798a1.511 1.511 0 01-2.188 0 1.511 1.511 0 010-2.188L12.719.422a1.548 1.548 0 012.125 0l12.25 12.188a1.54714964 1.54714964 0 01-2.188 2.188z" }, null, -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */),
    (!props.keepAlive)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "expand",
          onEnter: enter,
          onAfterEnter: afterEnter,
          onLeave: leave,
          onAfterLeave: afterLeave
        }, {
          default: _withCtx(() => [
            (isOpen.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: "is-open",
                  ref_key: "collapse",
                  ref: collapse,
                  class: "collapse__body"
                }, [
                  _renderSlot(_ctx.$slots, "default", { toggle: toggle })
                ], 512 /* NEED_PATCH */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 3 /* FORWARDED */
        }))
      : (_openBlock(), _createBlock(_Transition, {
          key: 1,
          name: "expand",
          onEnter: enter,
          onAfterEnter: afterEnter,
          onLeave: leave,
          onAfterLeave: afterLeave,
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              id: "keep-alive",
              ref_key: "collapse",
              ref: collapse,
              class: "collapse__body"
            }, [
              _renderSlot(_ctx.$slots, "default", { toggle: toggle })
            ], 512 /* NEED_PATCH */), [
              [_vShow, isOpen.value]
            ])
          ]),
          _: 3 /* FORWARDED */
        }))
  ], 2 /* CLASS */))
}
}

})