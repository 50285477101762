import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

import type { ButtonType } from '@/interfaces/commonProps';
import { computed, PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutRoundedButton',
  props: {
	theme: {
		default: false,
		type: [String, Boolean],
		validator(value: string | boolean) {
			return ['next', 'finalize', false].includes(value);
		}
	},
	type: {
		type: String as PropType<ButtonType>,
		default: 'button'
	},
},
  setup(__props) {

const props = __props;

const classes: Record<string, any> = computed(() => ({
	...(props.theme ? { [`checkout-rounded-button__wrapper--${props.theme}`]: true } : {}),
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkout-rounded-button__wrapper", classes.value])
  }, [
    _createElementVNode("button", {
      class: "checkout-rounded-button",
      type: __props.type
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8 /* PROPS */, _hoisted_1)
  ], 2 /* CLASS */))
}
}

})