import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "range__helper" }
const _hoisted_2 = { class: "range__helper" }

import Draggable from '@lmt-rpb/Draggable/Draggable.vue';
import {
	computed, getCurrentInstance, onMounted, ref, watch,
} from 'vue';

interface Props {
	defaultValue?: number,
	min?: number,
	max?: number,
	minLabel?: string
	maxLabel?: string,
	inactive?: boolean,
	modelValue: number | undefined,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RangeSlider',
  props: {
    defaultValue: { default: 5 },
    min: { default: 1 },
    max: { default: 16 },
    minLabel: { default: '' },
    maxLabel: { default: '' },
    inactive: { type: Boolean, default: false },
    modelValue: {}
  },
  emits: ['update:modelValue', 'RangeSlider:DragStart', 'RangeSlider:DragEnd', 'RangeSlider:Select'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const progress = ref(0);

const dragging = ref(false);

const trackStyle = computed((): Record<string, any> => ({
	width: `${progress.value}%`
}));

const dragContainer = ref<HTMLElement>();

const count = computed((): number => {
	const num = Math.round(((props.max - props.min) / 100) * progress.value);
	return Math.max(Math.min(props.min + num, props.max), props.min);
});

const onDragStart = () => {
	dragging.value = true;
	emit('RangeSlider:DragStart');
};

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	emit('RangeSlider:DragStart');
};

const onDragEnd = () => {
	dragging.value = false;
	emit('RangeSlider:DragEnd');
};

const valueInPercent = (val: number): number => (100 / (props.max - props.min)) * (val - props.min);

const setPositionByTap = (x: number) => {
	if (!dragContainer.value) return;
	const { width, left } = dragContainer.value.getBoundingClientRect();
	const posInPx = x - left;
	progress.value = (posInPx / width) * 100;
	emit('RangeSlider:Select', count.value);
};

const onClick = (event: MouseEvent) => {
	setPositionByTap(event.clientX);
};

const onTap = (event: TouchEvent) => {
	setPositionByTap(event.touches[0].clientX);
};

const increase = () => {
	const updated = Math.max(Math.min(count.value + 1, props.max), props.min);
	emit('update:modelValue', updated);
};

const decrease = () => {
	const updated = Math.max(Math.min(count.value - 1, props.max), props.min);
	emit('update:modelValue', updated);
};

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

onMounted(() => {
	emit('update:modelValue', model.value || props.defaultValue);
	dragContainer.value = getCurrentInstance()?.refs.dragContainer as HTMLElement;
});

watch(() => model.value, () => {
	progress.value = valueInPercent(model.value);
}, { immediate: true });

watch(() => progress.value, () => {
	emit('update:modelValue', count.value);
}, { immediate: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["range", {'is-inactive': _ctx.inactive}]),
    "aria-live": "polite"
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.minLabel), 1 /* TEXT */),
    _createElementVNode("div", {
      ref_key: "dragContainer",
      ref: dragContainer,
      class: "range__slider",
      onClick: _withModifiers(onClick, ["prevent"]),
      onTouchstart: onTap
    }, [
      _createVNode(Draggable, {
        "progress-value": progress.value,
        "onUpdate:progressValue": _cache[0] || (_cache[0] = ($event: any) => ((progress).value = $event)),
        class: "range__handler",
        tabindex: "0",
        role: "slider",
        "aria-label": "Slider Handle",
        "aria-valuemin": _ctx.min,
        "aria-valuemax": _ctx.max,
        "aria-valuenow": count.value,
        onKeydown: [
          _withKeys(decrease, ["left"]),
          _withKeys(increase, ["right"])
        ],
        "onDraggable:DragStart": onDragStart,
        "onDraggable:Drag": _cache[1] || (_cache[1] = ($event: any) => (onDrag($event))),
        "onDraggable:DragEnd": onDragEnd
      }, null, 8 /* PROPS */, ["progress-value", "aria-valuemin", "aria-valuemax", "aria-valuenow"]),
      _createElementVNode("div", {
        ref: "track",
        class: "range__track",
        style: _normalizeStyle(trackStyle.value)
      }, null, 4 /* STYLE */)
    ], 544 /* NEED_HYDRATION, NEED_PATCH */),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.maxLabel), 1 /* TEXT */)
  ], 2 /* CLASS */))
}
}

})