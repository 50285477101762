import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href", "rel"]
const _hoisted_2 = { key: 0 }

interface Props {
	rpbButtonClass?: string,
	text?: string,
	link?: string,
	relAttr?: string,
	fromPrice?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RpbButton',
  props: {
    rpbButtonClass: { default: 'rpb_btn--secondary' },
    text: { default: 'ab €999' },
    link: { default: '#' },
    relAttr: { default: '' },
    fromPrice: { type: Boolean, default: false }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    class: _normalizeClass(_ctx.rpbButtonClass),
    rel: _ctx.relAttr
  }, [
    (_ctx.fromPrice)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, "ab "))
      : _createCommentVNode("v-if", true),
    _createTextVNode(_toDisplayString(_ctx.text), 1 /* TEXT */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})