import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "direct-flight__info-bubble"
}

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { EventBus } from '@global-js/event-bus';
import {
	computed, onBeforeMount, onMounted, ref,
} from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	disabled?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'DirectFlight',
  props: {
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {


const props = __props;

const store = useStore();

const showInfoText = ref(false);

const directFlight = computed({
	get() {
		if (store.state.searchMask) {
			return store.state.searchMask.directFlight;
		}
		return false;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { directFlight: value });
	},
});

const resetInput = (): void => {
	store.dispatch('resetItem', 'directFlight');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

onMounted(() => {
	if (props.disabled) resetInput();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeMount(() => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["direct-flight__input-container", _ctx.disabled ? 'direct-flight__input-container--disabled' : ''])
  }, [
    _createVNode(Checkbox, {
      modelValue: directFlight.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((directFlight).value = $event)),
      disabled: _ctx.disabled,
      class: "direct-flight",
      label: "Nur Direktflüge anzeigen"
    }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
    _createVNode(_Transition, { name: "info-text" }, {
      default: _withCtx(() => [
        (showInfoText.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[4] || (_cache[4] = [
              _createElementVNode("p", { class: "direct-flight__info-text" }, " Es werden nur Angebote angezeigt, die mit hoher Wahrscheinlichkeit eine direkte Flugverbindung ohne Umsteigen beinhalten. ", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(BaseIcon, {
      name: "directFlight",
      class: "direct-flight__info-icon",
      onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (showInfoText.value = !showInfoText.value), ["enter","native"])),
      onMouseover: _cache[2] || (_cache[2] = ($event: any) => (showInfoText.value = true)),
      onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (showInfoText.value = false))
    })
  ], 2 /* CLASS */))
}
}

})