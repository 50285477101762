import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "gallery-modal__header" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "gallery-modal__body" }
const _hoisted_4 = { class: "gallery-modal__main-slides" }
const _hoisted_5 = ["src", "alt", "title"]
const _hoisted_6 = { class: "gallery-modal__copyright" }
const _hoisted_7 = {
  key: 0,
  class: "gallery-modal__thumb-slides"
}
const _hoisted_8 = ["src", "alt", "onClick"]

import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';
import { getWebsiteLogoPath, getWebsiteName } from '@utils/environmentUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { HotelCatalogueImageType } from '@interfaces/hotel-catalogue-types';
import {
	computed, nextTick, ref, watch, onBeforeUnmount,
} from 'vue';
import type { ClientType } from '@/interfaces/common';
import 'vue3-carousel/dist/carousel.css';

interface Props {
	images: HotelCatalogueImageType[],
	index?: number,
	modelValue?: boolean,
	client?: ClientType,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryModal',
  props: {
    images: {},
    index: { default: 0 },
    modelValue: { type: Boolean, default: false },
    client: { default: 'at' }
  },
  emits: ['slide', 'update:modelValue', 'close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const carouselIndex = ref(props.index);

const mainCarouselSettings = {
	snapAlign: 'start',
	breakpoints: {
		1200: { // breakpoint-large
			touchDrag: false,
		}
	}
};

const model = computed({
	get() {
		return props.modelValue;
	},

	set(value: boolean) {
		emit('update:modelValue', value);
	}
});

const thumbCarouselSettings = ref({
	itemsToShow: 3,
	snapAlign: 'center',
	breakpoints: {
		992: { // breakpoint-medium
			itemsToShow: 6,
		},
		1200: { // breakpoint-large
			itemsToShow: 10,
			touchDrag: false,
			snapAlign: ''
		},
		1500: {
			itemsToShow: 12,
			snapAlign: ''
		}
	}
});

const mainCarousel = ref<typeof Carousel>();

const thumbnailCarousel = ref<typeof Carousel>();

const altTextForLogo = getWebsiteName(props.client);

const modalLogo = computed((): string => `${getWebsiteLogoPath(props.client)}/img/logo.svg`);

const slideTo = (val: number) => {
	carouselIndex.value = val;
};

const slideNext = () => {
	slideTo(Math.min(carouselIndex.value + 1, props.images.length - 1));
};

const slidePrev = () => {
	slideTo(Math.max(carouselIndex.value - 1, 0));
};

const close = () => {
	emit('close');
};

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'ArrowRight') {
		slideNext();
	}
	if (event.key === 'ArrowLeft') {
		slidePrev();
	}
	if (event.key === 'Escape') {
		close();
		window.removeEventListener('keyup', handleKeyup);
	}
};

watch(() => model.value, () => {
	if (model.value) {
		nextTick(() => { // one tick delay
			mainCarousel.value?.updateSlideWidth();
			thumbnailCarousel.value?.updateSlideWidth();
			document.body.classList.add('gallery-open');
		});
		window.addEventListener('keyup', handleKeyup);
	} else {
		document.body.classList.remove('gallery-open');
		window.removeEventListener('keyup', handleKeyup);
	}
});

watch(() => props.index, () => {
	carouselIndex.value = props.index;
});

onBeforeUnmount(() => {
	document.body.classList.remove('gallery-open');
	window.removeEventListener('keyup', handleKeyup);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gallery-modal", {'is-visible': model.value}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "gallery-modal__logo",
        alt: _unref(altTextForLogo),
        src: modalLogo.value
      }, null, 8 /* PROPS */, _hoisted_2),
      _createVNode(RoundedButton, {
        class: "gallery-modal__close-button",
        theme: "outlined",
        "aria-label": "Close",
        onClick: close
      }, {
        default: _withCtx(() => [
          _createVNode(BaseIcon, {
            name: "close",
            class: "gallery-modal__close-icon"
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(Carousel), _mergeProps({
          id: "gallery",
          ref_key: "mainCarousel",
          ref: mainCarousel,
          modelValue: carouselIndex.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((carouselIndex).value = $event)),
          class: "gallery-modal__main-carousel"
        }, mainCarouselSettings), {
          addons: _withCtx(() => [
            _createVNode(_unref(CarouselNavigation), null, {
              prev: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "gallery-modal__aria-label" }, "prev", -1 /* HOISTED */)),
                _createVNode(BaseIcon, {
                  name: "chevron",
                  class: "gallery-modal__prev-icon"
                })
              ]),
              next: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "gallery-modal__aria-label" }, "next", -1 /* HOISTED */)),
                _createVNode(BaseIcon, {
                  name: "chevron",
                  class: "gallery-modal__next-icon"
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, slideIndex) => {
              return (_openBlock(), _createBlock(_unref(Slide), { key: slideIndex }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "gallery-modal__main-image",
                    src: image.URL,
                    alt: `${image.Caption} - ${slideIndex}`,
                    title: `© GIATA GmbH 1996 - ${new Date().getFullYear()}`
                  }, null, 8 /* PROPS */, _hoisted_5),
                  _createElementVNode("span", _hoisted_6, "© GIATA GmbH 1996 - " + _toDisplayString(new Date().getFullYear()), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 16 /* FULL_PROPS */, ["modelValue"])
      ]),
      (_ctx.images)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_unref(Carousel), _mergeProps({
              id: "thumbnails",
              ref_key: "thumbnailCarousel",
              ref: thumbnailCarousel,
              modelValue: carouselIndex.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((carouselIndex).value = $event)),
              class: "is-thumbnail"
            }, thumbCarouselSettings.value), {
              addons: _withCtx(() => [
                _createVNode(_unref(CarouselNavigation), null, {
                  prev: _withCtx(() => [
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "gallery-modal__aria-label" }, "prev", -1 /* HOISTED */)),
                    _createVNode(BaseIcon, {
                      name: "chevron",
                      class: "gallery-modal__prev-icon is-thumb"
                    })
                  ]),
                  next: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "gallery-modal__aria-label" }, "next", -1 /* HOISTED */)),
                    _createVNode(BaseIcon, {
                      name: "chevron",
                      class: "gallery-modal__next-icon is-thumb"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, imageIndex) => {
                  return (_openBlock(), _createBlock(_unref(Slide), { key: imageIndex }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: _normalizeClass(["gallery-modal__image", carouselIndex.value === imageIndex ? 'is-active' : '']),
                        src: image.Preview.URL,
                        alt: `${image.Caption} - ${_ctx.index}`,
                        onClick: ($event: any) => (slideTo(imageIndex))
                      }, null, 10 /* CLASS, PROPS */, _hoisted_8)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 16 /* FULL_PROPS */, ["modelValue"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}
}

})