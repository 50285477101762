import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "labeled-text" }
const _hoisted_2 = { class: "labeled-text__label" }
const _hoisted_3 = { class: "labeled-text__text" }

type Props = {
	text: string
	label: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LabeledText',
  props: {
    text: {},
    label: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1 /* TEXT */)
  ]))
}
}

})