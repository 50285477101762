<template>
	<div>
		<div class="flight-details__segment">
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Datum
				</div>
				<div class="flight-details__row-right">
					{{ getDate(segment.Departure.Date) }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Abflughafen
				</div>
				<div class="flight-details__row-right">
					{{ segment.Departure.Airport }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Zielflughafen
				</div>
				<div class="flight-details__row-right">
					{{ segment.Arrival.Airport }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Fluggesellschaft
				</div>
				<div class="flight-details__row-right">
					{{ segment.Airline }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Flugklasse
				</div>
				<div class="flight-details__row-right">
					{{ segment.CabinClass }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Flugnummer
				</div>
				<div class="flight-details__row-right">
					{{ segment.FlightNumber }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Abflugzeit
				</div>
				<div class="flight-details__row-right">
					{{ getTime(segment.Departure) }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left">
					Ankunftzeit
				</div>
				<div class="flight-details__row-right">
					{{ getTime(segment.Arrival) }}
				</div>
			</div>
			<div class="flight-details__row">
				<div class="flight-details__row-left has-icon">
					<BaseIcon
						name="briefcase"
						class="icon"
					/> Freigepäck
				</div>
				<div class="flight-details__row-right">
					<span
						class="flight-details__baggage"
					>
						{{ getAllowanceText(segment.BaggageAllowance) }}
						<InfoTooltip
							placement="bottom"
							:modifier-class="props.isRight ? 'is-right' : 'is-left'"
						>{{ getTooltipText(segment.BaggageAllowance) }}</InfoTooltip>
					</span>
				</div>
			</div>
			<div
				v-if="segment.StopOver"
				class="flight-details__row"
			>
				<div class="flight-details__row-left">
					Aufenthalt
				</div>
				<div class="flight-details__row-right">
					{{ convertSeconds(segment.StopOver.StopoverDuration) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { formatDate, convertSeconds } from '@utils/utils';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { FlightSegment } from '@/interfaces/checkout/offer-data';
import { BaggageAllowance, Juncture } from '@/interfaces/common';
import { AirportData } from '@/interfaces/offer';

interface Props {
	segment: FlightSegment,
	isRight?: boolean,
}

const props = defineProps<Props>();

const getDate = (date: Date | string): Date | string => {
	if (!date) {
		return '';
	}

	return formatDate(date, { year: 'numeric', month: 'short', day: '2-digit' });
};

const isAiportData = (aiport: Juncture | AirportData): aiport is AirportData => ('time' in aiport);

const getAllowanceText = (baggage: BaggageAllowance | undefined): string => {
	if (!baggage || !(baggage.MaxWeight || baggage.MaxBags)) {
		return '1 x p. P. inkl.';
	}
	const { MaxBags, MaxWeight } = baggage;
	if (MaxBags && MaxWeight) {
		return baggage.MaxBags + ' x ' + baggage.MaxWeight + 'kg p. P. inkl.';
	}

	if (MaxWeight) {
		return '1 x ' + baggage.MaxWeight + 'kg p. P. inkl.';
	}

	return MaxBags + ' x p. P. inkl.';
};

const getTooltipText = (baggae: BaggageAllowance | undefined) => {
	if (!baggae || !baggae.MaxWeight) {
		return 'Der Veranstalter liefert uns leider keine Informationen zum Freigepäck. Dieses beträgt ca. 20kg und kann je nach Airline auch weniger oder mehr betragen.';
	}
	return 'Bitte beachten Sie, dass die Angaben zu Freigepäckmengen in Ausnahmefällen abweichen können.';
};

const getTime = (airport: Juncture | AirportData): string => {
	let time = '';
	if (isAiportData(airport)) {
		if (airport.time) {
			time = airport.time + ' Uhr';
		}
	}

	if (!time && airport.Date) {
		time = `${formatDate(airport.Date, { hour: '2-digit', minute: '2-digit' })} Uhr`;
	}

	return time;
};

</script>

<style lang="scss" scoped>
.flight-details {
	&__row {
		display: flex;
		width: 100%;
		margin-bottom: 1rem;
		gap: 1.4rem;
	}

	&__row-left,
	&__row-right {
		flex: 1;
		font-size: $font-small-1;
	}

	&__row-left {
		text-align: right;
		font-weight: bold;

		&.has-icon {
			display: flex;
			justify-content: end;
			justify-items: center;

			.icon {
				margin-top: -0.1rem;
				width: 2rem;
				margin-right: 0.8rem;
			}
		}
	}

	&__row-right {
		font-weight: $font-weight-semibold;
		min-width: 16.75rem;

		.info-tooltip {
			margin-right: 0;
		}
	}

	&__segment {
		display: flex;
		flex-direction: column;
		margin-top: 2rem;

		@media (max-width: $breakpoint-small - 1px) {
			margin-bottom: 2rem;
		}

		& {
			margin-bottom: 3rem;
		}
	}
}

:deep(#tooltip) {
	max-width: 48.2rem;
	max-height: 11.5rem;

	@media (max-width: $breakpoint-verysmall - 1px) {
		max-width: $breakpoint-verysmall;
	}

	@media (min-width: $breakpoint-verysmall) and (max-width: $breakpoint-small - 1px) {
		$window-minus-width: calc((100vw - $breakpoint-verysmall) / -2);

		left: $window-minus-width !important;

		.info-tooltip__arrow {
			left: calc(-1 * $window-minus-width) !important;
		}
	}

	&.is-left {
		@media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1px) {
			left: 10rem !important;

			.info-tooltip__arrow {
				left: -10rem !important;
			}
		}

		@media (min-width: $breakpoint-medium) {
			left: 20.75rem !important;

			.info-tooltip__arrow {
				left: -20.75rem !important;
			}
		}
	}

	&.is-right {
		@media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1px){
			$left: min(4rem, calc(2.5rem + 3vw));

			left: calc($left * -1) !important;

			.info-tooltip__arrow {
				left: $left !important;
			}
		}

		@media (min-width: $breakpoint-medium) {
			$left: min(20rem, calc(10rem + 5vw));

			left: calc($left * -1) !important;

			.info-tooltip__arrow {
				left: $left !important;
			}
		}
	}
}
</style>
