import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SearchModeSwitchSimple from '@lmt-rpb/SearchModeSwitchSimple/SearchModeSwitchSimple.vue';
import SearchModeSwitchMini from '@lmt-rpb/SearchModeSwitchMini/SearchModeSwitchMini.vue';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';

interface Props {
	forceTheme?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchModeSwitch',
  props: {
    forceTheme: { default: '' }
  },
  setup(__props: any) {

const store = useStore();

const isDesktop = computed(() => store.state.config.isDesktop);

const props = __props;

const switchModel = computed({
	get() {
		return store.state.searchMask.onlyHotel;
	},

	set(value: boolean) {
		store.commit('searchMask/updateFormData', { onlyHotel: value });
	}
});

const theme = computed(() => {
	if (props.forceTheme) {
		return ({
			simple: SearchModeSwitchSimple,
			mini: SearchModeSwitchMini,
		} as {[key: string]: any})[props.forceTheme];
	}

	return isDesktop.value ? SearchModeSwitchMini : SearchModeSwitchSimple;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(theme.value), _mergeProps({
    modelValue: switchModel.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((switchModel).value = $event)),
    "draggable-aria-label": 'Mode Switch Handle'
  }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["modelValue"]))
}
}

})