import { BestAccommodationOfferForHotelList } from '@/interfaces/api/v1-best-accommodation-offer-for-hotel-list';
import { BestPackageOfferForHotelList } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { isBestPackageOfferForHotelList } from './typeUtils';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';

export enum TravelType {
	Accommodation = 'Accommodation',
	Package = 'Package',
}

type Offer = BestAccommodationOfferForHotelList | BestPackageOfferForHotelList | ItemType

export const extractTravelTypeFromOffer = (offer: Offer) => {
	if (isBestPackageOfferForHotelList(offer)) {
		return offer.PackageOffer;
	}
	return offer.AccommodationOffer;
};

export function getTravelType(type: 'hotel' | 'package') {
	return type === 'hotel' ? TravelType.Accommodation : TravelType.Package;
}
