import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment-method-info__container" }
const _hoisted_2 = {
  class: "payment-method-info__title",
  title: "Sichere Transaktion"
}

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutPaymentMethodInfo',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BaseIcon, {
        class: "payment-method-info__lock-icon",
        name: "lockCheckout"
      }),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "payment-method-info__additional-info main-info" }, " Sichere Bezahlung ", -1 /* HOISTED */))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "payment-method-info__body" }, " Alle Zahlungsinformationen werden vollständig SSL-verschlüsselt, gesichert und geschützt. ", -1 /* HOISTED */))
  ]))
}
}

})