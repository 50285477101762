import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "circle-button" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

type Props = {
	icon: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CircleButton',
  props: {
    icon: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(BaseIcon, {
      class: "circle-button__icon",
      name: _ctx.icon,
      viewBox: _ctx.$attrs.viewBox
    }, null, 8 /* PROPS */, ["name", "viewBox"])
  ]))
}
}

})