import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "additional-checkbox-filters" }

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { additionalCheckboxFilterTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalCheckboxFilters',
  setup(__props) {

const store = useStore();

const listOfAvailableFilters = ref(additionalCheckboxFilterTypes);

const ratingFilters = computed({
	get() {
		return store.state.searchMask.additionalCheckboxFilters?.ratingAttributes as string[];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			{ additionalCheckboxFilters: { ratingAttributes: value } }
		);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listOfAvailableFilters.value, (availableFilter, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: "additional-checkbox-filters__item"
      }, [
        _createVNode(Checkbox, {
          modelValue: ratingFilters.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ratingFilters).value = $event)),
          label: availableFilter.label,
          "custom-value": availableFilter.value
        }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})