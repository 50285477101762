import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

import {
	ref, computed, onMounted, onBeforeUnmount, watch,
} from 'vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import {
	dateDiff, pluralize, offsetDate,
} from '@utils/utils';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import TravelDurationContent from './TravelDurationContent.vue';

interface Props {
	showIcon?: boolean,
}

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelDurationField',
  props: {
    showIcon: { type: Boolean, default: true }
  },
  setup(__props: any, { expose: __expose }) {

const store = useStore();

const dropdownComponent = ref<InstanceType<typeof DropdownField>>();

const showModal = ref(false);

const dropdownVisible = ref(false);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const travelDurationReference = ref<HTMLElement | null>(null);

const props = __props;

const selection = ref({
	isExactSelected: false,
	travelDuration: [] as number[]
});

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	}
});

const offerDuration = computed({
	get() {
		const dur = store.state.searchMask.offerDuration;
		return {
			from: dur.from || 0,
			to: dur.to || 0
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			offerDuration: value,
			offerDurationRelative: { relativeFrom: '', relativeTo: '' }
		});
	}
});

const totalDiffInDay = computed((): number => dateDiff(offerDuration.value.from, offerDuration.value.to));

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
		selection.value.isExactSelected = newValue;
	}
});

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration || [];
	},
	set(newValue: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: newValue });
		selection.value.travelDuration = newValue;
	}
});

const travelDurationModel = computed({
	get() {
		return {
			exact: selection.value.isExactSelected,
			duration: selection.value.travelDuration
		};
	},
	set(newValue: {exact: boolean, duration: number[]}) {
		selection.value = {
			isExactSelected: newValue.exact,
			travelDuration: newValue.duration,
		};
	}
});

const getDaysDurationLabel = (duration: number[]) => {
	let result: string;
	if (duration.length === 1) {
		result = pluralize(Number(duration[0]), 'Tag', 'Tage');
	} else {
		result = `${duration.join('-')} Tage`;
	}
	return result;
};

const durationLabel = computed((): string => {
	let result = '';
	const duration = travelDurationModel.value.duration;
	const isBeliebig = duration.length === 0 || (duration[0] === 1 && duration[1] === 14);
	if (travelDurationModel.value.exact) {
		result = 'Exakt';
	} else if (!isBeliebig) {
		result = getDaysDurationLabel(duration);
	}
	return result;
});

const updateOfferDuration = ():void => {
	const offerFrom = offerDuration.value.from!;
	const min = travelDuration.value[0];
	if (totalDiffInDay.value < min) {
		const max = Math.max(...travelDuration.value);
		offerDuration.value = {
			from: offerDuration.value.from,
			to: offsetDate(offerFrom, max).getTime()
		};
	}
};

const closeDropdown = () => {
	if (isDesktop.value && dropdownComponent.value) {
		dropdownComponent.value.closeDropdown();
		dropdownVisible.value = false;
	}
};

const onOk = () => {
	if (maxTravelDurationError.value) { return; }
	isExact.value = selection.value.isExactSelected;
	travelDuration.value = selection.value.travelDuration;
	EventBus.$emit('TravelDuration:Changed');
	updateOfferDuration();
	store.commit('searchMask/updateFormData', { filter: '' } );
	closeDropdown();
};

// necessary for dropdown animation
const toggleDropdownVisiblity = () => {
	if (dropdownComponent.value) {
		setTimeout(() => {
			dropdownVisible.value = !dropdownVisible.value;
			if (!dropdownVisible.value) {
				onOk();
			}
		}, 150);
	}
};

const openModal = () => {
	const headerElement = document.querySelectorAll('.page-header')[0] as HTMLElement;
	if (headerElement) {
		headerElement.style.display = 'block';
	}
	setTimeout(() => { showModal.value = true; }, 100); // needs to wait for modal close otherwise its getting closed as well
};

const openModalOrDropdown = () => {
	if (!maxTravelDurationError.value) {
		if (dropdownComponent.value) {
			toggleDropdownVisiblity();
			dropdownComponent.value.open = true;
		} else {
			openModal();
		}
	}
};

const handleDurationFieldClick = (event: MouseEvent) => {
	if (maxTravelDurationError.value) { return; }
	const target = event.target as Node;
	const clickedDropdownOrModal = travelDurationReference.value && travelDurationReference.value.contains(target);
	if (!clickedDropdownOrModal) {
		toggleDropdownVisiblity();
	}
};

const onCancel = () => {
	selection.value = {
		travelDuration: travelDuration.value,
		isExactSelected: isExact.value,
	};
	maxTravelDurationError.value = travelDuration.value[0] > 56 && isExact.value;
	if (isDesktop.value && dropdownComponent.value) {
		dropdownComponent.value.closeDropdown();
		dropdownVisible.value = false;
	}
};

const handleDurationError = (pillValue: string, travelDurationDays: number) => {
	if (pillValue !== '') {
		maxTravelDurationError.value = pillValue === TRAVEL_DURATION_EXACT && travelDurationDays > 56;
	}
};

watch(travelDuration, (newValue) => {
	travelDuration.value = newValue;
});

watch(isExact, (newValue) => {
	isExact.value = newValue;
});

watch(isDesktop, () => {
	onCancel();
	toggleDropdownVisiblity();
});

onMounted(() => {
	EventBus.$on('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	selection.value = {
		isExactSelected: isExact.value,
		travelDuration: travelDuration.value
	};
});

onBeforeUnmount(() => {
	EventBus.$off('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
});

__expose({
	selection, durationLabel
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["travel-duration-field", {'travel': dropdownVisible.value, 'duration-error': maxTravelDurationError.value}]),
    onClick: handleDurationFieldClick
  }, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "dropdownComponent",
          ref: dropdownComponent,
          modelValue: durationLabel.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((durationLabel).value = $event)),
          class: "travel-duration-field__dropdown",
          label: "Dauer",
          wide: true,
          icon: _ctx.showIcon && 'clock',
          "with-cancel-button": selection.value.travelDuration[0] > totalDiffInDay.value,
          "form-field-class": "travel-duration-field__field",
          "max-travel-duration-error": maxTravelDurationError.value,
          "onDropdownField:OutsideClick": onOk,
          "onDropdownField:Ok": onOk,
          "onDropdownField:Cancel": onCancel,
          "onDropdownField:Clear": onCancel
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref_key: "travelDurationReference",
              ref: travelDurationReference
            }, [
              _createVNode(TravelDurationContent, {
                modelValue: travelDurationModel.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((travelDurationModel).value = $event)),
                "offer-duration-diff": totalDiffInDay.value,
                "error-message": maxTravelDurationError.value ? maxDurationErrorMessage : '',
                "onTravelDurationContent:PillChanged": handleDurationError
              }, null, 8 /* PROPS */, ["modelValue", "offer-duration-diff", "error-message"])
            ], 512 /* NEED_PATCH */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "icon", "with-cancel-button", "max-travel-duration-error"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(FormField, {
            label: "Dauer",
            class: "travel-duration-field__field",
            selected: durationLabel.value,
            "show-modal": showModal.value,
            icon: _ctx.showIcon && 'clock',
            "show-toggle-icon": false,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showModal.value = !showModal.value)),
            "onFormField:Clear": onCancel
          }, null, 8 /* PROPS */, ["selected", "show-modal", "icon"]),
          _createVNode(Modal, {
            modelValue: showModal.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
            title: "Reisedauer",
            class: "travel-duration-modal",
            "error-message": maxTravelDurationError.value ? maxDurationErrorMessage : '',
            "cancel-button-label": 'Verwerfen',
            "accept-button-label": 'Übernehmen',
            "onModal:Ok": onOk,
            "onModal:Cancel": onCancel,
            "onModal:Close": onCancel
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                ref_key: "travelDurationReference",
                ref: travelDurationReference
              }, [
                _createVNode(TravelDurationContent, {
                  modelValue: travelDurationModel.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((travelDurationModel).value = $event)),
                  "offer-duration-diff": totalDiffInDay.value,
                  "error-message": maxTravelDurationError.value ? maxDurationErrorMessage : '',
                  "onTravelDurationContent:PillChanged": handleDurationError
                }, null, 8 /* PROPS */, ["modelValue", "offer-duration-diff", "error-message"])
              ], 512 /* NEED_PATCH */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "error-message"])
        ], 64 /* STABLE_FRAGMENT */))
  ], 2 /* CLASS */))
}
}

})