import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { viewTypeValues } from '@/js/data/view-types';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SeaView',
  setup(__props) {


const store = useStore();

// We can get/set the state like this because it's a checkbox field and
// only 'FullOcean' is possible at the moment
const seaView = computed({
	get() {
		return store.state.searchMask.roomViews.length > 0;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', { roomViews: value ? viewTypeValues : [] });
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Checkbox, {
    modelValue: seaView.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((seaView).value = $event)),
    class: "sea-view",
    label: "Zimmer mit Meerblick"
  }, null, 8 /* PROPS */, ["modelValue"]))
}
}

})