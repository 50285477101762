<template>
	<button
		class="button"
		:type="type"
		:class="classes"
		:disabled="disabled"
		:title="titleValue"
		v-bind="$attrs"
	>
		<slot />
	</button>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';
import type { ButtonType } from '@/interfaces/commonProps';

const props = defineProps({
	theme: {
		default: false,
		type: [String, Boolean],
		validator(value: string | boolean) {
			return ['outlined', 'extra', 'transparent-no-outline', 'no-outline', false, 'gradient', 'checkout-next'].includes(value);
		}
	},
	type: {
		type: String as PropType<ButtonType>,
		default: 'button'
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	titleValue: {
		type: String,
		default: ''
	},
});

const classes: Record<string, any> = computed(() => ({
	...(props.theme ? { [`button--${props.theme}`]: true } : {}),
	'is-disabled': props.disabled
}));

</script>

<style lang="scss" scoped>
.button {
	padding: 1rem 3rem;
	border: none;
	border-radius: $border-radius-xx-large;
	outline: none;
	background: $color-primary;
	color: $color-white;
	font-size: $font-small-1;
	font-weight: $font-weight-semibold;
	font-family: $font-family;
	line-height: $line-height-default;
	cursor: pointer;

	&:hover,
	&:focus {
		border-color: $color-primary-l0;
		background: $color-primary-l0;
		color: $color-white;
	}

	&.button--outlined {
		border: 0.1rem solid $color-primary;
		background: $color-white;
		color: $color-primary;
		transition: all ease 0.3s;

		&:focus,
		&:hover,
		&:active {
			background: $color-primary-l0;
			color: $color-white;
		}
	}

	&.button--transparent-no-outline {
		border: 0.1rem solid transparent;
		background: transparent;
		color: $color-primary;
		font-weight: $font-weight-regular;

		&:focus,
		&:hover,
		&:active {
			opacity: 0.8;
		}
	}

	&.button--no-outline {
		background: transparent;
		color: $color-primary;
		font-weight: $font-weight-semibold;
	}

	&.button--gradient {
		background: linear-gradient($color-primary, $color-primary-darker);
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
	}

	&.button--extra {
		background: $color-extra;
		color: $color-white;
		font-size: $font-medium-3;
		font-weight: bold;

		&:focus,
		&:hover,
		&:active {
			background: $color-extra-t8;
			color: $color-white;
		}
	}

	&.button--checkout-next {
		background: linear-gradient($color-checkout-button-next-1, $color-checkout-button-next-2);
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
		color: $color-white;
		font-size: $font-medium-3;
		font-weight: bold;
	}

	&.is-disabled {
		background: $color-dark-gray;
		color: $color-light-gray;
		pointer-events: none;

		&.button--outlined {
			border: 0.2rem solid $color-dark-gray;
			background: $color-light-gray;
			color: $color-text;
		}

		&.button--extra {
			background: $color-dark-gray;
			color: $color-light-gray;

			&:focus,
			&:hover,
			&:active {
				background: $color-dark-gray;
				color: $color-light-gray;
			}
		}
	}
}
</style>
