<template>
	<svg
		width="24"
		height="18"
		viewBox="0 0 24 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Equipment Symbol</title>
		<path
			fill="#000000"
			d="M10.125 0C9.50156 0 9 0.501562 9 1.125C9 1.74844 9.50156 2.25 10.125 2.25H10.875V3.81094C5.60625 4.36875 1.5 8.83125 1.5 14.25H22.5C22.5 8.83125 18.3937 4.36875 13.125 3.81094V2.25H13.875C14.4984 2.25 15 1.74844 15 1.125C15 0.501562 14.4984 0 13.875 0H12H10.125ZM1.125 15.75C0.501562 15.75 0 16.2516 0 16.875C0 17.4984 0.501562 18 1.125 18H22.875C23.4984 18 24 17.4984 24 16.875C24 16.2516 23.4984 15.75 22.875 15.75H1.125Z"
		/>
	</svg>
</template>
