import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transition-expand" }

interface Props {
	showContent: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransitionExpand',
  props: {
    showContent: { type: Boolean, default: false }
  },
  setup(__props: any) {

// source: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/



const forceRepaintTriggerAnimation = (element: HTMLElement, height: string): void => {
	const el = element;
	// eslint-disable-next-line no-unused-expressions
	getComputedStyle(el).height;
	requestAnimationFrame(() => {
		el.style.height = height;
	});
};

const enter = (element: HTMLElement): void => {
	const el = element;
	const width = getComputedStyle(element).width;

	el.style.width = width;
	el.style.position = 'absolute';
	el.style.visibility = 'hidden';
	el.style.height = 'auto';

	const height = getComputedStyle(el).height;

	el.style.width = '';
	el.style.position = '';
	el.style.visibility = '';
	el.style.height = '0';

	forceRepaintTriggerAnimation(el, height);
};

const afterEnter = (element: HTMLElement): void => {
	const el = element;
	el.style.height = 'auto';
};

const leave = (element: HTMLElement): void => {
	const el = element;
	const height = getComputedStyle(el).height;

	el.style.height = height;

	forceRepaintTriggerAnimation(el, '0');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "expand",
    onEnter: enter,
    onAfterEnter: afterEnter,
    onLeave: leave,
    persisted: ""
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.showContent]
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})