<template>
	<svg
		width="21"
		height="24"
		viewBox="0 0 21 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Asterisk Symbol</title>
		<path
			fill="#000000"
			d="M10.2896 0C11.2378 0 12.0039 0.766071 12.0039 1.71429V8.97321L17.9771 5.38929C18.7914 4.90179 19.8414 5.16429 20.3289 5.97857C20.8164 6.79286 20.5539 7.84286 19.7396 8.33036L13.6217 12L19.7449 15.675C20.5592 16.1625 20.8217 17.2125 20.3342 18.0268C19.8467 18.8411 18.7967 19.1036 17.9824 18.6161L12.0039 15.0268V22.2857C12.0039 23.2339 11.2378 24 10.2896 24C9.34137 24 8.5753 23.2339 8.5753 22.2857V15.0268L2.60208 18.6107C1.7878 19.0982 0.737798 18.8357 0.250298 18.0214C-0.237202 17.2071 0.025298 16.1571 0.839584 15.6696L6.95744 12L0.834226 8.325C0.0199408 7.8375 -0.242559 6.7875 0.244941 5.97321C0.732441 5.15893 1.78244 4.89643 2.59673 5.38393L8.5753 8.97321V1.71429C8.5753 0.766071 9.34137 0 10.2896 0Z"
		/>
	</svg>
</template>
