import { Primitive } from 'zod';

export function flatMap<T = unknown>(list: { [x: string]: unknown }[]): T {
	return list.reduce((prev, curr) => ({ ...prev, ...curr }), {}) as T;
}

export function hasValue(o: string | number | boolean | undefined | unknown): boolean {
	if (typeof o === 'string') {
		return o.trim().length > 0;
	}
	if (typeof o === 'number') {
		return !Number.isNaN(o);
	}
	if (typeof o === 'object' && o !== null) {
		return JSON.stringify(o) !== '{}';
	}
	if (typeof o === 'boolean') {
		return true;
	}
	return false;
}

export function mergeMaps<K = string, V = unknown>(...maps: Array<Map<K, V>>): Map<K, V> {
	return new Map(
		maps.flatMap((m) => Array.from(m.entries()))
	);
}

export function filterObjectArray<T extends Record<string, Primitive>>(item: T, fieldsToFilterBy: Array<keyof T>, filterExpression: string): boolean {
	return fieldsToFilterBy.some(
		(field) => item[field]?.toString().toLowerCase().includes(filterExpression.toLowerCase())
	);
}

export function filterDuplicates<T>(array: T[]): T[] {
	//  Set ist nur unique für primitive Datentypen und die Reihenfolge NICHT deterministisch
	return Array.from(new Set(array));
}

/**
 * Returns an array of keys/props that have a falsy value [false, 0, '', null, undefined, NaN]
 * @param object
 * @returns Array of keys/props that have a falsy value [false, 0, '', null, undefined, NaN]
 */
export function getFalsyKeys<T extends Record<string, T[keyof T]>>(object: T): Array<keyof T> {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return Object.entries(object).filter(([_, value]) => !value).map(([key]) => key);
}
