import { getFluxImageUrl } from '@utils/utils';
import { getBaseUrl } from '@utils/environmentUtils';
import { HotelBoxData, CommonHotelBoxData } from '@interfaces/components/hotelBoxData';
import { BestPackageOfferForHotelList, BestAccommodationOfferForHotelList } from '@interfaces/api';
import { ApiDataTypes } from '@components/common/types';
import { mapper } from '@services/transform';
import boardTypes from '@/js/data/board-types';

enum DestinationType {
	Country = 'country',
	Region = 'region',
	City = 'city'
}

export class HotelTransformService {
	private readonly mainBaseUrl: string;

	private readonly imageBaseUrl: string;

	constructor() {
		this.mainBaseUrl = getBaseUrl();
		this.imageBaseUrl = getFluxImageUrl() || 'https://images.flux.reisen';
	}

	private generateImageUrls(iffCode: string) {
		return {
			l: `${this.imageBaseUrl}/hotel/${iffCode}/1/l`,
			m: `${this.imageBaseUrl}/hotel/${iffCode}/1/m`,
			s: `${this.imageBaseUrl}/hotel/${iffCode}/1/s`,
			xs: `${this.imageBaseUrl}/hotel/${iffCode}/1/xs`
		};
	}

	private commonProperties(offer: BestPackageOfferForHotelList | BestAccommodationOfferForHotelList): CommonHotelBoxData {
		const { Hotel: hotel, Location: location } = offer;
		return {
			Name: hotel.Name,
			HotelIffCode: hotel.IffCode,
			CityName: location.City.CityName,
			RegionName: location.Region.RegionName,
			CountryName: location.Country.CountryName,
			Category: hotel.Category,
			MeanRatingOverall: offer.Ratings.Overall,
			Image: this.generateImageUrls(hotel.IffCode)
		};
	}

	public buildLinkParams(apiParams: ApiDataTypes | false, hotelOnly = false): string {
		if (!apiParams) return '';

		const params: Record<string, any> = {
			ddate: apiParams.RelativeStartDate || apiParams.StartDate,
			rdate: apiParams.RelativeEndDate || apiParams.EndDate,
			adult: apiParams.Adults,
			dur: apiParams.MinDuration !== apiParams.MaxDuration
				? `${apiParams.MinDuration},${apiParams.MaxDuration}`
				: apiParams.MaxDuration,
			depap: apiParams.DepartureAirport,
			...(apiParams.DirectFlight ? { dfl: 1 } : {}),
			...(apiParams.TourOperatorCodes?.length ? { brand: apiParams.TourOperatorCodes?.join(',') } : {}),
			...(apiParams.BoardTypes?.length ? { board: mapper(apiParams.BoardTypes, boardTypes, (obj) => obj.showInMenu) } : {}),
			...(apiParams.Children?.length ? { child: apiParams.Children?.join('-') } : {})
		};

		const paramsArray = Object.entries(params);
		let paramsString = '?';

		paramsArray.forEach((keyValue) => {
			paramsString += `${keyValue.join('=')}&`;
		});

		paramsString += hotelOnly ? 'ibe=hotel' : 'ibe=package';
		return paramsString;
	}

	private transformBoarding(boardType: string): string {
		return boardTypes.find((type) => type.value === boardType)?.label || '';
	}

	private transformOffer(
		offer: BestPackageOfferForHotelList | BestAccommodationOfferForHotelList,
		apiParams: ApiDataTypes | false,
		hotelOnly: boolean,
		fromPlugin: boolean,
	): HotelBoxData {
		const isPackage = 'PackageOffer' in offer;
		const specificOffer = isPackage ? offer.PackageOffer : offer.AccommodationOffer;
		let url = `${this.mainBaseUrl}${offer.Hotel.URL}`;

		if (fromPlugin) {
			url += this.buildLinkParams(apiParams, hotelOnly) || '';
		}

		return {
			...this.commonProperties(offer),
			Url: url,
			Price: specificOffer.Price,
			CurrencyCode: specificOffer.CurrencyCode,
			BoardType: this.transformBoarding(specificOffer.Board),
			TravelDuration: isPackage ? specificOffer.BetweenDeparturesDuration : specificOffer.OvernightStays,
			DepartureAirportTown: isPackage ? specificOffer.Flight.OutboundFlight.AirportName : undefined,
			DepartureDay: specificOffer.StartDate,
		};
	}

	public buildListUrl(apiParams: ApiDataTypes | false, id: number, destinationType: DestinationType, hotelOnly = false): string {
	const destinationTypes = {
		[DestinationType.Country]: `/hotels/g/${id}/`,
		[DestinationType.Region]: `/hotels/r/${id}/`,
		[DestinationType.City]: `/hotels/o/${id}/`
	};

	const linkParams = this.buildLinkParams(apiParams, hotelOnly);

	return destinationTypes[destinationType] + linkParams;
}

	public transformList(
		hotelOffers: BestPackageOfferForHotelList[] | BestAccommodationOfferForHotelList[],
		apiParams: ApiDataTypes | false,
		hotelOnly = false,
		fromPlugin = true,
	): HotelBoxData[] {
		return hotelOffers.map((offer) => this.transformOffer(offer, apiParams, hotelOnly, fromPlugin));
	}
}
