import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import FilterMobile from '@lmt-rpb/FilterMobile/FilterMobile.vue';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';

type Props = {
	listType: 'hotel' | 'region' | 'offer'
	destinationLabel?: string
	header?: string,
	applyButton?: string,
	cancelButton?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterModal',
  props: {
    listType: {},
    destinationLabel: { default: '' },
    header: { default: 'Filter' },
    applyButton: { default: 'Apply' },
    cancelButton: { default: 'Cancel' }
  },
  setup(__props: any, { expose: __expose }) {

const searchModal = ref<typeof SearchModal | null>(null);
const store = useStore();

const props = __props;

const openModal = () => {
	store.dispatch('searchMask/saveCurrentState');
	window.history.replaceState(window.history.state, '', window.location.href.split('#')[0]);
	searchModal.value?.openModal();
};

const handleApply = () => {
	EventBus.$emit('FilterModal:submit');
};

const handleClose = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
};

__expose({ openModal });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(SearchModal, {
    ref_key: "searchModal",
    ref: searchModal,
    header: props.header,
    "apply-button": props.applyButton,
    "cancel-button": props.cancelButton,
    "onSearchModal:applyChanges": handleApply,
    "onSearchModal:close": handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(FilterMobile, {
        "list-type": _ctx.listType,
        "destination-label": _ctx.destinationLabel
      }, null, 8 /* PROPS */, ["list-type", "destination-label"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["header", "apply-button", "cancel-button"]))
}
}

})