<template>
	<div
		class="map-offer-box"
	>
		<HotelImageSlider
			v-if="item.NumberOfImages > 0 && isActive"
			:iff-code="item.Hotel.IffCode"
			:alt="item.Hotel.Name"
			:number-of-images="Math.min(item.NumberOfImages, 10)"
		/>
		<BookmarkHotelButton
			tabindex="-1"
			class="map-offer-box__bookmark-button"
			:item="item"
		/>
		<CircleButton
			class="map-offer-box__close-button"
			icon="crossRounded"
			viewBox="-0.5 -0.5 14 14"
			@click="close"
		/>
		<component
			:is="shouldNavigate ? 'a' : 'div'"
			:href="hotelUrl"
			:title="item.Hotel.Name"
			target="_blank"
			@click="clickHandler"
		>
			<HotelHeader
				class="map-offer-box__header"
				:hotel="item.Hotel"
				:show-suns="showSuns"
				:show-address="false"
			/>
			<div class="map-offer-box__subline">
				<div class="map-offer-box__map-container">
					<span
						v-if="item.Hotel.Category >= 4.2"
						class="map-offer-box__rating"
					>
						{{ ratingText(item.Hotel.Category) }} &ndash; {{ item.Hotel.Category }}/{{ bestRating }}
					</span>
				</div>
			</div>
			<div class="map-offer-box__text-container">
				<ul
					class="map-offer-box__text"
				>
					<li>{{ days }} Tage</li>
					<li>{{ board }}</li>
					<template v-if="item.PackageOffer">
						<br />
						<li>inkl. Flug </li>
					</template>
				</ul>
				<Price
					:price="offer.Price"
					:currency="offer.CurrencyCode"
					class="map-offer-box__price"
				/>
			</div>
		</component>
	</div>
</template>

<script lang="ts" setup>
import {
	ratingText,
} from '@utils/utils';
import Price from '@lmt-rpb/Price/Price.vue';
import HotelImageSlider from '@lmt-rpb/HotelImageSlider/HotelImageSlider.vue';
import BookmarkHotelButton from '@lmt-rpb/BookmarkHotelButton/BookmarkHotelButton.vue';
import { computed } from 'vue';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { Board as boardMapping } from '@/js/data/board-types';
import CircleButton from '@lmt-rpb/CircleButton/CircleButton.vue';
import HotelHeader from '@lmt-rpb/HotelHeader/HotelHeader.vue';
import { addEnabledParams } from '@/components/common/services/url';

interface Props {
	item: ItemType,
	showSuns: boolean,
	domain?: string,
	bestRating?: number,
	rating?: number,
	isActive?: boolean,
	isHotelPage?: boolean
	shouldNavigate?: boolean
}

const emit = defineEmits(['MapOfferBox:LinkOpened', 'MapOfferBox:Close']);

const props = withDefaults(defineProps<Props>(), {
	showSuns: false,
	domain: 'AT',
	bestRating: 6,
	rating: 2,
	isActive: true,
});

const close = () => {
	emit('MapOfferBox:Close');
};

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);

const clickHandler = () => {
	if (props.shouldNavigate) {
		emit('MapOfferBox:LinkOpened');
	} else {
		close();
	}
};

const hotelUrl = computed(() => (props.isHotelPage ? addEnabledParams(props.item.Hotel.URL) : props.item.Hotel.URL));

const board = computed(() => {
	const boardMappingProp = offer.value.Board as keyof typeof boardMapping;
	return boardMapping[boardMappingProp];
});

const days = computed(() =>	(offer.value.TravelType === 'Package' ? offer.value.BetweenDeparturesDuration : offer.value.OvernightStays));

</script>

<style lang="scss" scoped>
.map-offer-box {
	position: relative;
	background: white;
	padding: 0 0 0.8rem;
	transition: box-shadow 0.25s ease;
	box-shadow: 0 0.3rem 0.6rem $color-box-shadow;
	font-size: $font-small-1;
	line-height: 1.7;
	text-decoration: none;
	text-align: left;
	border-bottom: 0.2rem solid transparent;
	border-radius: $border-radius-regular;
	max-width: calc(100% - 1.6rem);
	isolation: isolate;

	& > a,
	& > a:hover,
	& > a:active, {
		color: $color-text;
		text-decoration: none;
	}

	& button {
		padding: 0;
	}

	:deep(.hotel-image-slider),
	:deep(.hotel-image-slider__container-image) {
		border-top-left-radius: $border-radius-regular;
		border-top-right-radius: $border-radius-regular;
	}

	:deep(swiper-container::part(button-next)),
	:deep(swiper-container::part(button-prev)) {
		width: 3.4rem;
		height: 3.4rem;
		background-color: hsl(0deg 0% 100% / 90%);
		color: $color-text;

		&::after {
			font-size: 1.5rem;
			font-weight: 900;
			-webkit-text-stroke: 0.15rem;
		}
	}

	:deep(swiper-container::part(button-next)) {
		padding-left: 0.25rem;
	}

	:deep(swiper-container::part(button-prev)) {
		padding-right: 0.25rem;
	}

	&__map-container {
		display: flex;
		align-items: center;
	}

	&__card--active {
		display: block;
	}

	&__bookmark-button {
		position: absolute;
		top: 1.6rem;
		right: 6.4rem;
		z-index: 1;
		background-color: white;
		width: 3.2rem;
		height: 3.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		cursor: pointer;

		:deep(svg) {
			height: 2.1rem;
			width: 2.1rem;
		}
	}

	:deep(.bookmark-hotel-button:focus svg) {
		fill: #A2A2A2;
		transform: scale(1);
	}

	&__close-button {
		position: absolute;
		top: 1.6rem;
		right: 1.6rem;
		z-index: 1;

		/* display: inline-block !important; */
	}

	&__header {
		margin-inline: 1.6rem;
		margin-top: 1.2rem;

		:deep(.hotel-header__name) {
			font-size: $font-small-2;
		}
	}

	&__text-container {
		padding-inline: 1.6rem 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		row-gap: 0.5rem;
	}

	&__text {
		list-style: none;
		font-size: 0;
		display: flex;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			font-size: $font-small-3;
			white-space: nowrap;
			overflow: hidden;
			font-weight: $font-weight-light;
			text-overflow: ellipsis;
		}

		li::after {
			content: '•';
			color: $color-price-text;
			display: inline-block;
			margin: 0 0.5rem;
		}

		li:last-child::after {
			content: '';
		}
	}

	&__price {
		display: block;
		font-size: $font-medium-3;
		text-align: right;
		color: $color-extra;
		font-weight: $font-weight-bold;

		:deep(small) {
			font-weight: $font-weight-bold;
		}
	}

	&__rating {
		font-size: $font-small-3;
		color: $color-text-label;
		font-weight: $font-weight-bold;
		white-space: nowrap;
	}

	&__subline {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: 0 1.6rem;
	}
}

@media screen and (min-width: $breakpoint-mobilelarge) {
	.map-offer-box {
		max-width: calc(100% - 3.2rem);

		&__header {
			:deep(.hotel-header__name) {
				font-size: $font-medium-3;
			}
		}

		:deep(swiper-container::part(button-next)),
		:deep(swiper-container::part(button-prev)) {
			width: 3.321rem;
			height: 3.321rem;

			&::after {
				font-size: $font-small-2;
			}
		}

		&__rating {
			font-size: $font-small-2;
		}

		&__text-container {
			margin-top: 0.8rem;
		}

		&__text {
			li {
				font-size: $font-small-2;
			}
		}
	}
}

@media screen and (min-width: $breakpoint-verysmall) {
	.map-offer-box {
		width: 28.2rem;
		max-width: 28.2rem;

		&__text-container {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

// Till store.config.isMobile is active
@media screen and (max-width: $breakpoint-verysmall) {
	.map-offer-box {
		position: fixed;
		bottom: 1.2rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1000;

		:deep(.hotel-image-slider__container-image) {
			width: 100%;
			height: 100%;
			max-height: 18rem;
		}

		:deep(.hotel-image-slider__image) {
			width: 100%;
			height: 100%;
			max-height: 18rem;
			object-fit: cover;
		}
	}
}

</style>
