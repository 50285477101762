<template>
	<div
		class="skeleton-loader animation--wave"
		:style="styling"
	></div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
	borderRadius?: string
	loaderWidth: number,
	loaderHeight: number,
	widthUnit?: 'rem' | '%';
}

const props = withDefaults(defineProps<Props>(), {
	borderRadius: '',
	widthUnit: 'rem'
});
const styling = computed(() => {
	if (!props.loaderWidth || !props.loaderHeight) {
		return '';
	}

	return {
		'border-radius': `${props.borderRadius}`,
		width: `${props.loaderWidth}${props.widthUnit}`,
		height: `${props.loaderHeight}rem`
	};
});

</script>

<style lang="scss" scoped>
.skeleton-loader {
	overflow: hidden;
	position: relative;
	width: fit-content;
	cursor: wait;
	background-color: rgb(215 215 215 / 50%);

	--gradient-color: #cfcfcf;
}

.skeleton-loader::before {
	content: "";
	display: block;
	position: absolute;
	inset: 0;
}

@keyframes wave {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.animation--wave::before {
	background:
		linear-gradient(
			90deg,
			transparent,
			transparent,
			var(--gradient-color),
			transparent,
			transparent,
		);
	transform: translateX(-100%);
	animation: wave 1.5s linear infinite;
}
</style>
