import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "board-type" }
const _hoisted_2 = ["onClick", "onKeydown"]
const _hoisted_3 = {
  key: 0,
  class: "select-field__price"
}
const _hoisted_4 = ["onClick", "onKeydown"]
const _hoisted_5 = {
  key: 0,
  class: "select-field__price"
}

import { watch, ref, computed } from 'vue';
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep } from '@utils/utils';
import { getLocaleString } from '@utils/environmentUtils';
import { useStore } from '@/components/common/store';
import { Board, UpsellingBoard } from '@/interfaces/search-form';
import { getOfferUpsellingBoard as requestUpsellingBoardList } from '@/components/common/services/bestOfferBoardService';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';

interface Props {
	isHotelPage?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BoardField',
  props: {
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const localeString = ref(getLocaleString('close'));
const boardTypes = computed({
	get() {
		if (store.state.searchMask?.boardTypes) {
			return store.state.searchMask.boardTypes;
		}
		return [];
	},

	set(value: string[]) {
		if (value[0] === '') {
			value = [];
		}
		store.commit('searchMask/updateFormData', { boardTypes: value });
	}
});

const selected = ref< string | null>((boardTypes.value.length && boardTypes.value[0]) || null);

watch(() => selected.value, () => {
	boardTypes.value = [selected.value || ''];
});

watch(() => boardTypes.value, () => {
	selected.value = (boardTypes.value.length && boardTypes.value[0]) || null;
});

const proxy = ref< string | null >((boardTypes.value.length && boardTypes.value[0]) || null);
const open = ref(false);
const showModal = ref(false);

const isDesktop = computed(() => store.state.config.isDesktop);

const defaultTypes = computed(() => store.state.types.board.filter((obj: any) => obj.showInMenu));

const upsellingList = ref(defaultTypes.value);

const showLoadingSpinner = ref(false);

const boardList = computed(() => (props.isHotelPage ? upsellingList.value : defaultTypes.value));

const fieldValue = computed(() => boardTypes.value.map((value: string) => boardList.value.find((x) => x.value === value)?.label).toString());

const upsellingResponseError = ref(false);

const isEntryDisabled = (entry: Board) => !entry.price && props.isHotelPage && !upsellingResponseError.value;

const clearInput = () => {
	selected.value = null;
};

const clearProxyInput = () => {
	proxy.value = null;
};

const getBoardListUpdatedPrices = (responseBoards: UpsellingBoard[]) => {
	const updatedTypes = cloneDeep(defaultTypes.value);

	updatedTypes.forEach((item) => {
		const responseItem = responseBoards.find((respItem: any) => respItem.Board.Type === item.value);
		item.price = responseItem?.Price;
	});
	return updatedTypes;
};

const getUpsellingData = async() => {
	showLoadingSpinner.value = true;
	const { result, error } = await requestUpsellingBoardList();
	if (error) {
		upsellingResponseError.value = true;
		upsellingList.value = defaultTypes.value;
		showLoadingSpinner.value = false;
		return;
	}
	upsellingResponseError.value = false;
	upsellingList.value = getBoardListUpdatedPrices(result);
	showLoadingSpinner.value = false;
};

const onToggle = (toggle: boolean) => {
	open.value = toggle;
	if (toggle && props.isHotelPage) {
		getUpsellingData();
	}
};

const onOk = () => {
	boardTypes.value = [proxy.value || ''];
	onToggle(false);
};

const onReset = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
	onToggle(false);
};

const toggleModal = () => {
	showModal.value = !showModal.value;
	if (showModal.value) {
		store.dispatch('searchMask/saveCurrentState');
		const boardTypesValue = store.state.searchMask.boardTypes;
		proxy.value = (boardTypesValue.length && boardTypesValue[0]) || null;
		if (props.isHotelPage) {
			getUpsellingData();
		}
	}
};

const select = (item: Board) => {
	if (!isEntryDisabled(item)) {
		selected.value = item.value;
		proxy.value = item.value;
	}
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(SelectField, {
          key: 0,
          ref: "boardFieldDesktop",
          modelValue: selected.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
          class: "board",
          board: true,
          items: boardList.value,
          label: "Verpflegung",
          "label-prop": 'label',
          "value-prop": "value",
          icon: "board",
          "button-wording": localeString.value,
          "item-any": true,
          loading: showLoadingSpinner.value && _ctx.isHotelPage,
          "onSelectField:Toggle": onToggle,
          "onSelectField:Clear": clearInput
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(boardList.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, [
                _createElementVNode("button", {
                  class: _normalizeClass(["select-field__list-item", {'is-selected': selected.value === item['value'], 'is-disabled' : isEntryDisabled(item)}]),
                  onClick: _withModifiers(() => select(item), ["prevent"]),
                  onKeydown: _withKeys(() => select(item), ["enter"])
                }, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1 /* TEXT */),
                  (item.price)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ab " + _toDisplayString(_unref(getPriceCorrectCurrency)(item.price)), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_2)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "items", "button-wording", "loading"]))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(FormField, {
          key: 1,
          selected: fieldValue.value,
          label: "Verpflegung",
          board: true,
          "show-modal": showModal.value,
          icon: "board",
          "show-toggle-icon": false,
          onClick: toggleModal,
          "onFormField:Clear": _cache[1] || (_cache[1] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["selected", "show-modal"]))
      : _createCommentVNode("v-if", true),
    _createVNode(Modal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
      board: true,
      class: "board__modal",
      title: "Verpflegung",
      "onModal:Ok": onOk,
      "onModal:Cancel": onReset
    }, {
      default: _withCtx(() => [
        _createVNode(SelectField, {
          ref: "boardFieldMobile",
          modelValue: proxy.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((proxy).value = $event)),
          class: "board",
          items: boardList.value,
          manual: true,
          "show-footer": false,
          "allow-clear": true,
          loading: showLoadingSpinner.value && _ctx.isHotelPage,
          "value-prop": "value",
          icon: "board",
          "onSelectField:Clear": _cache[3] || (_cache[3] = ($event: any) => {clearInput(); clearProxyInput()})
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(boardList.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, [
                _createElementVNode("button", {
                  class: _normalizeClass(["select-field__list-item", {'is-selected': proxy.value === item['value'], 'is-disabled' : isEntryDisabled(item)}]),
                  onClick: _withModifiers(() => select(item), ["prevent"]),
                  onKeydown: _withKeys(() => select(item), ["enter"])
                }, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1 /* TEXT */),
                  (item.price)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, " ab " + _toDisplayString(_unref(getPriceCorrectCurrency)(item.price)), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "items", "loading"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}
}

})