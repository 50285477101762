<template>
	<label
		:for="value"
		class="payment-radio-button"
		:class="{'selected': selected }"
	>
		<input
			:id="value"
			type="radio"
			class="payment-radio-button__input"
			:value="value"
			name="payment-radio-input"
			enterkeyhint="next"
			@change="onChange"
		>
		<div
			class="payment-radio-button__label"
			:class="{'selected': selected }"
		>{{ label }}</div>
		<div
			class="payment-radio-button__sub-label"
			:class="{'selected': selected }"
		>gebührenfrei</div>
	</label>
</template>

<script lang="ts" setup>
import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';

type Props = {
	value: RadioButtonCommonProps['value'],
	label: RadioButtonCommonProps['label'],
	selected?: RadioButtonCommonProps['selected'],
};

const emit = defineEmits(['PaymentRadio:Change']);

defineProps<Props>();

const onChange = (event: Event) => {
	emit('PaymentRadio:Change', (event?.target as HTMLInputElement).value);
};

</script>

<style lang="scss" scoped>
.payment-radio-button {
	position: relative;
	border: 0.1rem solid $color-search-mask-border;
	border-radius: $border-radius-x-small;
	min-height: 6rem;
	cursor: pointer;
	padding: 0.7rem;
	background: $color-page-background;
	margin-bottom: 0;
	box-shadow: inset #85E1EC66 0.2rem 0 0.2rem;
	box-shadow: #0000000A 0.1rem 0.2rem 0.2rem;

	&__label {
		cursor: pointer;
		font-size: $font-small-1;
		font-weight: bold;
		color: $color-checkout-radio-background;
		margin: -2rem 1rem 0 2.8rem;
		margin-left: 2.8rem;
		margin-top: -2rem;

		&.selected {
			color: $color-page-background;
		}
	}

	&__input + .payment-radio-button__label::before {
		content: '';
		position: absolute;
		top: 1.2rem;
		left: 0.7rem;
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 2rem;
		margin-right: 0.8rem;
		border: 0.1rem solid $color-checkout-button;
		flex-shrink: 0;
	}

	&__input:checked + &__label::before {
		border: 0.4rem solid $color-checkout-button;
		box-shadow: 0 0 0 0.1rem white;
		background: $color-white;
	}

	&__input:focus,
	&__input:hover,
	&__input:active {
		& + .payment-radio-button__label::before {
			box-shadow: 0 0 0 0.4rem $color-checkout-hover-radio;
		}

		&:checked + .payment-radio-button__label::before {
			border: 0.3rem solid $color-checkout-button;
			box-shadow: 0 0 0 0.1rem white;
		}
	}

	&__sub-label {
		font-weight: $font-weight-regular;
		color: #44A678;
		font-size: $font-small-3;
		margin-left: 2.8rem;

		&.selected {
			color: $color-page-background;
		}
	}

	&__input {
		cursor: pointer;
		opacity: 0;
	}

	&.selected {
		background: $color-checkout-radio-background;
		color: $color-page-background;
		border: 0.2rem solid  $color-primary;
		box-shadow: inset #00000029 0 0.3rem 0.6rem;
	}

	@media (min-width: $breakpoint-medium) {
		max-width: 25rem;
	}

	@media (min-width: $breakpoint-large) {
		&__label {
			font-size: $font-medium-3;
		}

		&__sub-label {
			font-size: $font-small-2;
		}
	}
}
</style>
