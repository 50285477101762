<template>
	<svg
		width="20"
		height="16"
		viewBox="0 0 20 16"
		fill="#000"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Wellness</title>
		<path
			d="M6.35764 7.05903C7.52778 7.77778 8.54167 8.72917 9.33681 9.85417C9.57986 10.1979 9.80208 10.559 10 10.9306C10.1979 10.5556 10.4201 10.1979 10.6632 9.85417C11.4583 8.72917 12.4722 7.77778 13.6424 7.05903C15.1944 6.10417 17.0174 5.55556 18.9583 5.55556H19.3021C19.6875 5.55556 20 5.86806 20 6.25347C20 11.3924 15.8368 15.5556 10.6979 15.5556H10H9.30208C4.16319 15.5556 0 11.3924 0 6.25347C0 5.86806 0.3125 5.55556 0.697917 5.55556H1.04167C2.98264 5.55556 4.80556 6.10417 6.35764 7.05903ZM10.4688 0.194444C11.0139 0.78125 12.5903 2.6875 13.3993 5.90972C12.0799 6.65972 10.9201 7.67361 10 8.88194C9.07986 7.67361 7.92014 6.66319 6.6007 5.90972C7.40625 2.6875 8.98264 0.78125 9.53125 0.194444C9.65278 0.0659722 9.82292 0 10 0C10.1771 0 10.3472 0.0659722 10.4688 0.194444Z"
			fill="#333333"
		/>
	</svg>
</template>
