/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { Commit } from 'vuex';
import { cloneDeep } from '@utils/utils';
import {
	CheckoutState,
	Traveller,
	Travel,
	Payment,
	Customer,
	Insurance,
	Unverified,
	BookRequestBody,
} from '@interfaces/checkout/checkout-state';
import { OfferData } from '@/interfaces/checkout/offer-data';
import { formatPrice, getFinalCurrency, getFinalPrice } from '@/js/utils/priceUtils';
import { PAYMENT_TYPES_CONSTANTS } from '@global-js/constants';
import { CardPaymentProviders } from '@/interfaces/checkout/checkoutTypes';
import { InsuranceOffer } from '@/interfaces/checkout/insurance-data';
import { ref } from 'vue';
import store from '.';

export const emptyTraveller = {
	Id: 0,
	Title: '',
	Gender: '',
	BirthDate: '',
	FirstName: '',
	LastName: '',
	AgeQualifier: 'ADULT',
} as Traveller;

const defaultState: CheckoutState = {
	Travellers: [],
	Customer: {
		Id: null,
		Title: '',
		FirstName: '',
		LastName: '',
		Gender: '',
		BirthDate: '',
		Address: '',
		PostalCode: '',
		CityName: '',
		CountryCode: '',
		Email: '',
		Phone: '',
	},
	Payment: {
		CurrencyCode: 'EUR',
		FirstName: '',
		LastName: '',
		IBAN: '',
		BIC: '',
	},
	Travel: {
		TravelType: '',
		OfferID: '', // This is the actual OfferID from the offerData request
		PaymentType: '',
		Remarks: '',
		LegalFormID: '', // Can be empty. Not required for shopping-cart request
	},
	Insurance: {
		// Insurance is an object if there are insurances available and the customer has selected one.
		// It is TRUE if there are no insurances available and the customer wants an offer.
		// It is FALSE if the customer doesn't want an offer.
		OfferID: '', // This is the InsuranceOfferID
		PaymentType: '',
		ProductName: '',
		StartDate: '',
		EndDate: '',
		CountryCode: '',
		TourOperator: '',
		TripCost: undefined,
		InsuranceCost: undefined,
	},
	Steps: {
		OpenStep: 0,
		CompletedSteps: [],
	},
	OfferData: {},
	Unverified: {
		Currency: '',
		Amount: null,
		StartDate: '',
		EndDate: '',
		ExchangeRateAmount: null,
		ExchangeRateCurrency: '',
	},
	InsuranceInformationAccepted: false,
	HasBeenValidatedBefore: false,
	InsuranceLoading: true,
	FlexStorno: null,
	FlexStornoChecked: false,
	IsCustomerTraveller: true,
};

interface ICheckoutValidationState {
	[field: string]: FieldValidation
}
export type FieldValidation = {
	valid: boolean,
	validated: boolean,
	hasBackendError: boolean,
}

export const CheckoutValidationState = ref<ICheckoutValidationState>({});
export default {
	namespaced: true,
	state: () => ({ ...defaultState }),
	actions: {
		initOfferData: ({ commit }: { commit: Commit }, offer: OfferData): void => {
			commit('UPDATE_OFFER', offer);

			commit('UPDATE_PAYMENT', {
				CurrencyCode: offer.Offer.PriceInformation?.Total.Converted?.Currency || offer.Offer.PriceInformation?.Total.Currency,
			});
			commit('UPDATE_TRAVEL', {
				TravelType: offer.Offer.TravelType,
				OfferID: offer.Offer.OfferID,
			});
		},
		updateTravellers: ({ commit }: { commit: Commit }, traveller: Traveller): void => {
			commit('UPDATE_TRAVELLERS', traveller);
		},
		updateCustomer: ({ state, commit }: { state: CheckoutState, commit: Commit }, customer: Partial<Customer>): void => {
			commit('UPDATE_CUSTOMER', customer);
			if (state.IsCustomerTraveller) {
				const customerCopy = cloneDeep(customer);
				customerCopy.Id = 0;
				commit('UPDATE_TRAVELLERS', customerCopy);
			}
		},
		updateInsurance: ({ commit }: { commit: Commit }, insurance: Partial<Insurance> | boolean): void => {
			commit('UPDATE_INSURANCE', insurance);
		},
		resetInsurance: ({ commit }: { state: CheckoutState, commit: Commit }): void => {
			commit('RESET_INSURANCE');
		},
		updatePayment: ({ commit }: { commit: Commit }, payment: Partial<Payment>): void => {
			commit('UPDATE_PAYMENT', payment);
		},
		updateTravel: ({ commit }: { commit: Commit }, travel: Partial<Travel>): void => {
			commit('UPDATE_TRAVEL', travel);
		},
		updateUnverifiedData: ({ commit }: { commit: Commit }, unverifiedData: Partial<Unverified>): void => {
			commit('UPDATE_UNVERIFIED_DATA', unverifiedData);
		},
		updateInsuranceInformationAccepted: ({ commit }: { commit: Commit }, insuranceInformationAccepted: boolean): void => {
			commit('UPDATE_INSURANCE_INFORMATION_ACCEPTED', insuranceInformationAccepted);
		},
		updateInsuranceHasBeenValidatedBefore: ({ commit }: { commit: Commit }, hasBeenValidatedBefore: boolean): void => {
			commit('UPDATE_INSURANCE_VALIDATED_BEFORE', hasBeenValidatedBefore);
		},
		updateInsuranceLoading: ({ commit }: { commit: Commit }, insuranceLoading: boolean): void => {
			commit('UPDATE_INSURANCE_LOADING', insuranceLoading);
		},
		updateIsCustomerTraveller: ({ state, commit }: { state: CheckoutState, commit: Commit }, isCustomerTraveller: boolean): void => {
			commit('UPDATE_ISCONTACT_TRAVELLER', isCustomerTraveller);

			const customerCopy = state.IsCustomerTraveller ? cloneDeep(state.Customer) : cloneDeep(emptyTraveller);
			customerCopy.Id = 0;
			commit('UPDATE_TRAVELLERS', customerCopy);
		},
	},
	mutations: {
		UPDATE_OFFER(state: CheckoutState, offer: OfferData): void {
			// eslint-disable-next-line no-param-reassign
			state.OfferData = offer;
		},
		UPDATE_TRAVELLERS(state: CheckoutState, traveller: Traveller): void {
			const travellers = [...state.Travellers];
			const foundTravellerIndex = travellers.findIndex((stateTraveller) => stateTraveller.Id === traveller.Id);
			if (foundTravellerIndex !== -1) {
				const t = travellers[foundTravellerIndex];
				t.FirstName = traveller.FirstName ?? t.FirstName;
				t.LastName = traveller.LastName ?? t.LastName;
				t.Gender = traveller.Gender ?? t.Gender;
				t.BirthDate = traveller.BirthDate ?? t.BirthDate ?? '--';
				t.Title = traveller.Title ?? t.Title;
			} else if (traveller.Id !== 99) {
				travellers.push(traveller);
			}
			state.Travellers = travellers;
		},
		UPDATE_CUSTOMER(state: CheckoutState, customer: Partial<Customer>): void {
			state.Customer = { ...state.Customer, ...customer };
		},
		UPDATE_INSURANCE(state: CheckoutState, insurance: InsuranceOffer | boolean): void {
			state.Insurance = insurance;
		},
		RESET_INSURANCE(state: CheckoutState): void {
			// eslint-disable-next-line no-param-reassign
			state.Insurance = {};
		},
		UPDATE_PAYMENT(state: CheckoutState, payment: Partial<Payment>): void {
			// eslint-disable-next-line no-param-reassign
			state.Payment = { ...state.Payment, ...payment };
		},
		UPDATE_TRAVEL(state: CheckoutState, travel: Partial<Travel>): void {
			// eslint-disable-next-line no-param-reassign
			state.Travel = { ...state.Travel, ...travel };
		},
		UPDATE_UNVERIFIED_DATA(state: CheckoutState, unverifiedData: Partial<Unverified>): void {
			// eslint-disable-next-line no-param-reassign
			state.Unverified = { ...state.Unverified, ...unverifiedData };
		},
		UPDATE_INSURANCE_INFORMATION_ACCEPTED(state: CheckoutState, insuranceInformationAccepted: boolean): void {
			// eslint-disable-next-line no-param-reassign
			state.InsuranceInformationAccepted = insuranceInformationAccepted;
		},
		UPDATE_INSURANCE_VALIDATED_BEFORE(state: CheckoutState, hasBeenValidatedBefore: boolean): void {
			// eslint-disable-next-line no-param-reassign
			state.HasBeenValidatedBefore = hasBeenValidatedBefore;
		},
		UPDATE_INSURANCE_LOADING(state: CheckoutState, insuranceLoading: boolean): void {
			// eslint-disable-next-line no-param-reassign
			state.InsuranceLoading = insuranceLoading;
		},
		updateCheckout(state: CheckoutState, update: { [key: string]: any }): void {
			Object.keys(update).forEach((key: string) => {
				state[key] = update[key];
			});
		},
		UPDATE_ISCONTACT_TRAVELLER(state: CheckoutState, isCustomerTraveller: boolean): void {
			// eslint-disable-next-line no-param-reassign
			state.IsCustomerTraveller = isCustomerTraveller;
		},
	},
	getters: {
		formfields: (state: CheckoutState): BookRequestBody => {
			const travellersWithoutAgeQualifier = state.Travellers
				.map(
					({
						Id, Title, Gender, BirthDate, FirstName, LastName
					}) => ({
						Id, Title, Gender, BirthDate, FirstName, LastName
					})
				);
			return {
				SessionID: state.OfferData.SessionID,
				OfferToken: state.OfferData.OfferToken,
				Travellers: travellersWithoutAgeQualifier,
				Customer: state.Customer,
				Payment: state.Payment,
				Travel: state.Travel,
				Insurance: store.getters['checkout/insurance'],
				OptionalFlexRate: state.FlexStornoChecked ? state.FlexStorno?.FlexToken : undefined,
			};
		},
		travellers: (state: CheckoutState): Traveller[] => state.Travellers,
		adultTravellers: (state: CheckoutState): Traveller[] => state.Travellers.filter(
			(traveller) => traveller.AgeQualifier === 'ADULT' && traveller.FirstName && traveller.LastName
		),
		customer: (state: CheckoutState): Customer => state.Customer,
		insurance: (state: CheckoutState): Insurance | boolean => {
			if (typeof state.Insurance === 'boolean') {
				return state.Insurance;
			}
			return {
				OfferID: state.Insurance.OfferID,
				ProductName: state.Insurance.Name,
				InsuranceCost: state.Insurance.Price,
				PaymentType: 'DirectDebitInternational', // only DirectDebitInternational available at the moment
				PriceInformation: state.Insurance.PriceInformation,
				StartDate: state.OfferData.Offer.StartDate,
				EndDate: state.OfferData.Offer.EndDate,
				TourOperator: state.OfferData.Offer.TourOperator.Code,
				TripCost: state.OfferData.Offer.PriceInformation?.Total.Amount,
				CountryCode: state.OfferData.Offer.Hotel.CountryCode,
				InsuranceInformationAccepted: state.InsuranceInformationAccepted,
			};
		},
		insuranceOffer: (state: CheckoutState): InsuranceOffer | boolean => state.Insurance,
		isBankTransfer: (state: CheckoutState): boolean => state.Travel.PaymentType === 'BankTransfer',
		isPayPalPayment: (state: CheckoutState): boolean => state.Travel.PaymentType === 'PayPal',
		isSepaPayment: (state: CheckoutState): boolean => state.Travel.PaymentType === PAYMENT_TYPES_CONSTANTS.SEPA,
		isCardPayment: (state: CheckoutState): boolean => (['AX', 'VI', 'MC'] satisfies CardPaymentProviders[]).includes(state.Travel.PaymentType as CardPaymentProviders),
		insuranceInformationAccepted: (state: CheckoutState): boolean => state.InsuranceInformationAccepted,
		payment: (state: CheckoutState): Payment => state.Payment,
		travel: (state: CheckoutState): Travel => state.Travel,
		unverified: (state: CheckoutState): Unverified => state.Unverified,
		finalPrice: (state: CheckoutState) => (includeInsurance = true, flexStorno = true): number => finalPriceFunction(state.OfferData as OfferData, state.Unverified, includeInsurance && state.Insurance, state, flexStorno),
		finalPriceConverted: (state: CheckoutState) => (includeInsurance = true, flexStorno = true): number => finalPriceFunction(state.OfferData as OfferData, state.Unverified, includeInsurance && state.Insurance, state, flexStorno, true),
		finalCurrency: (state: CheckoutState) => (): string => finalCurrencyFunction(state.OfferData as OfferData, state.Unverified),
		finalCurrencyConverted: (state: CheckoutState) => (): string => finalCurrencyFunction(state.OfferData as OfferData, state.Unverified, true),

		finalPriceWithCurrency: (state: CheckoutState) => (includeInsurance = true, flexStorno = true): string => {
			const price = finalPriceFunction(state.OfferData as OfferData, state.Unverified, includeInsurance && store.getters['checkout/insurance'], state, flexStorno);
			return formatPrice(price, finalCurrencyFunction(state.OfferData as OfferData, state.Unverified), true);
		},
		finalPriceWithCurrencyConverted: (state: CheckoutState) => (includeInsurance = true, flexStorno = true): string => {
			const price = finalPriceFunction(state.OfferData as OfferData, state.Unverified, includeInsurance && store.getters['checkout/insurance'], state, flexStorno, true);
			return formatPrice(price, finalCurrencyFunction(state.OfferData as OfferData, state.Unverified, true), true);
		},
		isCustomerTraveller: (state: CheckoutState) => state.IsCustomerTraveller,
		validTravelersData: (state: CheckoutState) => state.Travellers.every((traveller: Traveller) => {
			if (!traveller.BirthDate) {
				return false;
			}
			let id = traveller.Id;
			if (id === 0 && store.state.checkout.IsCustomerTraveller) {
				// we need to check customer birthday for traveller 0, if "ich reise selbst"
				id = 99;
			}
			return CheckoutValidationState.value['birth-date-' + id]?.valid ?? true;
		}),
	},
};

const finalPriceFunction = (offerData: OfferData, unverified: Unverified, insurance: Insurance | boolean, state : CheckoutState, withoutStorno: boolean, converted = false): number => {
	const { PriceInformation, TravellerAmount } = offerData.Offer;
	const { Status } = offerData;
	const price = converted && PriceInformation?.Total.Converted?.Amount ? PriceInformation.Total.Converted.Amount : PriceInformation?.Total.Amount;
	const unverifiedPrice = converted && unverified.ExchangeRateAmount ? (unverified.Amount || 0) * unverified.ExchangeRateAmount : unverified.Amount;

	let finalPrice = getFinalPrice(Status, price, unverifiedPrice as number, TravellerAmount);

	if (insurance && typeof insurance === 'object' && insurance.PriceInformation) {
		const { PriceInformation: insurancePriceInfo } = insurance;
		const convertedPrice = insurancePriceInfo?.Converted;
		let amount = insurancePriceInfo?.Amount;
		if (converted && convertedPrice?.Amount) {
			amount = convertedPrice.Amount;
		}
		finalPrice += amount ?? 0;
	}
	if (withoutStorno && state.FlexStorno && state.FlexStornoChecked) {
		const totalPrice = state.FlexStorno.FlexRate.Price;
		const flexPrice = converted ? totalPrice.Converted!.Amount : totalPrice.Amount;
		finalPrice += flexPrice;
	}

	return finalPrice;
};

const finalCurrencyFunction = (offerData: OfferData, unverified: Unverified, converted = false): string => {
	const convertedCurrency = offerData.Offer.PriceInformation?.Total.Converted?.Currency;
	const currency = converted && convertedCurrency ? convertedCurrency : offerData.Offer.PriceInformation?.Total.Currency;
	const unverifiedCurrency = converted && unverified.ExchangeRateCurrency ? unverified.ExchangeRateCurrency : unverified.Currency;
	return getFinalCurrency(offerData.Status, currency, unverifiedCurrency);
};
