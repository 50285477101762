import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dropdown-filter-type" }
const _hoisted_2 = {
  key: 1,
  class: "dropdown-filter-type__mobile"
}
const _hoisted_3 = {
  key: 0,
  class: "dropdown-filter-type__mobile-header"
}

import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import { useStore } from '@/components/common/store';
import { computed, ref } from 'vue';
import { getLocaleString } from '@utils/environmentUtils';

type Props = {
	title: string
	modalTitle?: string
	icon: string
	showButtons?: boolean
	placeholder?: string
	selectedValue?: string
	disabled?: boolean
	buttonDisabled?: boolean
	searchable?: boolean
	emitOnOk?: boolean
	withClearButton?: boolean
	applyButtonText?: string
	cancelButtonText?: string
	searchTerm?: string
	withMenuHeader?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownFilterType',
  props: {
    title: {},
    modalTitle: { default: undefined },
    icon: {},
    showButtons: { type: Boolean, default: false },
    placeholder: { default: 'Beliebig' },
    selectedValue: { default: undefined },
    disabled: { type: Boolean },
    buttonDisabled: { type: Boolean },
    searchable: { type: Boolean },
    emitOnOk: { type: Boolean, default: false },
    withClearButton: { type: Boolean, default: false },
    applyButtonText: { default: undefined },
    cancelButtonText: { default: undefined },
    searchTerm: { default: undefined },
    withMenuHeader: { type: Boolean, default: true }
  },
  emits: ['DropdownFilterType:apply', 'DropdownFilterType:abort', 'DropdownFilterType:filter', 'DropdownFilterType:clear', 'update:searchTerm'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const props = __props;

const emit = __emit;

const isDesktop = computed(() => store.state.config.isDesktop);
const desktopField = ref<null | InstanceType<typeof DropdownField>>(null);

const searchTerm = computed({
	get() {
		return props.searchTerm ?? '';
	},
	set(value: string) {
		emit('update:searchTerm', value);
	}
});
const fieldValue = computed(() => {
	if (!props.searchable) {
		return props.selectedValue;
	}
	return dropdownOpen.value ? searchTerm.value : props.selectedValue;
});

const modal = ref<null | InstanceType<typeof SearchModal>>(null);
const modalHeader = computed(() => `${props.modalTitle ?? props.title} auswählen`);
const openModal = () => {
	if (props.disabled) return;
	modal.value?.openModal();
	searchTerm.value = '';
};

const close = () => {
	desktopField.value?.closeDropdown();
	modal.value?.handleClose();
};

const dropdownOpen = ref(false);
const handleToggle = (isOpen: boolean) => {
	dropdownOpen.value = isOpen;
};
const actualPlaceholder = computed(() => {
	if (!props.searchable) {
		return props.placeholder;
	}
	return dropdownOpen.value ? 'Suchen' : props.placeholder;
});

const handleUpdate = (value: string) => {
	if (!props.searchable) {
		return;
	}
	searchTerm.value = value;
};

const handleOk = (e) => {
	if (props.emitOnOk) {
		emit('DropdownFilterType:apply', e);
	}
	close();
};

const handleOutsideClick = (e) => {
	emit('DropdownFilterType:abort', e);
	close();
};

const handleApply = (e) => {
	emit('DropdownFilterType:apply', e);
};
const handleAbort = (e) => {
	emit('DropdownFilterType:abort', e);
};
const handleClear = () => {
	emit('DropdownFilterType:clear');
};

__expose({
	close,
	open: openModal
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "desktopField",
          ref: desktopField,
          class: "dropdown-filter-type__desktop",
          "model-value": fieldValue.value,
          label: _ctx.title,
          placeholder: actualPlaceholder.value,
          icon: _ctx.icon,
          "show-footer": _ctx.showButtons,
          "button-wording": _ctx.applyButtonText ?? _unref(getLocaleString)('close'),
          disabled: _ctx.disabled,
          readonly: !_ctx.searchable || !dropdownOpen.value,
          "with-clear-button": _ctx.withClearButton,
          "button-disabled": _ctx.buttonDisabled,
          searchable: _ctx.searchable,
          "onUpdate:modelValue": handleUpdate,
          "onDropdownField:Ok": handleOk,
          "onDropdownField:OutsideClick": handleOutsideClick,
          "onDropdownField:open": _cache[0] || (_cache[0] = ($event: any) => (handleUpdate(''))),
          "onDropdownField:Toggle": handleToggle,
          "onDropdownField:Clear": handleClear
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {
              close: close,
              open: openModal
            })
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["model-value", "label", "placeholder", "icon", "show-footer", "button-wording", "disabled", "readonly", "with-clear-button", "button-disabled", "searchable"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.withMenuHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createVNode(FormField, {
            class: "dropdown-filter-type__mobile-form-field",
            selected: _ctx.selectedValue,
            "show-modal": true,
            label: _ctx.title,
            icon: _ctx.icon,
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled,
            onClick: openModal
          }, null, 8 /* PROPS */, ["selected", "label", "icon", "placeholder", "disabled"]),
          _createVNode(SearchModal, {
            ref_key: "modal",
            ref: modal,
            modelValue: searchTerm.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchTerm).value = $event)),
            class: "dropdown-filter-type__mobile-modal",
            header: modalHeader.value,
            "cancel-button": _ctx.showButtons ? (_ctx.cancelButtonText ?? 'Abbrechen') : '',
            "apply-button": _ctx.showButtons ? (_ctx.applyButtonText ?? 'Ok') : '',
            searchable: _ctx.searchable,
            "with-clear-button": _ctx.withClearButton,
            "input-clearable": "",
            "onSearchModal:applyChanges": handleApply,
            "onSearchModal:close": handleAbort,
            "onSearchModal:clear": handleClear
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                close: close,
                open: openModal
              })
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["modelValue", "header", "cancel-button", "apply-button", "searchable", "with-clear-button"])
        ]))
  ]))
}
}

})