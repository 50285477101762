import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["aria-expanded", "onKeydown"]
const _hoisted_2 = { class: "inner-dropdown__inner" }
const _hoisted_3 = {
  class: "inner-dropdown__close-icon",
  viewBox: "0 0 27 32"
}
const _hoisted_4 = { class: "inner-dropdown__container" }

import FormField from '@lmt-rpb/FormField/FormField.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import {
	computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';

interface Props {
	modelValue?: string,
	label?: string,
	icon?: string,
	readonly?: boolean,
	wide?: boolean,
	manual?: boolean,
	closeOnEsc?: boolean,
	showFooter?: boolean,
	formFieldClass?: string,
	board?: boolean,
	placeholder?: string,
	allowClear?: boolean,
	showToggleIcon?: boolean,
	showCloseIcon?: boolean,
	disabled?: boolean,
	preventMousedown?: boolean,
	buttonWording?: string,
	buttonCancel?: string,

}


export default /*@__PURE__*/_defineComponent({
  __name: 'InnerDropdown',
  props: {
    modelValue: { default: '' },
    label: { default: '' },
    icon: { default: '' },
    readonly: { type: Boolean, default: true },
    wide: { type: Boolean, default: false },
    manual: { type: Boolean, default: undefined },
    closeOnEsc: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    formFieldClass: { default: '' },
    board: { type: Boolean, default: false },
    placeholder: { default: 'Beliebig' },
    allowClear: { type: Boolean, default: false },
    showToggleIcon: { type: Boolean, default: true },
    showCloseIcon: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preventMousedown: { type: Boolean, default: true },
    buttonWording: { default: 'Übernehmen' },
    buttonCancel: { default: 'Abbrechen' }
  },
  emits: [
	'update:modelValue',
	'DropdownField:Navigate',
	'DropdownField:FieldClick',
	'DropdownField:OutsideClick',
	'DropdownField:Toggle',
	'DropdownField:Focus',
	'DropdownField:Cancel',
	'DropdownField:Ok',
	'DropdownField:InputFocus',
	'DropdownField:Blur',
	'DropdownField:Clear',
	'DropdownField:open',
],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const store = useStore();

const props = __props;
const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: string) {
		if (typeof newValue === 'string') {
			emit('update:modelValue', newValue);
		}
	}
});

const root = ref();

const el = ref<HTMLElement | null>(null);

const open = ref<boolean>(props.manual || false);

const contentRender = ref<boolean>(props.manual || false);

const box = ref<HTMLElement | null>(null);

const field = ref<InstanceType<typeof FormField> | null>(null);

const dropdownClasses = computed((): string[] => {
	const state = open.value ? 'is-open' : '';
	const theme = props.wide ? 'wide' : 'narrow';
	const footer = !props.showFooter ? 'is-without-footer' : '';

	return [state, `dropdown--${theme}`, footer];
});

const isHeaderSearch = computed((): boolean => !!(root.value?.headerSearch));

const pageType = computed((): string => store.state.config.pageType);

const calendarDateChanged = computed((): boolean => store.state.calendarDateChanged);

const onKeydown = (e: KeyboardEvent): void => {
	if (open.value && [38, 40].indexOf(e.which) !== -1) {
		e.preventDefault();
		emit('DropdownField:Navigate', e.which === 38 ? -1 : 1);
	}
};

const closeDropdown = (): void => {
	open.value = false;
};

const checkTarget = (e: MouseEvent) => {
	if ((e.target as Element).parentElement && ((e.target as Element).parentElement as Element).classList.contains('destination-field')) {
		return false;
	}
	if (open.value && e.target && !el.value?.contains((e.target as Node))) {
		emit('DropdownField:OutsideClick');
		closeDropdown();
	}
};

const onOpen = (parent: string) => {
	if (parent === 'autocomplete') {
		document.addEventListener('click', checkTarget);
	}
};

const openDropdown = (): void => {
	if (props.disabled) {
		return;
	}
	open.value = true;
};

const toggleDropdownEsc = (): void => {
	emit('DropdownField:OutsideClick');
};

const onFieldClick = (e: MouseEvent): void => {
	e.stopPropagation();

	if (!isHeaderSearch.value && pageType.value === 'themePage' && window.innerWidth > 1440) {
		EventBus.$emit('OfferSearch:ScrollUpSearchForm');
	}

	if (!e || !e.target) {
		openDropdown();
		return;
	}

	const target = e.target as Node;
	const clickOnContainer = box.value && box.value.contains(target);

	const clickOnField = el.value?.contains(target) && !clickOnContainer;

	if (props.manual !== undefined && !calendarDateChanged.value) {
		emit('DropdownField:FieldClick', clickOnField, clickOnContainer);
		return;
	}

	// If calendar is open and date changes, prevent toggle dropdown on field click
	if (calendarDateChanged.value) {
		if ((e.target as Element).classList.contains('offer-duration__field')) {
			emit('DropdownField:OutsideClick');
		}
		return;
	}

	if (clickOnField && !open.value) {
		openDropdown();
	} else if (clickOnField && open.value) {
		closeDropdown();
	}
};

const toggleDropdown = (e?: MouseEvent): void => {
	if (!open.value && e) {
		onFieldClick(e);
	} else {
		open.value = !open.value;
	}
};

const onEnter = (): void => {
	if (props.manual === undefined) {
		toggleDropdown();
	}
};

watch(() => props.manual, () => {
	open.value = props.manual;
});

watch(() => open.value, () => {
	const inputEl = el.value?.querySelector('input');

	// remove listener if any
	document.removeEventListener('click', checkTarget);
	EventBus.$off('keydown.escape', toggleDropdownEsc);

	if (open.value) {
		if (inputEl) {
			inputEl.focus();
		}

		document.addEventListener('click', checkTarget);

		if (props.closeOnEsc) {
			EventBus.$once('keydown.escape', toggleDropdownEsc);
		}
	}
	emit('DropdownField:Toggle', open.value);
});

onBeforeMount(() => {
	watch(() => open.value, () => {
		const unwatch = () => {
			contentRender.value = true;
		};
		unwatch();
	});
});

onMounted(() => {
	document.addEventListener('keydown', onKeydown);
	root.value = getCurrentInstance()?.proxy.$root;
	el.value = getCurrentInstance()?.proxy.$el as HTMLElement;
});

onBeforeUnmount(() => {
	document.removeEventListener('keydown', onKeydown);
});

__expose({
	closeDropdown,
	toggleDropdown,
	onOpen,
	open,
	contentRender,
	field
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inner-dropdown", dropdownClasses.value]),
    "aria-expanded": `${open.value}`,
    onClick: onFieldClick,
    onKeydown: _withKeys(_withModifiers(onEnter, ["prevent"]), ["enter"])
  }, [
    _createVNode(FormField, {
      ref_key: "field",
      ref: field,
      selected: model.value,
      "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      tabindex: "0",
      class: _normalizeClass(["dropdown__field", _ctx.formFieldClass]),
      label: _ctx.label,
      readonly: _ctx.readonly,
      board: _ctx.board,
      icon: _ctx.icon,
      open: open.value,
      placeholder: _ctx.placeholder,
      "allow-clear": _ctx.allowClear,
      "show-toggle-icon": _ctx.showToggleIcon,
      "prevent-mousedown": _ctx.preventMousedown,
      "onFormField:Focus": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('DropdownField:Focus', $event))),
      "onFormField:Blur": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('DropdownField:Blur', $event))),
      "onFormField:Clear": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('DropdownField:Clear', $event))),
      onInputFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('DropdownField:InputFocus', $event)))
    }, null, 8 /* PROPS */, ["selected", "class", "label", "readonly", "board", "icon", "open", "placeholder", "allow-clear", "show-toggle-icon", "prevent-mousedown"]),
    _createElementVNode("div", {
      ref_key: "box",
      ref: box,
      class: "inner-dropdown__box"
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showFooter && _ctx.showCloseIcon)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "inner-dropdown__close",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('DropdownField:Cancel')))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                _createElementVNode("title", null, _toDisplayString(_unref(getLocaleString)('close')), 1 /* TEXT */),
                _cache[6] || (_cache[6] = _createElementVNode("path", { d: "M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" }, null, -1 /* HOISTED */))
              ]))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          (contentRender.value)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : _createCommentVNode("v-if", true)
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})