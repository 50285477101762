<template>
	<ul class="mostPopularFilters">
		<template
			v-for="(availableFilter, index) in listOfAvailableFilters"
			:key="index"
		>
			<li class="mostPopularFilters__item">
				<Checkbox
					v-if="availableFilter.attrType === 'HotelAttributes'"
					v-model="hotelFilters"
					:label="availableFilter.label"
					:custom-value="availableFilter.value"
				/>
				<Checkbox
					v-else
					v-model="ratingFilters"
					:label="availableFilter.label"
					:custom-value="availableFilter.value"
				/>
			</li>
		</template>
	</ul>
</template>

<script lang="ts" setup>
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { mostPopularTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';

const store = useStore();

const listOfAvailableFilters = ref(mostPopularTypes);

const hotelFilters = computed({
	get() {
		if (store.state.searchMask.mostPopularFilters?.hotelAttributes) {
			return store.state.searchMask.mostPopularFilters?.hotelAttributes;
		}
		return [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			// eslint-disable-next-line no-use-before-define
			{ mostPopularFilters: { ratingAttributes: ratingFilters.value, hotelAttributes: value } }
		);
	}
});

const ratingFilters = computed({
	get() {
		if (store.state.searchMask.mostPopularFilters?.ratingAttributes) {
			return store.state.searchMask.mostPopularFilters?.ratingAttributes;
		}
		return [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			{ mostPopularFilters: { ratingAttributes: value, hotelAttributes: hotelFilters.value } }
		);
	}
});

</script>

<style lang="scss" scoped>

 

.mostPopularFilters {
	margin: 0;
	padding: 0;
	padding-left: 0.8rem;
	list-style: none;

	&__item {
		display: block;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
