<template>
	<div class="flex-filter">
		<div class="flex-filter__header">
			<h2 class="flex-filter__title">
				Flexibel buchen
			</h2>
			<button
				v-if="hasSelectedFilter"
				class="flex-filter__button"
				@click="clearFlexFilters"
			>
				Löschen
			</button>
		</div>
		<div
			v-for="item in flexItem"
			:key="item.Code"
			class="flex-filter__container"
		>
			<FilterRadioButton
				id="flex-filter"
				v-model="flexFilters"
				:label="item.Name"
				:value="item.Code"
			>
				<template #tooltip>
					<span class="flex-filter__tooltip">
						<InfoTooltip
							placement="bottom"
							modifier-class="info-tooltip__body"
						>
							{{ item.Tooltip }}
						</InfoTooltip>
					</span>
				</template>
			</FilterRadioButton>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { useStore } from '@/components/common/store';
import flexItem from '@/js/data/flex-filter-types';

const store = useStore();

const flexFilters = computed({
	get() {
		return store.state.searchMask.flex as string;
	},
	set(value: string) {
		store.commit('searchMask/updateFormData', {
			flex: value,
		});
	},
});

function clearFlexFilters() {
	store.commit('searchMask/updateFormData', {
		flex: null,
	});
}

const hasSelectedFilter = computed(() => store.state.searchMask.flex !== null);
</script>

<style lang="scss" scoped>
.flex-filter {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.6rem;

	:deep(.filter-radio-button__input) {
		margin: 0;
		padding: 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 0.8rem;
		cursor: default;
		width: 100%;
	}

	&__title {
		padding: 0;
		text-align: left;
		font-family: $font-family;
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__button {
		background-color: transparent;
		border: 0 solid;
		color: $color-primary;
		font-size: $font-small-3;
		font-weight: $font-weight-semibold;
		text-align: left;
		margin: 0;
		cursor: pointer;
	}

	&__container {
		display: flex;
		align-items: center;
		padding-inline: 0;
		padding-right: 3rem;
		padding-left: 0.8rem;

		& > * {
			max-width: 28rem;
		}

		:deep(.filter-radio-button > .filter-radio-button__input) {
			flex-shrink: 0;
			align-self: flex-start;
		}
	}

	&__tooltip {
		margin-left: 1rem;

		:deep(.info-tooltip__body) {
			font-size: $font-small-1;
			left: -2rem !important;
			right: 35% !important;
			max-width: 31rem !important;
		}

		:deep(.info-tooltip__arrow) {
			left: 2rem !important;
		}

		:deep(.info-tooltip__icon) {
			overflow: visible;
			opacity: 1;
			width: 1.6rem;
			height: 1.6rem;
			fill: $color-primary;
			cursor: pointer;
		}

		:deep(.info-tooltip__icon-container) {
			height: fit-content;
			width: fit-content;
		}
	}
}

@media (min-width: $breakpoint-verysmall) {
	.flex-filter {
		&__container {
			& > * {
				max-width: 100%;
			}
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flex-filter {
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: initial;
			box-shadow: none;
		}

		&__title {
			font-size: $font-medium-2;
		}
	}
}
</style>
