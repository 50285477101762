<template>
	<div
		v-if="feature"
		class="flex-feature"
		:class="{'flex-feature-offerlist': !hasHotelListStyle}"
		@mouseover="showTooltip = true"
		@mouseleave="showTooltip = false"
	>
		<div class="flex-feature__shield-container">
			<BaseIcon
				v-if="showShield"
				name="shield"
				class=""
			/>
		</div>

		<div
			class="flex-feature__title"
		>
			<span>{{ feature.title }}</span>
			<InfoTooltip
				class="flex-feature__tooltip"
				:show-tooltip-parent="showTooltip"
			>
				{{ feature.description }}
			</InfoTooltip>
		</div>
	</div>
</template>

<script lang="ts" setup>

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ref } from 'vue';
import { SingleFeature } from '@/interfaces/common';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';

interface Props {
	feature: SingleFeature,
	hasHotelListStyle?: boolean,
	showShield?: boolean,
}

withDefaults(defineProps<Props>(), {
	hasHotelListStyle: false,
	showShield: true,
});

const showTooltip = ref(false);

</script>

<style lang="scss" scoped>
.flex-feature {
	display: flex;
	gap: 0.5rem;
	align-items: first baseline;
	color: $color-green-text;
	font-weight: $font-weight-semibold;
	margin-top: 0.6rem;

	&__description {
		list-style: none;
		margin: 0;
		padding: 4px 8px;
	}

	&__tooltip {
		margin-left: 0.5rem;
		display: inline-flex;
	}

	&__shield-container {
		flex-shrink: 0;
		fill: $color-green-text;
		width: 1rem;
		height: 1rem;
	}

	&__shield {
		flex-shrink: 0;
		fill: $color-green-text;
		width: 1rem;
		height: 1rem;
		position: relative;
		bottom: 0.1rem;
	}
}

.flex-feature-offerlist {
	:deep(.info-tooltip__icon-container) {
		position: relative;
		top: 0.1rem;
	}

	.flex-feature__shield {
		display: inline;
		height: 1.2rem;
		width: 1.2rem;
	}
}
</style>
