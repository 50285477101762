<template>
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
        <title>Rooms</title>
		<path
			d="M1.25 1.875C1.25 0.839844 2.08984 0 3.125 0H16.875C17.9102 0 18.75 0.839844 18.75 1.875V6.91797C18.1836 6.58984 17.5508 6.37109 16.875 6.28906C16.875 6.27734 16.875 6.26172 16.875 6.25V5C16.875 4.30859 16.3164 3.75 15.625 3.75H11.875C11.1836 3.75 10.625 4.30859 10.625 5V6.25H9.375V5C9.375 4.30859 8.81641 3.75 8.125 3.75H4.375C3.68359 3.75 3.125 4.30859 3.125 5V6.25C3.125 6.26172 3.125 6.27734 3.125 6.28906C2.44922 6.375 1.8125 6.59375 1.25 6.91797V1.875ZM0 11.25C0 9.17969 1.67969 7.5 3.75 7.5H16.25C18.3203 7.5 20 9.17969 20 11.25V16.25C20 16.9414 19.4414 17.5 18.75 17.5C18.0586 17.5 17.5 16.9414 17.5 16.25V15H2.5V16.25C2.5 16.9414 1.94141 17.5 1.25 17.5C0.558594 17.5 0 16.9414 0 16.25V11.25Z"
			fill="#333333"
		/>
	</svg>
</template>
