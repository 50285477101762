<template>
	<DropdownFilterType
		v-model:search-term="filterExpression"
		class="airport-filter"
		title="Abflughafen"
		icon="flightStart"
		modal-title="Flughafen"
		apply-button-text="Übernehmen"
		cancel-button-text="Verwerfen"
		:with-clear-button="selectedOptionsProxy.length > 0"
		:selected-value="selectedOptionsConcat"
		:with-menu-header="false"
		:button-disabled="!!notFoundMessage"
		show-buttons
		searchable
		emit-on-ok
		@dropdown-filter-type:apply="applyChanges"
		@dropdown-filter-type:abort="cancel"
		@dropdown-filter-type:clear="clearSelectedOptions"
	>
		<template #default>
			<p
				v-if="notFoundMessage"
				class="airport-filter__not-found"
			>
				{{ notFoundMessage }}
			</p>
			<template v-else>
				<CheckBox
					v-for="airport in filteredItems"
					:key="airport.value"
					v-model="selectedOptionsProxy"
					:label="airport.label"
					:custom-value="airport.value"
					class="airport-filter__checkbox"
				>
					<template #default>
						<p class="airport-filter__checkbox-label">
							<b class="airport-filter__checkbox-label--strong">{{ airport.label }}</b>
							&nbsp;({{ airport.value }})
						</p>
					</template>
				</CheckBox>
			</template>
		</template>
	</DropdownFilterType>
</template>

<script setup lang="ts">
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import CheckBox from '@lmt-rpb/CheckBox/CheckBox.vue';

import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { ClientType } from '@/interfaces/common';
import { useAirportOptions } from '../../composables/airportOptions';
import { useFilterArray } from '../../composables/filterArray';

type Props = {
	disabled?: boolean
	client?: ClientType
}
const props = withDefaults(defineProps<Props>(), { client: 'at' });

const store = useStore();
const { airportOptions } = useAirportOptions(props.client);

const {
	filterExpression,
	filteredItems,
	notFoundMessage,
} = useFilterArray(airportOptions, ['value', 'label']);

const selectedOptions = computed(() => store.state.searchMask?.departure ?? []);

const selectedOptionsProxy = ref(selectedOptions.value);
const applyChanges = () => {
	store.commit('searchMask/updateFormData', { departure: selectedOptionsProxy.value });
};
const cancel = () => {
	selectedOptionsProxy.value = store.state.searchMask?.departure ?? [];
};

const clearSelectedOptions = () => {
	selectedOptionsProxy.value = [];
};

const selectedOptionsConcat = computed(() => airportOptions.value
	.filter((airport) => selectedOptions.value.includes(airport.value))
	.map((airport) => airport.label)
	.join(', '));

watch(selectedOptions, () => {
	selectedOptionsProxy.value = selectedOptions.value;
});

</script>

<style lang="scss" scoped>
.airport-filter {
	:deep(.search-modal__main) {
		padding-inline: 0;
	}

	:deep(.dropdown__box) {
		min-width: 29.2rem;
	}

	:deep(.dropdown__field) {
		padding-left: 1.6rem;
	}

	:deep(.form-field__icon) {
		flex-shrink: 0;
	}

	:deep(.dropdown-filter-type__mobile-form-field) {
		margin: 0;
	}

	&__not-found {
		font-size: $font-small-1;
		padding-right: 1.6rem;
		padding-left: 5.6rem;
	}

	&__checkbox {
		margin-bottom: 1.6rem;
		margin-left: 1.6rem;
		display: block;
	}

	&__checkbox-label {
		padding: 0;
		margin: 0;
		color: $color-text;
		font-size: $font-small-1;
		line-height: 2.179rem;

		&--strong {
			font-weight: $font-weight-bold;
		}
	}
}

</style>
