<template>
	<div class="checkout-hotel-infos">
		<CheckoutHotelInfoGallery
			v-if="hotelInfo.Photos"
			:images="hotelInfo.Photos"
			:client="client"
			class="checkout-hotel-infos__gallery"
		/>
		<section class="checkout-hotel-infos__content">
			<ul class="checkout-hotel-infos__list">
				<li
					v-for="(value, detail) in details.basicInfo"
					:key="detail"
					class="checkout-hotel-infos__detail"
				>
					<span class="checkout-hotel-infos__detail-name">{{ detail }}</span>: {{ value }}
				</li>
			</ul>
			<div
				class="checkout-hotel-infos__description"
				v-html="details.description"
			></div>
			<p class="checkout-hotel-infos__copyright">
				&copy; GIATA GmbH 1996 - {{ new Date().getFullYear() }}
			</p>
			<slot></slot>
		</section>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { HotelCatalogueType } from '@/interfaces/hotel-catalogue-types';
import CheckoutHotelInfoGallery from '../CheckoutHotelInfoGallery/CheckoutHotelInfoGallery.vue';

const props = withDefaults(defineProps<{
	hotelInfo: HotelCatalogueType,
	client?: string
}>(), {
	client: 'at'
});

const details = computed(() => ({
	basicInfo: {
		Katalog: props.hotelInfo.CatalogName,
		Veranstalter: props.hotelInfo.TourOperatorName,
		Objekt: props.hotelInfo.HotelName,
		Ort: props.hotelInfo.CityName,
		Kategorie: props.hotelInfo.HotelCategory,
	},
	description: props.hotelInfo.Description,
}));

</script>

<style lang="scss" scoped>
.checkout-hotel-infos {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	&__gallery {
		margin-left: 0.8rem;
	}

	&__content {
		display: flex;
		flex-direction: column;
		font-size: $font-small-1;
		line-height: $line-height-text;
		margin-left: auto;
		margin-right: auto;
		overflow-wrap: break-word;
		width: 88%;

		@media (min-width: $breakpoint-verysmall) {
			width: 70%;
			align-items: unset;
		}

		@media (min-width: $breakpoint-small) {
			width: 60%;
		}
	}

	&__list {
		padding-left: 0;
		margin-bottom: 3rem;
		list-style: none;
	}

	&__detail {
		list-style: none;
	}

	&__detail-name {
		font-weight: $font-weight-semibold;
	}

	&__copyright {
		margin-top: 3rem;
	}
}

</style>
