import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex-filter" }
const _hoisted_2 = { class: "flex-filter__header" }
const _hoisted_3 = { class: "flex-filter__tooltip" }

import { computed } from 'vue';
import FilterRadioButton from '@lmt-rpb/FilterRadioButton/FilterRadioButton.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { useStore } from '@/components/common/store';
import flexItem from '@/js/data/flex-filter-types';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlexFilter',
  setup(__props) {

const store = useStore();

const flexFilters = computed({
	get() {
		return store.state.searchMask.flex as string;
	},
	set(value: string) {
		store.commit('searchMask/updateFormData', {
			flex: value,
		});
	},
});

function clearFlexFilters() {
	store.commit('searchMask/updateFormData', {
		flex: null,
	});
}

const hasSelectedFilter = computed(() => store.state.searchMask.flex !== null);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "flex-filter__title" }, " Flexibel buchen ", -1 /* HOISTED */)),
      (hasSelectedFilter.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "flex-filter__button",
            onClick: clearFlexFilters
          }, " Löschen "))
        : _createCommentVNode("v-if", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(flexItem), (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.Code,
        class: "flex-filter__container"
      }, [
        _createVNode(FilterRadioButton, {
          id: "flex-filter",
          modelValue: flexFilters.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((flexFilters).value = $event)),
          label: item.Name,
          value: item.Code
        }, {
          tooltip: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(InfoTooltip, {
                placement: "bottom",
                "modifier-class": "info-tooltip__body"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.Tooltip), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ])
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "label", "value"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})