import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled", "title"]

import { computed, PropType } from 'vue';
import type { ButtonType } from '@/interfaces/commonProps';


export default /*@__PURE__*/_defineComponent({
  __name: 'RoundedButton',
  props: {
	theme: {
		default: false,
		type: [String, Boolean],
		validator(value: string | boolean) {
			return ['outlined', 'extra', 'transparent-no-outline', 'no-outline', false, 'gradient', 'checkout-next'].includes(value);
		}
	},
	type: {
		type: String as PropType<ButtonType>,
		default: 'button'
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	titleValue: {
		type: String,
		default: ''
	},
},
  setup(__props) {

const props = __props;

const classes: Record<string, any> = computed(() => ({
	...(props.theme ? { [`button--${props.theme}`]: true } : {}),
	'is-disabled': props.disabled
}));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: ["button", classes.value],
    type: __props.type,
    disabled: __props.disabled,
    title: __props.titleValue
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default")
  ], 16 /* FULL_PROPS */, _hoisted_1))
}
}

})