<template>
	<div
		class="travel-duration-field"
		:class="{'travel': dropdownVisible, 'duration-error': maxTravelDurationError}"
		@click="handleDurationFieldClick"
	>
		<template
			v-if="isDesktop"
		>
			<DropdownField
				ref="dropdownComponent"
				v-model="durationLabel"
				class="travel-duration-field__dropdown"
				label="Dauer"
				:wide="true"
				:icon="showIcon && 'clock'"
				:with-cancel-button="selection.travelDuration[0] > totalDiffInDay"
				form-field-class="travel-duration-field__field"
				:max-travel-duration-error="maxTravelDurationError"
				@DropdownField:OutsideClick="onOk"
				@DropdownField:Ok="onOk"
				@DropdownField:Cancel="onCancel"
				@DropdownField:Clear="onCancel"
			>
				<div ref="travelDurationReference">
					<TravelDurationContent
						v-model="travelDurationModel"
						:offer-duration-diff="totalDiffInDay"
						:error-message="maxTravelDurationError ? maxDurationErrorMessage : ''"
						@TravelDurationContent:PillChanged="handleDurationError"
					/>
				</div>
			</DropdownField>
		</template>
		<template v-else>
			<FormField
				label="Dauer"
				class="travel-duration-field__field"
				:selected="durationLabel"
				:show-modal="showModal"
				:icon="showIcon && 'clock'"
				:show-toggle-icon="false"
				@click="showModal = !showModal"
				@FormField:Clear="onCancel"
			/>

			<Modal
				v-model="showModal"
				title="Reisedauer"
				class="travel-duration-modal"
				:error-message="maxTravelDurationError ? maxDurationErrorMessage : ''"
				:cancel-button-label="'Verwerfen'"
				:accept-button-label="'Übernehmen'"
				@Modal:Ok="onOk"
				@Modal:Cancel="onCancel"
				@Modal:Close="onCancel"
			>
				<div ref="travelDurationReference">
					<TravelDurationContent
						v-model="travelDurationModel"
						:offer-duration-diff="totalDiffInDay"
						:error-message="maxTravelDurationError ? maxDurationErrorMessage : ''"
						@TravelDurationContent:PillChanged="handleDurationError"
					/>
				</div>
			</Modal>
		</template>
	</div>
</template>

<script lang="ts" setup>
import {
	ref, computed, onMounted, onBeforeUnmount, watch,
} from 'vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import {
	dateDiff, pluralize, offsetDate,
} from '@utils/utils';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import TravelDurationContent from './TravelDurationContent.vue';

interface Props {
	showIcon?: boolean,
}

const maxDurationErrorMessage = 'Die gewählte Dauer kann nicht länger als 56 Tage sein. Bitte ändern Sie die Reisedaten.';

const store = useStore();

const dropdownComponent = ref<InstanceType<typeof DropdownField>>();

const showModal = ref(false);

const dropdownVisible = ref(false);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const travelDurationReference = ref<HTMLElement | null>(null);

const props = withDefaults(defineProps<Props>(), {
	showIcon: true,
});

const selection = ref({
	isExactSelected: false,
	travelDuration: [] as number[]
});

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	}
});

const offerDuration = computed({
	get() {
		const dur = store.state.searchMask.offerDuration;
		return {
			from: dur.from || 0,
			to: dur.to || 0
		};
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			offerDuration: value,
			offerDurationRelative: { relativeFrom: '', relativeTo: '' }
		});
	}
});

const totalDiffInDay = computed((): number => dateDiff(offerDuration.value.from, offerDuration.value.to));

const isExact = computed({
	get() {
		return store.state.searchMask.isTravelDurationExactSelected;
	},
	set(newValue: boolean) {
		store.commit('searchMask/updateFormData', { isTravelDurationExactSelected: newValue });
		selection.value.isExactSelected = newValue;
	}
});

const travelDuration = computed({
	get() {
		return store.state.searchMask.travelDuration || [];
	},
	set(newValue: number[]) {
		store.commit('searchMask/updateFormData', { travelDuration: newValue });
		selection.value.travelDuration = newValue;
	}
});

const travelDurationModel = computed({
	get() {
		return {
			exact: selection.value.isExactSelected,
			duration: selection.value.travelDuration
		};
	},
	set(newValue: {exact: boolean, duration: number[]}) {
		selection.value = {
			isExactSelected: newValue.exact,
			travelDuration: newValue.duration,
		};
	}
});

const getDaysDurationLabel = (duration: number[]) => {
	let result: string;
	if (duration.length === 1) {
		result = pluralize(Number(duration[0]), 'Tag', 'Tage');
	} else {
		result = `${duration.join('-')} Tage`;
	}
	return result;
};

const durationLabel = computed((): string => {
	let result = '';
	const duration = travelDurationModel.value.duration;
	const isBeliebig = duration.length === 0 || (duration[0] === 1 && duration[1] === 14);
	if (travelDurationModel.value.exact) {
		result = 'Exakt';
	} else if (!isBeliebig) {
		result = getDaysDurationLabel(duration);
	}
	return result;
});

const updateOfferDuration = ():void => {
	const offerFrom = offerDuration.value.from!;
	const min = travelDuration.value[0];
	if (totalDiffInDay.value < min) {
		const max = Math.max(...travelDuration.value);
		offerDuration.value = {
			from: offerDuration.value.from,
			to: offsetDate(offerFrom, max).getTime()
		};
	}
};

const closeDropdown = () => {
	if (isDesktop.value && dropdownComponent.value) {
		dropdownComponent.value.closeDropdown();
		dropdownVisible.value = false;
	}
};

const onOk = () => {
	if (maxTravelDurationError.value) { return; }
	isExact.value = selection.value.isExactSelected;
	travelDuration.value = selection.value.travelDuration;
	EventBus.$emit('TravelDuration:Changed');
	updateOfferDuration();
	store.commit('searchMask/updateFormData', { filter: '' } );
	closeDropdown();
};

// necessary for dropdown animation
const toggleDropdownVisiblity = () => {
	if (dropdownComponent.value) {
		setTimeout(() => {
			dropdownVisible.value = !dropdownVisible.value;
			if (!dropdownVisible.value) {
				onOk();
			}
		}, 150);
	}
};

const openModal = () => {
	const headerElement = document.querySelectorAll('.page-header')[0] as HTMLElement;
	if (headerElement) {
		headerElement.style.display = 'block';
	}
	setTimeout(() => { showModal.value = true; }, 100); // needs to wait for modal close otherwise its getting closed as well
};

const openModalOrDropdown = () => {
	if (!maxTravelDurationError.value) {
		if (dropdownComponent.value) {
			toggleDropdownVisiblity();
			dropdownComponent.value.open = true;
		} else {
			openModal();
		}
	}
};

const handleDurationFieldClick = (event: MouseEvent) => {
	if (maxTravelDurationError.value) { return; }
	const target = event.target as Node;
	const clickedDropdownOrModal = travelDurationReference.value && travelDurationReference.value.contains(target);
	if (!clickedDropdownOrModal) {
		toggleDropdownVisiblity();
	}
};

const onCancel = () => {
	selection.value = {
		travelDuration: travelDuration.value,
		isExactSelected: isExact.value,
	};
	maxTravelDurationError.value = travelDuration.value[0] > 56 && isExact.value;
	if (isDesktop.value && dropdownComponent.value) {
		dropdownComponent.value.closeDropdown();
		dropdownVisible.value = false;
	}
};

const handleDurationError = (pillValue: string, travelDurationDays: number) => {
	if (pillValue !== '') {
		maxTravelDurationError.value = pillValue === TRAVEL_DURATION_EXACT && travelDurationDays > 56;
	}
};

watch(travelDuration, (newValue) => {
	travelDuration.value = newValue;
});

watch(isExact, (newValue) => {
	isExact.value = newValue;
});

watch(isDesktop, () => {
	onCancel();
	toggleDropdownVisiblity();
});

onMounted(() => {
	EventBus.$on('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
	selection.value = {
		isExactSelected: isExact.value,
		travelDuration: travelDuration.value
	};
});

onBeforeUnmount(() => {
	EventBus.$off('OfferDurationField:OpenTravelDuration', openModalOrDropdown);
});

defineExpose({
	selection, durationLabel
});
</script>

<style lang="scss" scoped>
.travel-duration-content {
	height: 100vh;
}

:deep(.list .list__body) {
	overflow: initial;
}

:deep(.list .list__body) {
	overflow: initial;
}

:deep(.dropdown__box) {
	min-width: 45rem;
}

.travel-duration-field {
	:deep(.dropdown.is-open .dropdown__box) {
		width: 45rem;
		max-width: 45rem;
		overflow: hidden;
	}

	:deep(.form-field__icon) {
		@include visible-from($breakpoint-verysmall);
	}

	:deep(.form-field__icon) {
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1rem;
		fill: $color-primary;
	}

	&.duration-error .is-open {
		:deep(.travel-duration-field__field) {
			border-color: $color-warning;
		}

		:deep(.dropdown__inner) {
			border-color: $color-warning;
		}

		:deep(.form-field.travel-duration-field__field::after) {
			border-color: $color-warning;
		}
	}

	.travel-duration-field__dropdown {
		// Hacky Firefox fix for range slider not going fully right
		:deep(.list__body) {
			overflow: initial;
		}

		:deep(.dropdown__inner) {
			overflow: initial;
		}
	}

	:deep(.travel-duration-field__field) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.travel {
	:deep(.dropdown.is-open .dropdown__box) {
		overflow: inherit !important;
	}
}

.travel-duration-modal {
	.travel-duration-modal__header {
		@include max-width(65rem);

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 3rem 1.5rem;
	}
}
</style>
