<template>
	<ins class="rpb_price"><small class="rpb_price__from-text">ab</small> <span class="rpb_price__amount">{{ formattedPrice }}</span></ins>
</template>

<script setup lang="ts">
import { formatPrice } from '@utils/priceUtils';
import { computed } from 'vue';

interface Props {
	price: number;
	currency: string;
}

const props = withDefaults(defineProps<Props>(), {
	price: 998,
	currency: 'EUR'
});

const formattedPrice = computed((): string => formatPrice(props.price, props.currency));

</script>

<style lang="scss" scoped>
.rpb_price {
	font-weight: $font-weight-semibold;
	width: 100%;
	text-align: center;
	font-size: 3rem;
	line-height: 3.4rem;
	white-space: nowrap;
	text-decoration: none;

	&__from-text {
		font-size: $font-medium-3;
		font-weight: normal;
	}
}
</style>
