import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pill__label" }

import {
	computed,
	ref,
} from 'vue';

type PillSelectType = {
	label: string;
	value: string;
}
interface Props {
	modelValue?: string | string[],
	item?: PillSelectType,
	single?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PillSelect',
  props: {
    modelValue: { default: '' },
    item: { default: () => ({ label: '', value: '' }) },
    single: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const isFocused = ref(false);

const model = computed({
	get() {
		return props.modelValue || [];
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const isSelected = computed((): boolean => {
	if (props.single) {
		return model.value === props.item.value;
	}
	return model.value.includes(props.item.value);
});

const onChange = (): void => {
	if (props.modelValue === props.item.value) { return; }

	if (props.single) {
		model.value = (!isSelected.value && props.item.value) || '';
		return;
	}

	if (isSelected.value) {
		model.value = (model.value as string[]).filter((value: string | number) => value !== props.item.value);
	} else {
		(model.value as string[]).push(props.item.value);
	}
};
__expose({
	onChange,
	model,
	isSelected
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["pill", {'is-selected': isSelected.value, 'is-focued': isFocused.value}])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.item.label), 1 /* TEXT */),
    _createElementVNode("input", {
      type: "checkbox",
      class: "pill__input",
      onChange: onChange,
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (isFocused.value = true)),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (isFocused.value = false))
    }, null, 32 /* NEED_HYDRATION */)
  ], 2 /* CLASS */))
}
}

})