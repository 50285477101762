import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rpb_price" }
const _hoisted_2 = { class: "rpb_price__amount" }

import { formatPrice } from '@utils/priceUtils';
import { computed } from 'vue';

interface Props {
	price: number;
	currency: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Price',
  props: {
    price: { default: 998 },
    currency: { default: 'EUR' }
  },
  setup(__props: any) {

const props = __props;

const formattedPrice = computed((): string => formatPrice(props.price, props.currency));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ins", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("small", { class: "rpb_price__from-text" }, "ab", -1 /* HOISTED */)),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("span", _hoisted_2, _toDisplayString(formattedPrice.value), 1 /* TEXT */)
  ]))
}
}

})