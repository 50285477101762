<template>
	<SearchModal
		ref="searchModal"
		:header="props.header"
		:apply-button="props.applyButton"
		:cancel-button="props.cancelButton"
		@SearchModal:applyChanges="handleApply"
		@SearchModal:close="handleClose"
	>
		<FilterMobile
			:list-type="listType"
			:destination-label="destinationLabel"
		>
		</FilterMobile>
	</SearchModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import FilterMobile from '@lmt-rpb/FilterMobile/FilterMobile.vue';
import { useStore } from '@/components/common/store';
import { EventBus } from '@global-js/event-bus';

type Props = {
	listType: 'hotel' | 'region' | 'offer'
	destinationLabel?: string
	header?: string,
	applyButton?: string,
	cancelButton?: string,
}

const searchModal = ref<typeof SearchModal | null>(null);
const store = useStore();

const props = withDefaults(defineProps<Props>(), {
	destinationLabel: '',
	header: 'Filter',
	applyButton: 'Apply',
	cancelButton: 'Cancel',
});

const openModal = () => {
	store.dispatch('searchMask/saveCurrentState');
	window.history.replaceState(window.history.state, '', window.location.href.split('#')[0]);
	searchModal.value?.openModal();
};

const handleApply = () => {
	EventBus.$emit('FilterModal:submit');
};

const handleClose = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
};

defineExpose({ openModal });
</script>
