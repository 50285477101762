import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock } from "vue"

import {
	onMounted, ref, onBeforeMount,
	computed, watch,
	nextTick,
} from 'vue';
import { debounce } from '@utils/utils';
import { isClientGermany } from '@utils/environmentUtils';
import * as hotellistService from '@services/bestOfferForAreaService';
import MapOfferButton from '@lmt-rpb/MapOfferButton/MapOfferButton.vue';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { ApiDataTypes } from '@/components/common/types';
import { useStore } from '@/components/common/store';
import importMapsLibary from './importMapsLibraryScript';

interface Props {
	id: string,
	lat: number,
	lng: number,
	northwest: {lat: number, lng: number} | null,
	southeast: {lat: number, lng: number} | null,
	hotelRequestBody: ApiDataTypes
	hotelToShowIffCode?: number
	hotelToShow?: ItemType | null
	isHotelPage?: boolean
}

interface Coordinate {
	Latitude: number;
	Longitude: number;
}

interface Area {
	LowerLeft: Coordinate;
	UpperRight: Coordinate;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapWithOffers',
  props: {
    id: {},
    lat: {},
    lng: {},
    northwest: {},
    southeast: {},
    hotelRequestBody: {},
    hotelToShowIffCode: { default: undefined },
    hotelToShow: { default: undefined },
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any, { expose: __expose }) {


const props = __props;

const offers = ref<ItemType[]>([]);

const markerComponents = ref<InstanceType<typeof MapOfferButton>[]>();

const mapDiv = ref<HTMLDivElement>();

const mapImported = ref(false);

let map:google.maps.Map;

const showSuns = isClientGermany();

function importGoogleMaps() {
	if (!mapImported.value) {
		mapImported.value = true;
		importMapsLibary({ key: process.env.googleMapsKey, v: 'weekly' });
	}
}

async function initMap() {
	await google.maps.importLibrary('maps');
	await google.maps.importLibrary('marker');

	return new google.maps.Map(mapDiv.value!, {
		zoom: 7,
		mapId: props.id,
		gestureHandling: 'greedy'
	});
}

async function handleMapResize() {
	closeAllInfoWindow();
	offers.value = [];
}

const store = useStore();
const isMobile = computed(() => store.state.config.isMobile);

watch(isMobile, handleMapResize);

function setArea() {
	if (props.northwest && props.southeast) {
		const bounds = new google.maps.LatLngBounds();
		bounds.extend(props.northwest);
		bounds.extend(props.southeast);
		map.fitBounds(bounds);
	}
}

function addGoogleMapsListener() {
	google.maps.event.addListener(
		map,
		'bounds_changed',
		debounce(async() => addNewMarker(), 400)
	);
	google.maps.event.addListener(map, 'click', () => {
		closeAllInfoWindow();
	});
}

async function addNewMarker() {
	try {
		const area = getGeoLocationArea();
		const response = await hotellistService.getByGeoLocation(props.hotelRequestBody, area);
		const newOffers = response?.Offers?.filter((newOffer) => !offers.value.find((savedOffers) => savedOffers.Hotel.IffCode === newOffer.Hotel.IffCode)) || [];
		offers.value.push(...newOffers);
	} catch (e) {
		if (e instanceof Error) {
			console.error(e.message);
		}
	}
}

function getGeoLocationArea() {
	const bounds = map.getBounds();
	let area: Area | null = null;
	if (bounds) {
		const northeast = bounds.getNorthEast();
		const southwest = bounds.getSouthWest();
		area = {
			UpperRight: { Longitude: northeast.lng(), Latitude: northeast.lat() },
			LowerLeft: { Longitude: southwest.lng(), Latitude: southwest.lat() },
		};
	}
	return area;
}

function closeAllInfoWindow(notCloseId?: number) {
	markerComponents.value?.forEach((m) => {
		if (m.props.item.Hotel.IffCode !== notCloseId) {
			m.buttonClicked = false;
			m.setActive(false);
		}
	});
}

async function openDefaultInfoWindow() {
	if (!props.hotelToShowIffCode && !props.hotelToShow) return;
	closeAllInfoWindow(props.hotelToShowIffCode);
	offers.value = props.hotelToShow ? [props.hotelToShow] : [];
	await addNewMarker();
	const defaultMarker = markerComponents.value?.find((marker) => marker.props.item.Hotel.IffCode === props.hotelToShowIffCode);
	if (defaultMarker) {
		defaultMarker.setActive(true);
		defaultMarker.buttonClicked = true;
	}
}

onBeforeMount(() => {
	importGoogleMaps();
});

onMounted(async() => {
	map = await initMap();
	setArea();
	if (props.hotelToShow) {
		offers.value = [props.hotelToShow];
	}
	addGoogleMapsListener();
	await nextTick();
});

__expose({
	openDefaultInfoWindow
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "mapDiv",
      ref: mapDiv,
      class: "map-with-offers"
    }, null, 512 /* NEED_PATCH */),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offers.value, (o, index) => {
      return (_openBlock(), _createBlock(MapOfferButton, {
        key: o.Hotel.IffCode,
        ref_for: true,
        ref_key: "markerComponents",
        ref: markerComponents,
        "show-suns": _unref(showSuns),
        item: o,
        index: index,
        map: _unref(map)!,
        "default-open": o.Hotel.IffCode === props.hotelToShowIffCode,
        "should-navigate": o.Hotel.IffCode !== props.hotelToShowIffCode,
        "is-hotel-page": _ctx.isHotelPage,
        "onMapOfferButton:Click": ($event: any) => (closeAllInfoWindow(o.Hotel.IffCode))
      }, null, 8 /* PROPS */, ["show-suns", "item", "index", "map", "default-open", "should-navigate", "is-hotel-page", "onMapOfferButton:Click"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}
}

})