import { isLmatClient, isRpbClient, isTbdeClient } from "@/js/utils/environmentUtils";

export const dealsFilterClientTitle = () => {
	if (isRpbClient()) {
		return 'Restplätze';
	}
	if (isLmatClient() || isTbdeClient()) {
		return 'Deals';
	}
	return 'Schnäppchen';
};
