<template>
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="#000000"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Beach</title>
		<path
			d="M12.0243 9.27083L9.9375 8.51042L7.43056 15.3889H1.11111C0.496528 15.3889 0 15.8854 0 16.5C0 17.1146 0.496528 17.6111 1.11111 17.6111H18.8889C19.5035 17.6111 20 17.1146 20 16.5C20 15.8854 19.5035 15.3889 18.8889 15.3889H9.79514L12.0208 9.27083H12.0243ZM16.2292 9.26389L16.1146 9.57986L18.4653 10.434C19.0938 10.6632 19.7847 10.2882 19.8403 9.62153C20.066 6.89583 19.0104 4.22222 17.0347 2.38194C17.1042 2.65972 17.1458 2.94792 17.1528 3.24306L17.1597 3.45139C17.2222 5.43055 16.9062 7.40278 16.2292 9.26389ZM16.0417 3.27431C16.0035 2.07986 15.2604 1.02431 14.1528 0.586806C14.1215 0.572917 14.0868 0.5625 14.0556 0.548611C12.9097 0.142361 11.6319 0.465278 10.8229 1.375L10.684 1.53125C9.45833 2.89931 8.50694 4.49306 7.875 6.22222L7.76042 6.53819L15.0694 9.19792L15.184 8.88194C15.8125 7.15278 16.1076 5.32292 16.0486 3.48264L16.0417 3.27431ZM3.72222 3.75347C3.33681 4.29861 3.625 5.03125 4.25347 5.26042L6.71875 6.15625L6.83333 5.84028C7.51042 3.97917 8.53819 2.26389 9.85764 0.788194L9.99653 0.631944C10.2118 0.392361 10.4514 0.180556 10.7083 0C7.94444 0.0868056 5.33681 1.46528 3.72222 3.75V3.75347Z"
			fill="#333333"
		/>
	</svg>
</template>
