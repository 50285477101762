<template>
	<svg
		width="19"
		height="24"
		viewBox="0 0 19 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Location Symbol</title>
		<path
			fill="#000000"
			d="M10.1372 23.4607C12.5481 20.4435 18.0467 13.1309 18.0467 9.02335C18.0467 4.04171 14.005 0 9.02335 0C4.04171 0 0 4.04171 0 9.02335C0 13.1309 5.49861 20.4435 7.90953 23.4607C8.48759 24.1798 9.55911 24.1798 10.1372 23.4607ZM9.02335 6.01557C9.82107 6.01557 10.5861 6.33246 11.1502 6.89653C11.7142 7.4606 12.0311 8.22564 12.0311 9.02335C12.0311 9.82106 11.7142 10.5861 11.1502 11.1502C10.5861 11.7142 9.82107 12.0311 9.02335 12.0311C8.22564 12.0311 7.4606 11.7142 6.89653 11.1502C6.33246 10.5861 6.01557 9.82106 6.01557 9.02335C6.01557 8.22564 6.33246 7.4606 6.89653 6.89653C7.4606 6.33246 8.22564 6.01557 9.02335 6.01557Z"
		/>
	</svg>
</template>
