import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "checkout-infos" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "checkout-infos__operator-hotel-wrapper" }
const _hoisted_4 = { class: "checkout-infos__hotel" }
const _hoisted_5 = { class: "checkout-infos__hotel-name" }
const _hoisted_6 = {
  key: 0,
  class: "checkout-infos__hotel-address"
}
const _hoisted_7 = { class: "checkout-infos__operator" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "checkout-infos__operator" }
const _hoisted_10 = {
  key: 0,
  class: "checkout-infos__info-bubble"
}
const _hoisted_11 = { class: "checkout-infos__info-text" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ref, computed } from 'vue';
import Rating from '@/components/common/widgets/rating.vue';
import { TourOperatorComplete } from '@/interfaces/checkout/shopping-cart';
import { getFluxImageUrl } from '@/js/utils/utils';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	iffCode: number,
	hotelName: string,
	hotelCategory: number,
	tourOperator: TourOperatorComplete,
	hotelAddress?: string,
	client?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutInfos',
  props: {
    iffCode: {},
    hotelName: {},
    hotelCategory: {},
    tourOperator: {},
    hotelAddress: { default: '' },
    client: { default: 'at' }
  },
  setup(__props: any) {

const props = __props;

const showInfoText = ref(false);

const imageUrl = computed((): string => {
	let url = getFluxImageUrl();

	if (!url) {
		url = 'https://images.flux.reisen';
	}

	return url;
});

const mediumImage = computed((): string => `${imageUrl.value}/hotel/${props.iffCode}/1/m`);
const showSuns = isClientGermany();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: mediumImage.value,
      class: "checkout-infos__image",
      alt: _ctx.hotelName
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.hotelName) + " ", 1 /* TEXT */),
          _createVNode(Rating, {
            class: "hotel-name__rating",
            rate: _ctx.hotelCategory,
            suns: _unref(showSuns)
          }, null, 8 /* PROPS */, ["rate", "suns"])
        ]),
        (_ctx.hotelAddress)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.hotelAddress), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          class: "checkout-infos__operator-image",
          src: _ctx.tourOperator.Logo,
          alt: _ctx.tourOperator.Name + ' Logo'
        }, null, 8 /* PROPS */, _hoisted_8),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_Transition, { name: "info-text" }, {
            default: _withCtx(() => [
              (showInfoText.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("ul", _hoisted_11, [
                      _createElementVNode("li", null, _toDisplayString(_ctx.tourOperator.LegalEntity.Name), 1 /* TEXT */),
                      _createElementVNode("li", null, _toDisplayString(_ctx.tourOperator.LegalEntity.Address), 1 /* TEXT */),
                      _createElementVNode("li", null, _toDisplayString(_ctx.tourOperator.LegalEntity.CountryCode) + " - " + _toDisplayString(_ctx.tourOperator.LegalEntity.PostalCode) + " " + _toDisplayString(_ctx.tourOperator.LegalEntity.City), 1 /* TEXT */),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tourOperator.LegalEntity.Contacts, (item) => {
                        return (_openBlock(), _createElementBlock("li", { key: item }, _toDisplayString(item), 1 /* TEXT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createTextVNode(" Veranstalter: " + _toDisplayString(_ctx.tourOperator.Name) + " ", 1 /* TEXT */),
          _createVNode(BaseIcon, {
            name: "infoFill",
            class: "checkout-infos__info",
            onKeypress: _cache[0] || (_cache[0] = _withKeys(($event: any) => (showInfoText.value = !showInfoText.value), ["enter"])),
            onMouseover: _cache[1] || (_cache[1] = ($event: any) => (showInfoText.value = true)),
            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (showInfoText.value = false))
          })
        ])
      ])
    ])
  ]))
}
}

})