import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { icons } from './svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseIcon',
  props: {
	name: {
		type: String,
		required: true,
		validator(value: string) {
			return Object.prototype.hasOwnProperty.call(icons, value);
		}
	}
},
  setup(__props) {

/* eslint-disable global-require */
const props = __props;

const iconComponent = computed(() => icons[props.name]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(iconComponent.value), null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})