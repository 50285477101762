import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'DealsFilter',
  setup(__props) {

const store = useStore();

const dealFilter = computed({
	get() {
		return Boolean(store.state.searchMask.deal);
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', {
			deal: value,
		});
	},
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Checkbox, {
    modelValue: dealFilter.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dealFilter).value = $event)),
    class: "deals-only-filter",
    label: "Angebote mit Preissenkung"
  }, null, 8 /* PROPS */, ["modelValue"]))
}
}

})