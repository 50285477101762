import { ApiDataTypes } from '@components/common/types/index';
import { DiscountHotelPriceOffer } from '@interfaces/neusta-discount-price';
import { ItemType } from '@interfaces/hotel-list-types/hotel-list-types';
import request from './request';
import { BestOfferForHotelList, BestPackageOfferForHotelList, BestPackageOfferForHotelListResponse } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { HotelTransformService } from '../../HotelGrid/hotelTransformService';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import * as NeustaService from './neustaService';

export const getUniqueKey = ({ offerId, iffCode }: { offerId: string, iffCode: number }) => `${offerId}_${iffCode}`;

function findDealForHotel(deals: Map<string, DiscountHotelPriceOffer>, iffCode: number): DiscountHotelPriceOffer | undefined {
	return Array.from(deals.values()).find((deal) => deal.iffCode === iffCode);
}

export function mergeOffersWithDeals(offers: HotelBoxData[], deals: Map<string, DiscountHotelPriceOffer>): HotelBoxData[] {
	return offers.map((offer) => {
		const deal = findDealForHotel(deals, offer.HotelIffCode);
		if (deal) {
			const discountPercent = deal.relativeDiscountPercent?.toAvgPrice || 0;

			if (discountPercent >= 5) {
				return {
					...offer,
					DealFactor: discountPercent,
					Discount: discountPercent,
					PriceAverage: deal.referencePrice?.avgPrice,
					Price: deal.currentPrice
				};
			}
		}
		return offer;
	});
}

export async function getHotelListOffer(apiUrl: string, bodyParams: ApiDataTypes, uniqueKey: string): Promise<BestPackageOfferForHotelListResponse> {
	const response = await request<BestPackageOfferForHotelListResponse>(
		{ method: 'post', url: apiUrl, data: bodyParams },
		uniqueKey
	);

	if (!response || Object.keys(response).length === 0) {
		throw new Error('No response or empty response');
	}

	return response;
}

export async function getNeusta(rawOffers: BestOfferForHotelList[], offersData: HotelBoxData[]): Promise<HotelBoxData[]> {
	const neustaDeals = await NeustaService.getAsMap(rawOffers as unknown as ItemType[]);
	return mergeOffersWithDeals(offersData, neustaDeals);
}


export async function transformOffersToHotelBoxData(response: BestPackageOfferForHotelListResponse, bodyParams: ApiDataTypes, hotelOnly: boolean): Promise<HotelBoxData[]> {
	const transformService = new HotelTransformService();
	return transformService.transformList(response.Offers, bodyParams, hotelOnly, false)
}