import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["modules", "navigation"]
const _hoisted_2 = ["alt"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]

import {
 computed, onActivated, onMounted, ref,
} from 'vue';
import { getFluxImageUrl } from '@utils/utils';
import { register } from 'swiper/element/bundle';
import { Navigation } from 'swiper/modules';
import ImageWithPlaceholder from '@lmt-rpb/ImageWithPlaceholder/ImageWithPlaceholder.vue';
import { v4 as uuidv4 } from 'uuid';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	iffCode: number,
	alt: string,
	numberOfImages: number;
	itemIndex?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelImageSlider',
  props: {
    iffCode: {},
    alt: {},
    numberOfImages: {},
    itemIndex: { default: -1 }
  },
  setup(__props: any) {

register();

const props = __props;

const imageUrl = getFluxImageUrl() || 'https://images.flux.reisen';

const images = computed(() => {
	const imageArray = [];

	const maxAmountofPictures = props.numberOfImages;

	for (let i = 0; i < maxAmountofPictures; i++) {
		imageArray.push(`${imageUrl}/hotel/${props.iffCode}/${i + 1}`);
	}
	return imageArray;
});

const uniqueId = CSS.escape('id-' + uuidv4());
const swiperRef = ref(null);

onActivated(() => {
	if (swiperRef.value) {
		const swiperEl = swiperRef.value.querySelector('swiper-container');
		if (swiperEl) {
			swiperEl.initialize();
		}
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "swiperRef",
    ref: swiperRef,
    class: "hotel-image-slider"
  }, [
    _createElementVNode("swiper-container", {
      modules: [_unref(Navigation)],
      navigation: {
				nextEl: `#${_unref(uniqueId)}-next`,
				prevEl: `#${_unref(uniqueId)}-prev`
			},
      class: "hotel-image-slider__container-image"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (item, index) => {
        return (_openBlock(), _createElementBlock("swiper-slide", {
          key: index,
          alt: _ctx.alt
        }, [
          (index === 0 && _ctx.itemIndex === 0)
            ? (_openBlock(), _createBlock(ImageWithPlaceholder, {
                key: 0,
                class: "hotel-image-slider__image",
                src: item + '/m',
                width: "282",
                height: "188"
              }, null, 8 /* PROPS */, ["src"]))
            : (_openBlock(), _createBlock(ImageWithPlaceholder, {
                key: 1,
                class: "hotel-image-slider__image rpb_lazy",
                src: item + '/m',
                width: "282",
                height: "188",
                loading: "lazy"
              }, null, 8 /* PROPS */, ["src"]))
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ], 8 /* PROPS */, _hoisted_1),
    _createElementVNode("div", {
      id: `${_unref(uniqueId)}-prev`,
      class: "hotel-image-slider__custom-prev-button"
    }, [
      _createElementVNode("span", null, [
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "hotel-image-slider__chevron-icon"
        })
      ])
    ], 8 /* PROPS */, _hoisted_3),
    _createElementVNode("div", {
      id: `${_unref(uniqueId)}-next`,
      class: "hotel-image-slider__custom-next-button"
    }, [
      _createElementVNode("span", null, [
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "hotel-image-slider__chevron-icon"
        })
      ])
    ], 8 /* PROPS */, _hoisted_4)
  ], 512 /* NEED_PATCH */))
}
}

})