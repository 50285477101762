import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-expanded", "onKeydown"]
const _hoisted_2 = { class: "dropdown__inner" }
const _hoisted_3 = {
  class: "dropdown__close-icon",
  viewBox: "0 0 27 32"
}
const _hoisted_4 = { class: "dropdown__container" }
const _hoisted_5 = {
  key: 2,
  class: "dropdown__footer"
}
const _hoisted_6 = { class: "dropdown__cancel" }
const _hoisted_7 = { class: "dropdown__ok" }

import FormField from '@lmt-rpb/FormField/FormField.vue';
import DropdownClearButton from '@lmt-rpb/DropdownClearButton/DropdownClearButton.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	modelValue?: string,
	label?: string,
	icon?: string,
	readonly?: boolean,
	wide?: boolean,
	manual?: boolean,
	closeOnEsc?: boolean,
	showFooter?: boolean,
	formFieldClass?: string,
	board?: boolean,
	placeholder?: string,
	allowClear?: boolean,
	showToggleIcon?: boolean,
	showCloseIcon?: boolean,
	disabled?: boolean,
	buttonDisabled?: boolean,
	preventMousedown?: boolean,
	buttonWording?: string,
	buttonCancel?: string,
	withCancelButton?: boolean,
	withClearButton?: boolean,
	maxTravelDurationError?: boolean,
	isFocused?: boolean,
	customClearIcon?: string,
	searchable?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownField',
  props: {
    modelValue: { default: '' },
    label: { default: '' },
    icon: { default: '' },
    readonly: { type: Boolean, default: true },
    wide: { type: Boolean, default: false },
    manual: { type: Boolean, default: undefined },
    closeOnEsc: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    formFieldClass: { default: '' },
    board: { type: Boolean, default: false },
    placeholder: { default: 'Beliebig' },
    allowClear: { type: Boolean, default: false },
    showToggleIcon: { type: Boolean, default: true },
    showCloseIcon: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    buttonDisabled: { type: Boolean },
    preventMousedown: { type: Boolean, default: true },
    buttonWording: { default: 'Übernehmen' },
    buttonCancel: { default: 'Verwerfen' },
    withCancelButton: { type: Boolean, default: false },
    withClearButton: { type: Boolean, default: false },
    maxTravelDurationError: { type: Boolean, default: false },
    isFocused: { type: Boolean, default: false },
    customClearIcon: { default: undefined },
    searchable: { type: Boolean }
  },
  emits: [
	'update:modelValue',
	'DropdownField:Navigate',
	'DropdownField:FieldClick',
	'DropdownField:OutsideClick',
	'DropdownField:Toggle',
	'DropdownField:Focus',
	'DropdownField:Cancel',
	'DropdownField:Ok',
	'DropdownField:InputFocus',
	'DropdownField:Blur',
	'DropdownField:Clear',
	'DropdownField:open',
],
  setup(__props: any, { expose: __expose, emit: __emit }) {


const store = useStore();

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: string) {
		if (typeof newValue === 'string') {
			emit('update:modelValue', newValue);
		}
	}
});

const root = ref();

const el = ref<HTMLElement | null>(null);

const open = ref<boolean>(props.manual || false);

const contentRender = ref<boolean>(props.manual || false);

const box = ref<HTMLElement | null>(null);

const field = ref<InstanceType<typeof FormField> | null>(null);

const dropdownClasses = computed((): string[] => {
	const state = open.value ? 'is-open' : '';
	const theme = props.wide ? 'wide' : 'narrow';
	const footer = !props.showFooter ? 'is-without-footer' : '';

	return [state, `dropdown--${theme}`, footer];
});

const isHeaderSearch = computed((): boolean => root.value?.type.name === 'SearchForm-Header' || root.value?.type.name === 'Solr-Search');

const pageType = computed((): string => store.state.config.pageType);

const calendarDateChanged = computed((): boolean => store.state.calendarDateChanged);

const onKeydown = (e: KeyboardEvent): void => {
	if (open.value && [38, 40].indexOf(e.which) !== -1 && !props.maxTravelDurationError) {
		e.preventDefault();
		emit('DropdownField:Navigate', e.which === 38 ? -1 : 1);
	}
};

const checkTarget = (e: MouseEvent) => {
	if ((e.target as Element).parentElement && ((e.target as Element).parentElement as Element).classList.contains('destination-field')) {
		return false;
	}

	if (open.value && e.target && !el.value?.contains((e.target as Node))) {
		emit('DropdownField:OutsideClick');
	}

	if (open.value && props.searchable) {
		const inputEl = el.value?.querySelector('input');
		if (inputEl) {
			inputEl.focus();
		}
	}
};

const onOpen = (parent: string) => {
	if (parent === 'autocomplete') {
		document.addEventListener('click', checkTarget);
	}
};

const openDropdown = (): void => {
	if (props.disabled || props.maxTravelDurationError) {
		return;
	}
	open.value = true;
	emit('DropdownField:open');
};

const closeDropdown = (): void => {
	open.value = false;
};

const toggleDropdownEsc = (): void => {
	emit('DropdownField:OutsideClick');
};

const onFieldClick = (e: MouseEvent): void => {
	if (!isHeaderSearch.value && pageType.value === 'themePage' && window.innerWidth > 1440 && !props.maxTravelDurationError) {
		EventBus.$emit('OfferSearch:ScrollUpSearchForm');
	}

	if (!e || !e.target) {
		openDropdown();
		return;
	}

	const target = e.target as Node;
	const clickOnContainer = box.value && box.value.contains(target);

	const clickOnField = el.value?.contains(target) && !clickOnContainer;

	if (props.manual !== undefined && !calendarDateChanged.value) {
		emit('DropdownField:FieldClick', clickOnField, clickOnContainer);
		return;
	}

	// If calendar is open and date changes, prevent toggle dropdown on field click
	if (calendarDateChanged.value) {
		if ((e.target as Element).classList.contains('offer-duration__field')) {
			emit('DropdownField:OutsideClick');
		}
		return;
	}

	if (clickOnField && !open.value && !props.maxTravelDurationError) {
		openDropdown();
	} else if (clickOnField && open.value && !props.maxTravelDurationError) {
		if (props.searchable) {
			return;
		}
		closeDropdown();
	}
};

const toggleDropdown = (e?: MouseEvent): void => {
	if (!open.value && e) {
		onFieldClick(e);
	} else {
		open.value = !open.value;
	}
};

const onEnter = (): void => {
	if (props.manual === undefined) {
		toggleDropdown();
	}
};

watch(() => props.manual, () => {
	open.value = props.manual;
});

watch(() => open.value, () => {
	const inputEl = el.value?.querySelector('input');

	// remove listener if any
	document.removeEventListener('click', checkTarget);
	EventBus.$off('keydown.escape', toggleDropdownEsc);

	if (open.value) {
		if (inputEl) {
			inputEl.focus();
		}

		document.addEventListener('click', checkTarget);

		if (props.closeOnEsc) {
			EventBus.$once('keydown.escape', toggleDropdownEsc); // Why does this only work with eventBus?
		}
	}
	emit('DropdownField:Toggle', open.value);
});

onBeforeMount(() => {
	watch(() => open.value, () => {
		const unwatch = () => {
			contentRender.value = true;
		};
		unwatch();
	});
});

onMounted(() => {
	document.addEventListener('keydown', onKeydown);
	root.value = getCurrentInstance()?.root;
	el.value = getCurrentInstance()?.proxy.$el as HTMLElement;
});

onBeforeUnmount(() => {
	document.removeEventListener('keydown', onKeydown);
});

__expose({
	closeDropdown,
	toggleDropdown,
	onOpen,
	open,
	contentRender,
	field
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", dropdownClasses.value]),
    "aria-expanded": `${open.value}`,
    onClick: onFieldClick,
    onKeydown: _withKeys(_withModifiers(onEnter, ["prevent"]), ["enter"])
  }, [
    _createVNode(FormField, {
      ref_key: "field",
      ref: field,
      selected: model.value,
      "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      tabindex: "0",
      class: _normalizeClass(["dropdown__field", _ctx.formFieldClass]),
      label: _ctx.label,
      readonly: _ctx.readonly,
      board: _ctx.board,
      icon: _ctx.icon,
      open: open.value,
      "custom-clear-icon": _ctx.customClearIcon,
      placeholder: _ctx.placeholder,
      "allow-clear": _ctx.allowClear,
      "show-toggle-icon": _ctx.showToggleIcon,
      "prevent-mousedown": _ctx.preventMousedown,
      disabled: _ctx.maxTravelDurationError,
      "onFormField:Focus": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('DropdownField:Focus', $event))),
      "onFormField:Blur": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('DropdownField:Blur', $event))),
      "onFormField:Clear": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('DropdownField:Clear', $event))),
      onInputFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('DropdownField:InputFocus', $event))),
      onToggle: toggleDropdown
    }, null, 8 /* PROPS */, ["selected", "class", "label", "readonly", "board", "icon", "open", "custom-clear-icon", "placeholder", "allow-clear", "show-toggle-icon", "prevent-mousedown", "disabled"]),
    _createElementVNode("div", {
      ref_key: "box",
      ref: box,
      class: _normalizeClass(["dropdown__box", {'input-focused': _ctx.isFocused}])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showFooter && _ctx.showCloseIcon)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "dropdown__close",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('DropdownField:Cancel')))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                _createElementVNode("title", null, _toDisplayString(_unref(getLocaleString)('close')), 1 /* TEXT */),
                _cache[12] || (_cache[12] = _createElementVNode("path", { d: "M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" }, null, -1 /* HOISTED */))
              ]))
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          (contentRender.value)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 })
            : _createCommentVNode("v-if", true)
        ]),
        (_ctx.withClearButton)
          ? (_openBlock(), _createBlock(DropdownClearButton, {
              key: 1,
              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$emit('DropdownField:Clear')), ["stop"])),
              onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('DropdownField:Clear')), ["stop"]), ["enter"]))
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.showFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.withCancelButton)
                ? (_openBlock(), _createBlock(RoundedButton, {
                    key: 0,
                    ref: "ok",
                    "aria-label": "Verwerfen",
                    class: "dropdown__cancel",
                    onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.$emit('DropdownField:Clear')), ["stop"])),
                    onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('DropdownField:Clear')), ["stop"]), ["enter"]))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.buttonCancel), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 512 /* NEED_PATCH */))
                : _createCommentVNode("v-if", true),
              _createVNode(RoundedButton, {
                ref: "ok",
                "aria-label": "Auswahl wählen",
                disabled: _ctx.maxTravelDurationError || _ctx.buttonDisabled,
                onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.$emit('DropdownField:Ok')), ["stop"])),
                onKeydown: _cache[11] || (_cache[11] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('DropdownField:Ok')), ["stop"]), ["enter"]))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.buttonWording), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */)
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}
}

})