import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal modal-mask" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = {
  key: 0,
  class: "modal-footer"
}

import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	title?: string,
	buttonText?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SmallModal',
  props: {
    title: {},
    buttonText: {}
  },
  emits: ['SmallModal:Close'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const onCloseClick = () => emit('SmallModal:Close');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "modal-close",
            onClick: onCloseClick
          }, [
            _createVNode(BaseIcon, {
              name: "close",
              class: "modal-close__icon"
            })
          ]),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode(" @slot the main text of the body "),
            _renderSlot(_ctx.$slots, "body")
          ]),
          (_ctx.buttonText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(RoundedButton, {
                  class: "modal-footer__button modal-footer__button-left",
                  theme: "extra",
                  onClick: onCloseClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})