<template>
	<div class="payment-radio-button-group">
		<PaymentRadioButton
			v-for="{label, value} in options"
			:key="value"
			:label="label"
			:value="value"
			:selected="modelValue === value"
			@PaymentRadio:Change="onPaymentRadioChange"
		/>
	</div>
</template>

<script lang="ts" setup>
import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';
import PaymentRadioButton from '@lmt-rpb/PaymentRadioButton/PaymentRadioButton.vue';

defineProps<{
	options: RadioButtonCommonProps[],
	modelValue?: string
}>();

const emit = defineEmits(['update:modelValue']);

const onPaymentRadioChange = (value: string | undefined) => {
	emit('update:modelValue', value);
};

</script>

<style lang="scss" scoped>
.payment-radio-button-group {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-width: 90%;

	@media (min-width: $breakpoint-verysmall) {
		display: grid;
		justify-content: center;
		max-width: 100%;
		grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
		gap: 1.6rem;
	}
}
</style>
