import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "payment-radio-button-group" }

import type { RadioButtonCommonProps } from '@/interfaces/components/inputs';
import PaymentRadioButton from '@lmt-rpb/PaymentRadioButton/PaymentRadioButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentRadioButtonGroup',
  props: {
    options: {},
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const onPaymentRadioChange = (value: string | undefined) => {
	emit('update:modelValue', value);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({label, value}) => {
      return (_openBlock(), _createBlock(PaymentRadioButton, {
        key: value,
        label: label,
        value: value,
        selected: _ctx.modelValue === value,
        "onPaymentRadio:Change": onPaymentRadioChange
      }, null, 8 /* PROPS */, ["label", "value", "selected"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})