import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled", "aria-checked", "aria-label", "value"]
const _hoisted_2 = { class: "checkbox__label" }

import { computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	disabled?: boolean,
	label?: string,
	modelValue: boolean | T[],
	customValue?: T,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckBox',
  props: {
    disabled: { type: Boolean, default: false },
    label: { default: '' },
    modelValue: { type: [Boolean, Array], default: false },
    customValue: { default: undefined }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const isChecked = computed(() => {
	if (Array.isArray(model.value)) {
		return model.value.includes(props.customValue as T);
	}
	return !!model.value || false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.label ? 'label' : 'span'), {
    class: _normalizeClass(["checkbox", {'checkbox--checked': isChecked.value, 'checkbox--disabled': _ctx.disabled}])
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        disabled: _ctx.disabled,
        type: "checkbox",
        class: "checkbox__input",
        role: "checkbox",
        "aria-checked": isChecked.value,
        "aria-label": _ctx.label,
        value: _ctx.customValue
      }, null, 8 /* PROPS */, _hoisted_1), [
        [_vModelCheckbox, model.value]
      ]),
      _createElementVNode("span", _hoisted_2, [
        _createVNode(BaseIcon, {
          class: "checkbox__icon",
          name: "check"
        }),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class"]))
}
}

})