<template>
	<svg
		width="24"
		height="19"
		viewBox="0 0 24 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Card Symbol</title>
		<path
			fill="#000000"
			d="M2.66667 0C1.19583 0 0 1.19583 0 2.66667V4H24V2.66667C24 1.19583 22.8042 0 21.3333 0H2.66667ZM24 8H0V16C0 17.4708 1.19583 18.6667 2.66667 18.6667H21.3333C22.8042 18.6667 24 17.4708 24 16V8ZM4.66667 13.3333H7.33333C7.7 13.3333 8 13.6333 8 14C8 14.3667 7.7 14.6667 7.33333 14.6667H4.66667C4.3 14.6667 4 14.3667 4 14C4 13.6333 4.3 13.3333 4.66667 13.3333ZM9.33333 14C9.33333 13.6333 9.63333 13.3333 10 13.3333H15.3333C15.7 13.3333 16 13.6333 16 14C16 14.3667 15.7 14.6667 15.3333 14.6667H10C9.63333 14.6667 9.33333 14.3667 9.33333 14Z"
		/>
	</svg>
</template>
