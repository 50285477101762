<template>
	<ul class="additional-checkbox-filters">
		<template
			v-for="(availableFilter, index) in listOfAvailableFilters"
			:key="index"
		>
			<li class="additional-checkbox-filters__item">
				<Checkbox
					v-model="ratingFilters"
					:label="availableFilter.label"
					:custom-value="availableFilter.value"
				/>
			</li>
		</template>
	</ul>
</template>

<script lang="ts" setup>
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { additionalCheckboxFilterTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';

const store = useStore();

const listOfAvailableFilters = ref(additionalCheckboxFilterTypes);

const ratingFilters = computed({
	get() {
		return store.state.searchMask.additionalCheckboxFilters?.ratingAttributes as string[];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			{ additionalCheckboxFilters: { ratingAttributes: value } }
		);
	}
});
</script>

<style lang="scss" scoped>
.additional-checkbox-filters {
	margin: 0;
	padding: 0;
	padding-left: 0.8rem;
	list-style: none;

	&__item {
		display: block;
		margin-bottom: 1rem;
	}
}
</style>
