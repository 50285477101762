<template>
	<span class="discount-badge">{{ badgeText }}</span>
</template>

<script setup lang="ts">
import { determineClient, getBaseUrl } from '@utils/environmentUtils';
import { computed } from 'vue';

const client = determineClient(getBaseUrl());
const badgeText = computed(() => {
	switch (client) {
	case 'kuat':
		return 'Special Deal %';
	case 'lmat':
		return 'Schnäppchen %';
	default:
		return 'Restplatz %';
	}
});

</script>

<style lang="scss" scoped>
.discount-badge {
	background-color: $color-lime-light;
	color: $color-price-badge-text;
	font-size: $font-small-1;
	padding: 0.4rem 0.8rem;
	border-radius: 0.4rem;
	align-self: flex-end;
}

</style>
