import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout-sepa" }
const _hoisted_2 = { class: "checkout-sepa__name-info" }
const _hoisted_3 = { class: "checkout-sepa__payment-info" }

import { computed, watch } from 'vue';
import { useStore } from '@/components/common/store';
import {
	stringRequiredSchema, ibanRequiredSchema, bicRequiredSchema,
	noSchema,
} from '@/js/utils/validation-schemas';
import { z } from 'zod';
import { CheckoutValidationState } from '@/components/common/store/checkout';
import { Payment } from '@/interfaces/checkout/checkout-state';
import CheckoutTextField from '../CheckoutTextField/CheckoutTextField.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutSepaFormFields',
  props: {
    uniqueKey: { default: '' },
    validateForm: { type: Boolean, default: true }
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const updatePayment = (value: Partial<Payment>): void => {
	store.dispatch('checkout/updatePayment', value);
};

const iban = computed({
	get() {
		return store.state.checkout.Payment.IBAN || '';
	},
	set(value) {
		updatePayment({ IBAN: value.toUpperCase() }); // TODO remove after implement 10988 Ticket
	}
});

const bic = computed({
	get() {
		return store.state.checkout.Payment.BIC;
	},
	set(value) {
		updatePayment({ BIC: value.toUpperCase() });
	}
});

const errorMessage = (type: 'IBAN' | 'BIC') => {
	const isIban = type === 'IBAN';
	const inputValue = isIban ? iban.value : bic.value;

	if (inputValue.length === 0) {
		return `${type} fehlt`;
	}

	return `${type} ungültig`;
};

const firstName = computed({
	get() {
		return store.state.checkout.Payment.FirstName;
	},
	set(value: string) {
		updatePayment({ FirstName: value });
	}
});

const lastName = computed({
	get() {
		return store.state.checkout.Payment.LastName;
	},

	set(value: string) {
		updatePayment({ LastName: value });
	}
});

const setValidState = (inputValue: string, fieldName: string, validationSchema: z.ZodString | z.ZodPipeline) => {
	const validationResult = validationSchema.safeParse(inputValue);
	CheckoutValidationState.value[`${fieldName}-${props.uniqueKey}`].valid = !!inputValue && validationResult.success;
};

watch(() => store.getters['checkout/insuranceOffer'], () => {
	setValidState(firstName.value, 'first-name', stringRequiredSchema);
	setValidState(lastName.value, 'last-name', stringRequiredSchema);
	setValidState(iban.value, 'iban', ibanRequiredSchema);
	setValidState(bic.value, 'bic', bicRequiredSchema);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CheckoutTextField, {
        modelValue: firstName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((firstName).value = $event)),
        modelModifiers: { trim: true },
        label: "Vorname",
        "field-name": "first-name",
        "required-field": true,
        "unique-key": _ctx.uniqueKey,
        placeholder: "z.B. Max",
        "error-message": "Vorname fehlt",
        "validation-schema": _ctx.validateForm ? _unref(stringRequiredSchema) : _unref(noSchema)
      }, null, 8 /* PROPS */, ["modelValue", "unique-key", "validation-schema"]),
      _createVNode(CheckoutTextField, {
        modelValue: lastName.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lastName).value = $event)),
        modelModifiers: { trim: true },
        label: "Nachname",
        "field-name": "last-name",
        "required-field": true,
        "unique-key": _ctx.uniqueKey,
        placeholder: "z.B. Mustermann",
        "error-message": "Nachname fehlt",
        "validation-schema": _ctx.validateForm ? _unref(stringRequiredSchema) : _unref(noSchema)
      }, null, 8 /* PROPS */, ["modelValue", "unique-key", "validation-schema"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(CheckoutTextField, {
        modelValue: iban.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((iban).value = $event)),
        modelModifiers: { trim: true },
        class: "checkout-insurance__text-field--uppercase",
        label: "IBAN",
        "field-name": "iban",
        "required-field": true,
        "unique-key": _ctx.uniqueKey,
        placeholder: "z.B. AT02 2011 1000 0342 9660",
        "error-message": errorMessage('IBAN'),
        "validation-schema": _ctx.validateForm ? _unref(ibanRequiredSchema) : _unref(noSchema)
      }, null, 8 /* PROPS */, ["modelValue", "unique-key", "error-message", "validation-schema"]),
      _createVNode(CheckoutTextField, {
        modelValue: bic.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((bic).value = $event)),
        modelModifiers: { trim: true },
        class: "checkout-insurance__text-field--uppercase",
        label: "BIC",
        "field-name": "bic",
        "required-field": true,
        "unique-key": _ctx.uniqueKey,
        placeholder: "z.B. GIBAATWW",
        "error-message": errorMessage('BIC'),
        "validation-schema": _ctx.validateForm ? _unref(bicRequiredSchema) : _unref(noSchema)
      }, null, 8 /* PROPS */, ["modelValue", "unique-key", "error-message", "validation-schema"])
    ])
  ]))
}
}

})