import austrianWordings from '@assets/i18n/at-wordings.json';
import germanWordings from '@assets/i18n/de-wordings.json';
import swissWordings from '@assets/i18n/ch-wordings.json';
import {
	type ClientCodes, WEB_CLIENTS, type WebClientHost, CLIENT_SHORT_CODES,
	type ClientCurrency,
	CURRENCIES,
	CLIENT_DOMAIN_CODES,
	ClientCountry,
	GERMAN_CLIENTS,
	CLIENT_COUNTRIES,
	AUSTRIAN_CLIENTS,
	SWISS_CLIENTS,
	WebClientName,
	WEB_CLIENTS_NAMES,
	CLIENT_COUNTRIES_IN_GERMAN,
} from '@global-js/constants';
import type { ClientType } from '@/interfaces/common';

/**
 * Get the client mode based on the TLD of the given url.
 * Never use it in Storybook Components. Instead pass the client as a prop
 * @param url
 */
export function determineClient(url: string = window.location.host): ClientType {
	const DEFAULT_CLIENT: ClientType = CLIENT_DOMAIN_CODES.at; // Default to AT
	if (url.includes(WEB_CLIENTS.com)) {
		return CLIENT_DOMAIN_CODES.com;
	} if (url.includes(WEB_CLIENTS.tbde)) {
		return CLIENT_DOMAIN_CODES.tbde;
	} if (url.includes(WEB_CLIENTS.ch)) {
		return CLIENT_DOMAIN_CODES.ch;
	} if (url.includes(WEB_CLIENTS.kuat)) {
		return CLIENT_DOMAIN_CODES.kuat;
	} if (url.includes(WEB_CLIENTS.lmat)) {
		return CLIENT_DOMAIN_CODES.lmat;
	}

	return DEFAULT_CLIENT;
}

/** returns the name of website/company (restplatzboerse.at, lastminute.at or kuoni.at)
 */
export function getWebsiteHostName(client = determineClient()): WebClientHost {
	return WEB_CLIENTS?.[client] ?? WEB_CLIENTS.at;
}

function getClientPath(client: ClientType, basePaths: Partial<Record<ClientType, string>>, defaultPath: string): string {
	return basePaths[client] ?? defaultPath;
}

export function getWebsiteName(client: ClientType = determineClient()): WebClientName { return WEB_CLIENTS_NAMES[client]; }

/**
 * Get the base TT IBE URL ("https://<domain>/") from
 * the project environment "ibeBaseUrls" object.
 */
export function getIbeBaseUrl(): string {
	const client = determineClient();
	let baseUrl = '';
	if (!process.env.ibeBaseUrls) {
		throw new Error('ibeBaseUrls is not defined!');
	} else {
		baseUrl = JSON.parse(process.env.ibeBaseUrls)[client];
	}

	return baseUrl;
}

/**
 * Get the base URL ("https://<domain>/") from
 * the project environment "baseUrls" object.
 */
export function getBaseUrl(client: string = determineClient()): string {
	let baseUrl = '';
	if (!process.env.baseUrls) {
		throw new Error('baseUrls is not defined!');
	} else {
		baseUrl = JSON.parse(process.env.baseUrls)[client];
	}
	return baseUrl;
}

export function getBasePath(client: ClientType = determineClient()): string {
	const basePath = getBaseUrl(client);
	const paths: Partial<Record<ClientType, string>> = {
		kuat: `${basePath}/fileadmin/2/kuoni`,
		lmat: `${basePath}/fileadmin/2/lastminute`,
		tbde: `${basePath}/fileadmin/2/touristikboerse`,
	};

	return getClientPath(client, paths, `${basePath}/fileadmin/2/restplatzboerse/all`);
}

/** returns the logo of the correct website.
 */
export function getQuoteBannerLogo(client: ClientType = determineClient()): string {
	const baseUrl = getBaseUrl();
	const logos = {
		kuat: `${baseUrl}/fileadmin/2/kuoni/img/logo.svg`,
		lmat: `${baseUrl}/fileadmin/2/lastminute/img/logo.svg`,
		tbde: `${baseUrl}/fileadmin/2/touristikboerse/img/logo.svg`,
		at: `${baseUrl}/fileadmin/2/restplatzboerse/all/img/logo_plane_shadow@2x.png`,
		com: `${baseUrl}/fileadmin/2/restplatzboerse/all/img/logo_plane_shadow@2x.png`,
		ch: `${baseUrl}/fileadmin/2/restplatzboerse/all/img/logo_plane_shadow@2x.png`,
	} satisfies Record<ClientType, string>;

	return getClientPath(client, logos, '');
}

export function getWebsiteLogoPath(client: ClientType = determineClient()): string {
	const basePath = getBaseUrl(client);
	const paths: Partial<Record<ClientType, string>> = {
		kuat: `${basePath}/fileadmin/2/kuoni`,
		lmat: `${basePath}/fileadmin/2/lastminute`,
		com: `${basePath}/fileadmin/2/restplatzboerse/de`,
		ch: `${basePath}/fileadmin/2/restplatzboerse/ch`,
		tbde: `${basePath}/fileadmin/2/touristikboerse`,
	};

	return getClientPath(client, paths, `${basePath}/fileadmin/2/restplatzboerse/at`);
}

export function getClientString(client: ClientType = determineClient()): ClientCodes | '' {
	const clientMap: Record<ClientType, ClientCodes> = {
		kuat: CLIENT_SHORT_CODES.kuat,
		lmat: CLIENT_SHORT_CODES.lmat,
		at: CLIENT_SHORT_CODES.at,
		com: CLIENT_SHORT_CODES.com,
		ch: CLIENT_SHORT_CODES.ch,
		tbde: CLIENT_SHORT_CODES.tbde,
	};

	return clientMap[client] ?? '';
}

export function getLocaleString(i18Property: keyof typeof austrianWordings): string {
	let localeJSON = austrianWordings;

	if (isClientGermany()) {
		localeJSON = germanWordings;
	}

	if (isClientSwitzerland()) {
		localeJSON = swissWordings;
	}

	return localeJSON[i18Property];
}

export function getDefaultCurrency(client: ClientType = determineClient()): ClientCurrency {
	return client === 'ch' ? CURRENCIES.CHF : CURRENCIES.EUR;
}

export function getPaymentUrl(): string {
	return `${getBaseUrl()}/fileadmin/2/restplatzboerse/all/img/payment`;
}

export function determineCountry(client: ClientType = determineClient()): ClientCountry {
	if (GERMAN_CLIENTS.includes(client)) {
		return CLIENT_COUNTRIES.GERMANY;
	}
	if (AUSTRIAN_CLIENTS.includes(client)) {
		return CLIENT_COUNTRIES.AUSTRIA;
	}
	if (SWISS_CLIENTS.includes(client)) {
		return CLIENT_COUNTRIES.SWITZERLAND;
	}
	return CLIENT_COUNTRIES.AUSTRIA;
}

export const isClientGermany = (client: ClientType = determineClient()) => determineCountry(client) === CLIENT_COUNTRIES.GERMANY;
export const isClientSwitzerland = (client: ClientType = determineClient()) => determineCountry(client) === CLIENT_COUNTRIES.SWITZERLAND;
export const isClientAustria = (client: ClientType = determineClient()) => determineCountry(client) === CLIENT_COUNTRIES.AUSTRIA;

export function getDefaultCountry(client: ClientType = determineClient()) {
	const defaultCountries = {
		at: CLIENT_COUNTRIES_IN_GERMAN.AUSTRIA,
		kuat: CLIENT_COUNTRIES_IN_GERMAN.AUSTRIA,
		lmat: CLIENT_COUNTRIES_IN_GERMAN.AUSTRIA,
		com: CLIENT_COUNTRIES_IN_GERMAN.GERMANY,
		tbde: CLIENT_COUNTRIES_IN_GERMAN.GERMANY,
		ch: CLIENT_COUNTRIES_IN_GERMAN.SWITZERLAND,
	} satisfies Record<ClientType, string>;

	return defaultCountries[client] ?? '';
}

export const isRpbClient = (client: ClientType = determineClient()) => client === 'at' || client === 'com' || client === 'ch';
export const isKuatClient = (client: ClientType = determineClient()) => client === 'kuat';
export const isLmatClient = (client: ClientType = determineClient()) => client === 'lmat';
export const isTbdeClient = (client: ClientType = determineClient()) => client === 'tbde';
