import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, unref as _unref, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "calendar__wrap" }

import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';
import {
	now, dateDiff,
} from '@utils/utils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Confirm from '@lmt-rpb/Confirm/Confirm.vue';
import { EventBus } from '@global-js/event-bus';
import { OfferDuration } from '@interfaces/search-form';
import { useStore } from '@/components/common/store';
import type { SelectionType } from '@/components/common/types';
import {
	computed,
	onMounted,
	onUnmounted,
	Ref,
	ref,
} from 'vue';
import type { MonthData } from '@/interfaces/commonProps';
import Month from '../Month/Month.vue';
import Pager from '../Pager/Pager.vue';

type BestPriceMonthData = Omit<MonthData, 'active'>;

interface Props {
	modelValue?: SelectionType,
	maxMonths: number,
	horizontal: boolean,
	isExact?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Calendar',
  props: {
    modelValue: { default: () => ({ from: 0, to: 0 }) },
    maxMonths: { default: 24 },
    horizontal: { type: Boolean, default: true },
    isExact: { type: Boolean }
  },
  emits: ['update:modelValue', 'TravelDuration:Changed', 'Calendar:AlertOkClick', 'Calendar:AlertCancelClick'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const props = __props;
const showConfirm = ref(false);

const showDismiss = ref(false);

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const nowDate: Date = now() as Date;

const currentMonth: number = nowDate.getMonth();

const currentYear: number = nowDate.getFullYear();

const monthsToShow: Ref<BestPriceMonthData[]> = ref([]);

const steps = ref(0);

const offerDuration = computed((): OfferDuration | undefined => store.state.searchMask?.offerDuration);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const innerStyle = computed((): Record<string, string> => {
	if (!isDesktop.value) return {};
	const percent = steps.value * -50;

	return {
		transform: `translateX(${percent}%)`
	};
});

const scrollToSelectedMonth = () => {
	if (!isDesktop.value) {
		const indexToScroll = dateDiff(new Date(), offerDuration.value?.from, 'month');

		const monthToScroll = document.querySelector(`.calendar__month[offset="${monthsToShow.value[indexToScroll]?.offset}"]`);
		monthToScroll?.scrollIntoView();
	}
};

const onSelect = (day: Date) => {
	const from = model.value?.from;
	const to = model.value?.to;
	const date = day.getTime();

	if (!!from && !!to) {
		// reselect date
		model.value = { from: date, to: 0 };
	} else if (from === date) {
		// reset selection by reselect start date
		model.value = { from: 0, to: 0 };
	} else if (from && date < from) {
		// switch start date if it's earlier than previously
		const previous = model.value.from;
		model.value = { from: date, to: previous };
	} else if (!from) {
		// set from date
		model.value = { from: date, to: 0 };
	} else if (!to) {
		// set to date
		model.value = { ...model.value, to: date };
	}
};

const onSlide = (direction: number) => {
	steps.value = Math.max(0, Math.min(steps.value + direction, monthsToShow.value.length));
};
const onDismissCancel = () => {
	emit('Calendar:AlertCancelClick');
	showDismiss.value = false;
};

const onDismissOk = () => {
	emit('Calendar:AlertOkClick');
	showDismiss.value = false;
};

const getMonth = (offset: number): BestPriceMonthData => {
	const date = new Date(currentYear, offset);
	const index = date.getMonth();

	return {
		year: date.getFullYear(),
		month: index,
		offset
	};
};

const visibilityChanged = (month: BestPriceMonthData, index: number) => {
	// lookahead 2 visible month
	const count = monthsToShow.value.length;
	if (index + 2 > count && count < props.maxMonths) {
		const next = getMonth(month.offset + 1);
		monthsToShow.value.push(next);
	}
};

onMounted(() => {
	EventBus.$on('Calendar:Scroll', scrollToSelectedMonth);

	if (!offerDuration.value?.from) {
		monthsToShow.value = [currentMonth, currentMonth + 1].map(getMonth);
	} else {
		const indexToScroll = dateDiff(new Date(), offerDuration.value?.from, 'month');
		const indexPlus = indexToScroll >= 3 ? 1 : 0;

		for (let index = 0; index <= indexToScroll + indexPlus; index++) {
			monthsToShow.value.push(getMonth(currentMonth + index));
		}
		onSlide(indexToScroll);
		if (!isDesktop.value) {
			setTimeout(() => {
				const monthToScroll = document.querySelector(`.calendar__month[offset="${monthsToShow.value[indexToScroll + indexPlus]?.offset}"]`);
				monthToScroll?.scrollIntoView();
			}, 50);
		}
	}
});

onUnmounted(() => {
	EventBus.$off('Calendar:Scroll', scrollToSelectedMonth);
});

__expose({
	showConfirm,
	showDismiss,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["calendar", {'is-horizontal': _ctx.horizontal}])
  }, [
    (_ctx.horizontal)
      ? (_openBlock(), _createBlock(Pager, {
          key: 0,
          disabled: steps.value === 0,
          direction: "left",
          class: "calendar__pager is-prev",
          onSlide: onSlide
        }, null, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true),
    (_ctx.horizontal)
      ? (_openBlock(), _createBlock(Pager, {
          key: 1,
          disabled: steps.value === monthsToShow.value.length - 2,
          direction: "right",
          class: "calendar__pager is-next",
          onSlide: onSlide
        }, null, 8 /* PROPS */, ["disabled"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "calendar__inner",
        style: _normalizeStyle(innerStyle.value)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthsToShow.value, (monthData, index) => {
          return _withDirectives((_openBlock(), _createBlock(Month, _mergeProps({
            key: index,
            ref_for: true
          }, monthData, {
            class: "calendar__month",
            selection: model.value,
            horizontal: _ctx.horizontal,
            onSelect: onSelect
          }), null, 16 /* FULL_PROPS */, ["selection", "horizontal"])), [
            [_unref(vObserveVisibility), {
						callback: (isVisible) => isVisible && visibilityChanged(monthData, index),
						once: true
					}]
          ])
        }), 128 /* KEYED_FRAGMENT */))
      ], 4 /* STYLE */)
    ]),
    (showDismiss.value)
      ? (_openBlock(), _createBlock(Confirm, {
          key: 2,
          title: "Möchten Sie die Auswahl verwerfen?",
          cancel: `Verwerfen`,
          class: "calendar__duration-cancel-confirm",
          ok: "Übernehmen",
          "onConfirm:Ok": onDismissOk,
          "onConfirm:Cancel": onDismissCancel,
          "onConfirm:BrowserBack": onDismissCancel
        }, {
          "cancel-icon": _withCtx(() => [
            _createVNode(BaseIcon, {
              name: "trashcan",
              class: "calendar__button-icon is-cancel"
            })
          ]),
          "ok-icon": _withCtx(() => [
            _createVNode(BaseIcon, {
              name: "check",
              viewBox: "0 0 15.906 12",
              class: "calendar__button-icon is-ok"
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})