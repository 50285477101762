import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout-info-button" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "checkout-info-button__button-text" }

import { ref } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import OfferDetailsModal from '@lmt-rpb/OfferDetailsModal/OfferDetailsModal.vue';

type Props = {
	buttonText: string,
	modalTitle: string
	disabled?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutInfoButton',
  props: {
    buttonText: {},
    modalTitle: {},
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any, { expose: __expose }) {



const modal = ref<InstanceType<typeof OfferDetailsModal>>();

const modalOpen = ref(false);

const closeModal = () => {
	modalOpen.value = false;
};

const showModal = (event: Event): void => {
	// necessary for carousel to correctly init image sizes
	// carousel doesn't calculate the width if they are not rendered
	modalOpen.value = true;
	event?.preventDefault();
	modal.value?.open();
};

__expose({
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "button", {}, () => [
      _createElementVNode("button", {
        type: "button",
        disabled: _ctx.disabled,
        class: "checkout-info-button__button",
        onClick: showModal
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.buttonText), 1 /* TEXT */),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(BaseIcon, {
              key: 0,
              class: "checkout-info-button__button-arrow",
              name: "arrowRight"
            }))
          : _createCommentVNode("v-if", true)
      ], 8 /* PROPS */, _hoisted_2)
    ]),
    _createVNode(OfferDetailsModal, {
      ref_key: "modal",
      ref: modal,
      class: "offer-details-modal-button__modal",
      title: _ctx.modalTitle,
      "onOfferDetailsModal:closeModal": closeModal
    }, {
      default: _withCtx(() => [
        (modalOpen.value)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["title"])
  ]))
}
}

})