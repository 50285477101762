<template>
	<div
		v-if="!isDesktop"
		class="searchbar"
		:class="{
			'searchbar--one-column': !hasSort && !hasMap,
			'searchbar--three-columns': hasSort && hasMap
		}"
	>
		<div
			v-if="hasMap"
			class="searchbar__item searchbar__item--map"
			@click="handleMapClick"
		>
			<BaseIcon
				name="map"
				class="searchbar__icon searchbar__icon--map"
			/>
			<p class="searchbar__text">
				Karte
			</p>
		</div>
		<div
			v-if="hasSort"
			class="searchbar__item searchbar__item--sort"
			@click="handleSortClick"
		>
			<BaseIcon
				name="sortDesc"
				class="searchbar__icon"
			/>
			<LabeledText
				label="Sortiert nach"
				class="searchbar__text searchbar__text--sort"
				:text="sortText"
			/>
		</div>
		<div
			class="searchbar__item searchbar__item--filter"
			@click="handleFilterClick"
		>
			<BaseIcon
				name="filterNew"
				class="searchbar__icon"
			/>
			<p class="searchbar__text">
				Filter {{ filterAmount ? `(${filterAmount})` : '' }}
			</p>
		</div>
	</div>
</template>
<script setup lang="ts">
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';
import LabeledText from '@lmt-rpb/LabeledText/LabeledText.vue';
import { getFilterCount } from '@/js/utils/search-filter-utils';

type Props = {
	hasMap?: boolean
	hasSort?: boolean
}

withDefaults(defineProps<Props>(), {
	hasMap: true,
	hasSort: true
});

const emit = defineEmits(['SearchBar:openFilterModal']);

const store = useStore();
const sortingPricePopular = computed(() => store.state.searchMask.sortingPricePopular);
const sortText = computed(() => (sortingPricePopular.value ? 'Beliebtheit' : 'Preis'),);
const isDesktop = computed(() => store.state.config.isDesktop);
const formData = computed(() => store.state.searchMask);
const filterAmount = computed((): number => getFilterCount(formData.value));

const handleMapClick = () => {
	EventBus.$emit('map:toggle');
};
const handleSortClick = () => {
	store.commit('searchMask/updateFormData', { sortingPricePopular: !sortingPricePopular.value });
};
const handleFilterClick = () => {
	emit('SearchBar:openFilterModal');
};

</script>
<style scoped lang="scss">
 
.searchbar {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-items: center;
	width: 100%;
	background-color: $color-white;
	box-shadow: 0.3rem 0.6rem 1.4rem 0 #66666633;
	height: 5.2rem;

	&--one-column {
		grid-template-columns: 1fr;
	}

	&--three-columns {
		grid-template-columns: 4fr 6fr 5fr;
	}

	&__icon {
		width: 2rem;
		height: 2rem;
		fill: $color-text-upselling;

		&--map {
			fill: none;
			color: $color-text-upselling;
		}
	}

	&__text {
		margin-bottom: 0;
		color: $color-text-upselling;

		&--sort {
			width: 7.5rem;
		}
	}

	&__item {
		align-items: center;
		color: $color-primary;
		column-gap: 0.8rem;
		cursor: pointer;
		display: flex;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		justify-content: center;
		height: 3.6rem;
		width: 100%;
		position: relative;

		&:not(:last-child)::after {
			content: '';
			height: 3.6rem;
			width: 0.1rem;
			background-color: $color-border;
			position: absolute;
			right: 0;
		}
	}
}

@media screen and (min-width: $breakpoint-mobiletabs) {
	.searchbar--three-columns {
		grid-template-columns: repeat(3, 1fr);
	}
}
</style>
