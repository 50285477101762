<template>
	<div
		:class="{
			'big' : size === 'big',
			'medium' : size === 'medium',
			'small' : size === 'small',
		}"
		class="loading-spinner"
	>
		<svg
			width="150"
			height="150"
			viewBox="-50 -50 300 300"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="loading-spinner__svg"
		>
			<defs>
				<linearGradient :id="secondId">
					<stop
						offset="0%"
						stop-opacity="0"
						stop-color="currentColor"
					/>
					<stop
						offset="100%"
						stop-opacity="0.5"
						stop-color="currentColor"
					/>
				</linearGradient>
				<linearGradient :id="firstId">
					<stop
						offset="0%"
						stop-opacity="1"
						stop-color="currentColor"
					/>
					<stop
						offset="100%"
						stop-opacity="0.5"
						stop-color="currentColor"
					/>
				</linearGradient>
			</defs>
			<g stroke-width="20">
				<path
					:stroke="`url(#${secondId})`"
					d="M 4 100 A 96 96 0 0 1 196 100"
				/>
				<path
					:stroke="`url(#${firstId})`"
					d="M 196 100 A 96 96 0 0 1 4 100"
				/>
				<path
					stroke="currentColor"
					stroke-linecap="round"
					d="M 4 100 A 96 96 0 0 1 4 98"
				/>
			</g>
		</svg>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(defineProps<{
	size?: 'small' | 'medium' | 'big',
	uniqueKey?: string
}>(), {
	size: 'big',
	uniqueKey: ''
});

const firstId = computed((): string => {
	if (props.uniqueKey) {
		return `spinner-firstHalf-${props.uniqueKey}`;
	}
	return 'spinner-firstHalf';
});

const secondId = computed((): string => {
	if (props.uniqueKey) {
		return `spinner-secondHalf-${props.uniqueKey}`;
	}
	return 'spinner-secondHalf';
});

</script>

<style lang="scss" scoped>
@keyframes spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

.loading-spinner {
	position: relative;

	&.big {
		width: 13rem;
		height: 13rem;
	}

	&.medium {
		width: 5.8rem;
		height: 5.8rem;
	}

	&.small {
		width: 4rem;
		height: 4rem;
	}

	&__svg {
		width: 100%;
		height: 100%;
		color: $loading-spinner-color;
		animation: spin 1s infinite linear;
	}
}
</style>
