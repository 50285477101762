import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map-offer-button__button" }

import {
	computed, onMounted, ref,
} from 'vue';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import MapOfferBox from '@lmt-rpb/MapOfferBox/MapOfferBox.vue';
import { useStore } from '@/components/common/store';
import { formatPrice } from '@/js/utils/priceUtils';

interface Props {
	item: ItemType,
	showSuns: boolean,
	domain?: string,
	bestRating?: number,
	rating?: number,
	index: number
	map: google.maps.Map;
	defaultOpen?: boolean
	isHotelPage?: boolean
	shouldNavigate?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapOfferButton',
  props: {
    item: {},
    showSuns: { type: Boolean, default: false },
    domain: { default: 'AT' },
    bestRating: { default: 6 },
    rating: { default: 2 },
    index: {},
    map: {},
    defaultOpen: { type: Boolean, default: false },
    isHotelPage: { type: Boolean },
    shouldNavigate: { type: Boolean }
  },
  emits: ['MapOfferButton:Click'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const offer = computed(() => props.item.PackageOffer || props.item.AccommodationOffer!);

const root = ref<HTMLElement>();

const marker = ref<google.maps.marker.AdvancedMarkerElement>();

const infowindow = ref<google.maps.InfoWindow>();

const isActive = ref(false);

const buttonClicked = ref(false);

const store = useStore();

const isMobile = computed(() => store.state.config.isMobile);

const mouseEnterHandler = () => {
	if (isMobile.value) return;
	setActive(true);
};

onMounted(() => {
	createMarker();
	if (props.defaultOpen) {
		setActive(true);
		buttonClicked.value = true;
	}
});

const emit = __emit;

function closeInfoWindow() {
	buttonClicked.value = false;
	setActive(false);
}

function buttonClick() {
	emit('MapOfferButton:Click');
	setActive(true);
	buttonClicked.value = true;
}
function setActive(state: boolean) {
	if (state && !isActive.value) {
		openInfoWindow();
		isActive.value = state;
	} else if (!state && !buttonClicked.value) {
		infowindow.value?.close();
		isActive.value = state;
	}
}

function openInfoWindow() {
	infowindow.value?.open({
		anchor: marker.value,
		map: marker.value!.map,
	});
	if (isMobile.value) {
		// Comes from MapWithOffers
		const initialZoom = 7;
		const currentZoom = props.map.getZoom();
		// when the mobile browser toolbars are open this is needed to ensure that the marker is still in view
		const availableHeightRatio = (window.screen.height === window.innerHeight) ? 1 : (window.screen.height / window.innerHeight) * 1.5;
		const yOffset = -1 * ((availableHeightRatio * 0.25) ** Math.max(1, ((currentZoom ?? initialZoom) - initialZoom)));
		if (props.map.getRenderingType() !== 'UNINITIALIZED') {
			props.map.panTo({ lat: props.item.Location.Latitude + yOffset, lng: props.item.Location.Longitude });
		} else {
			props.map.setCenter({ lat: props.item.Location.Latitude + yOffset, lng: props.item.Location.Longitude });
		}
	}
	infowindow.value?.addListener('domready', () => {
		(infowindow.value?.getContent() as HTMLElement).parentElement?.addEventListener('mouseleave', ((args) => {
			setActive(false);
		}));
	});
}

function createMarker() {
	infowindow.value = new google.maps.InfoWindow({
		content: root.value?.querySelector('.map-offer-button__hotelbox'),
		headerDisabled: true,
		disableAutoPan: isMobile.value
	});

	marker.value = new google.maps.marker.AdvancedMarkerElement({
		zIndex: props.defaultOpen ? 9000 : props.index,
		position: { lat: props.item.Location.Latitude, lng: props.item.Location.Longitude },
		content: root.value,
		collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
		map: props.map
	});
	marker.value.addListener('click', buttonClick);
}

function mouseLeaveButton(event: MouseEvent) {
	if (isMobile.value) return;
	const relatedTarget = event.relatedTarget;
	if (relatedTarget instanceof HTMLElement && !relatedTarget.classList.contains('map-offer-button__hotelbox')) {
		setActive(false);
	}
}

const formattedPrice = computed((): string => formatPrice(offer.value.Price, offer.value.CurrencyCode));

__expose({
	marker,
	setActive,
	props,
	buttonClicked,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "root",
    ref: root,
    class: _normalizeClass(["map-offer-button", {'map-offer-button--active' : isActive.value}]),
    onMouseenter: mouseEnterHandler,
    onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (mouseLeaveButton($event)))
  }, [
    _createElementVNode("button", _hoisted_1, _toDisplayString(formattedPrice.value), 1 /* TEXT */),
    (_openBlock(), _createBlock(_Teleport, {
      to: ".map-with-offers",
      disabled: !isMobile.value
    }, [
      _createVNode(MapOfferBox, {
        class: _normalizeClass([{'map-offer-button__hotelbox--hover-only': !buttonClicked.value, 'map-offer-button__hotelbox--active': isMobile.value && isActive.value}, "map-offer-button__hotelbox"]),
        item: _ctx.item,
        "show-suns": _ctx.showSuns,
        domain: _ctx.domain,
        "best-rating": _ctx.bestRating,
        rating: _ctx.rating,
        "is-active": isActive.value,
        "is-hotel-page": _ctx.isHotelPage,
        "should-navigate": _ctx.shouldNavigate,
        "onMapOfferBox:LinkOpened": buttonClick,
        "onMapOfferBox:Close": closeInfoWindow
      }, null, 8 /* PROPS */, ["class", "item", "show-suns", "domain", "best-rating", "rating", "is-active", "is-hotel-page", "should-navigate"])
    ], 8 /* PROPS */, ["disabled"]))
  ], 34 /* CLASS, NEED_HYDRATION */))
}
}

})