import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "catalogue-info" }
const _hoisted_2 = {
  key: 0,
  class: "catalogue-info__warning-wrap"
}
const _hoisted_3 = { class: "catalogue-info__warning is-outdated" }
const _hoisted_4 = { class: "catalogue-info__warning is-not-valid" }
const _hoisted_5 = {
  key: 0,
  class: "catalogue-info__warning-info-text"
}

import { HotelCatalogueType } from '@interfaces/hotel-catalogue-types';
import { getLocaleString } from '@utils/environmentUtils';
import BaseIcons from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import {
	computed, ref, watch,
} from 'vue';
import CatalogueInfoContent from '@lmt-rpb/CatalogueInfoContent/CatalogueInfoContent.vue';

interface Props {
	catalogueInfo: HotelCatalogueType
	client?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogueInfo',
  props: {
    catalogueInfo: {},
    client: { default: 'at' }
  },
  emits: ['operator-changed'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const selectedOperator = ref(props.catalogueInfo.TourOperatorCode || null);

const tourOperators = computed((): Record<string, string>[] => {
	if (props.catalogueInfo.TourOperators) {
		return Object.entries(props.catalogueInfo.TourOperators).map(([code, name]) => ({ code, name }));
	}
	return [];
});

watch(() => selectedOperator.value, (operator: string | null) => {
	emit('operator-changed', operator);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.catalogueInfo.OfferCodeMatches && !_ctx.catalogueInfo.CatalogValid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(BaseIcons, {
              name: "directFlight",
              class: "catalogue-info__warning-icon"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "catalogue-info__warning-main-text" }, "Leider liegt derzeit keine zum Angebot passende Hotelbeschreibung des gewählten Veranstalters in der gewählten Saison vor.", -1 /* HOISTED */)),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "catalogue-info__warning-info-text" }, " Alternativ zeigen wir Ihnen die Beschreibung des Veranstalters zur vorherigen Saison, diese kann unter Umständen von der gewählten Saison abweichen. ", -1 /* HOISTED */))
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (!_ctx.catalogueInfo.OfferCodeMatches)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["catalogue-info__warning-wrap catalogue-info__warning-wrap--flex", {'no-operator': !tourOperators.value.length}])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(BaseIcons, {
              name: "directFlight",
              class: "catalogue-info__warning-icon"
            }),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "catalogue-info__warning-main-text" }, "Leider liegt derzeit keine zum Angebot passende Hotelbeschreibung des gewählten Veranstalters vor.", -1 /* HOISTED */)),
            (tourOperators.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Die angezeigte Beschreibung von einem alternativen Veranstalter dient dazu Ihnen einen Eindruck von der Ausstattung der Hotelanlage zu geben. "))
              : _createCommentVNode("v-if", true)
          ]),
          (tourOperators.value.length)
            ? (_openBlock(), _createBlock(SelectField, {
                key: 0,
                modelValue: selectedOperator.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOperator).value = $event)),
                items: tourOperators.value,
                "show-footer": true,
                "button-wording": _unref(getLocaleString)('close'),
                label: "Infos von anderen Veranstaltern anzeigen *",
                "value-prop": "code",
                "label-prop": "name",
                class: "catalogue-info__operator-select"
              }, null, 8 /* PROPS */, ["modelValue", "items", "button-wording"]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.catalogueInfo.TourOperatorName)
      ? (_openBlock(), _createBlock(CatalogueInfoContent, {
          key: 2,
          "hotel-info": _ctx.catalogueInfo,
          client: _ctx.client
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("p", { class: "catalogue-info__body-hint" }, " Bitte beachten Sie, dass nur die beim von Ihnen ausgewählten Angebot verlinkte Hotelbeschreibung Gültigkeit hat! Es ist möglich, dass in Einzelfällen nicht alle Veranstalter Hotelbeschreibungen ausweisen oder es entscheidende Unterschiede in den beschriebenen Leistungen gibt. ", -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["hotel-info", "client"]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})