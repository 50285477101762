import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hotel-header" }
const _hoisted_2 = { class: "hotel-header__name" }
const _hoisted_3 = {
  key: 0,
  class: "hotel-header__address"
}

import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';

type Props = {
	hotel: {
		Name: string
		Address?: string
		Category: number
	}
	showSuns?: boolean
	showAddress?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelHeader',
  props: {
    hotel: {},
    showSuns: { type: Boolean },
    showAddress: { type: Boolean, default: true }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.hotel.Name), 1 /* TEXT */),
    _createVNode(HotelStars, {
      class: "hotel-header__stars",
      "show-suns": _ctx.showSuns,
      rating: _ctx.hotel.Category
    }, null, 8 /* PROPS */, ["show-suns", "rating"]),
    (_ctx.showAddress && _ctx.hotel.Address)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.hotel.Address), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})