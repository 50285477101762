import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-filters" }
const _hoisted_2 = { class: "list-filters__content" }

import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import BoardField from '@lmt-rpb/BoardField/BoardField.vue';
import DirectFlightField from '@lmt-rpb/DirectFlight/DirectFlight.vue';
import FlyingTimeFilter from '@lmt-rpb/FlyingTimeFilter/FlyingTimeFilter.vue';
import RoomTypeField from '@lmt-rpb/RoomTypeField/RoomTypeField.vue';
import SeaViewField from '@lmt-rpb/SeaView/SeaView.vue';
import TransferField from '@lmt-rpb/TransferField/TransferField.vue';
import HotelCategory from '@lmt-rpb/HotelCategory/HotelCategory.vue';
import RecommendationRate from '@lmt-rpb/RecommendationRate/RecommendationRate.vue';
import PriceSlider from '@lmt-rpb/PriceSlider/PriceSlider.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFilters/MostPopularFilters.vue';
import AdditionalCheckboxFilters from '@lmt-rpb/AdditionalCheckboxFilters/AdditionalCheckboxFilters.vue';
import OperatorField from '@lmt-rpb/OperatorField/OperatorField.vue';
import type { SearchFormDataType } from '@interfaces/search-form';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import FilterContainer from '@lmt-rpb/FilterContainer/FilterContainer.vue';
import {
	computed, onMounted, ref,
} from 'vue';
import FlexFilter from '@lmt-rpb/FlexFilter/FlexFilter.vue';
import DealsFilter from '@lmt-rpb/Organisms/DealsFilter/DealsFilter.vue';
import { dealsFilterClientTitle } from '@lmt-rpb/Organisms/DealsFilter/dealsFilterHelper';
import { useStore } from '@/components/common/store';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	forceDesktop?: boolean
	destinationLabel?: string
	listType: 'hotel' | 'region' | 'offer',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListFilters',
  props: {
    forceDesktop: { type: Boolean, default: false },
    destinationLabel: { default: '' },
    listType: {}
  },
  setup(__props: any) {


const props = __props;

const dealsFilterTitle = dealsFilterClientTitle();

const store = useStore();
const formData = computed((): SearchFormDataType => store.state.searchMask);
const locationType = computed((): string => store.state.config.locationType);

const showDestinationFilter = computed((): boolean => props.listType === 'hotel' && locationType.value !== 'CITY');
const destinationFilterTitle = computed(() => (locationType.value === 'COUNTRY' || locationType.value === 'REGIONGROUP' ? 'Region' : 'Ort'));
const destinationTypes = computed(() => store.state.types.destination);

const isHotelListPage = computed(() => props.listType === 'hotel');
const isOfferList = computed(() => props.listType === 'offer');
const showDealsFilter = computed((): boolean => isHotelListPage.value);

const openAdditionalFilters = ref(Boolean(formData.value.additionalCheckboxFilters?.ratingAttributes?.length || formData.value.additionalCheckboxFilters?.hotelAttributes?.length));

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const operatorTypes = computed(() => store.state.types.operator);

const showSuns = isClientGermany();

const currencyType = computed(() => (store.getters.getCurrencyType));

const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);

const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onMounted(() => {
	// this is needed for setting the isDesktop property to true in Storybook
	if (props.forceDesktop) {
		store.state.config.isDesktop = true;
	}

	if (isHotelOnly.value) {
		setAccommodationType(true);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "list-filters__header" }, " Suchergebnisse filtern ", -1 /* HOISTED */)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (showDealsFilter.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 0,
              title: _unref(dealsFilterTitle),
              "with-divider": false,
              class: "list-filters__deals-container"
            }, {
              default: _withCtx(() => [
                _createVNode(DealsFilter, { class: "list-filters__deals" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title"]))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, { "with-divider": showDealsFilter.value }, {
          default: _withCtx(() => [
            _createVNode(FlexFilter)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["with-divider"]),
        (!isHotelOnly.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 1,
              title: "Flug und Transport"
            }, {
              default: _withCtx(() => [
                _createVNode(DirectFlightField, { class: "list-filters__direct-flight" }),
                _createVNode(FlyingTimeFilter, { class: "list-filters__flight" }),
                _createVNode(TransferField)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(PriceSlider, {
                  "default-value": formData.value.maxPrice,
                  currency: currencyType.value
                }, null, 8 /* PROPS */, ["default-value", "currency"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, { title: "Verpflegung und Zimmer" }, {
          default: _withCtx(() => [
            _createVNode(BoardField, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__board-types"
            }, null, 8 /* PROPS */, ["is-hotel-page"]),
            _createVNode(RoomTypeField, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__room-type"
            }, null, 8 /* PROPS */, ["is-hotel-page"]),
            _createVNode(SeaViewField, { class: "list-filters__sea-view" })
          ]),
          _: 1 /* STABLE */
        }),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 3,
              title: "Hotelkategorie ab"
            }, {
              default: _withCtx(() => [
                _createVNode(HotelCategory, {
                  value: formData.value.hotelCategory,
                  "show-suns": _unref(showSuns)
                }, null, 8 /* PROPS */, ["value", "show-suns"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 4,
              title: "Weiterempfehlungsrate ab"
            }, {
              default: _withCtx(() => [
                _createVNode(RecommendationRate, {
                  value: formData.value.minMeanRecommendationRate
                }, null, 8 /* PROPS */, ["value"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 5,
              title: "Beliebteste Filter",
              collapsible: true,
              "default-open": true,
              class: "list-filters__most-popular"
            }, {
              default: _withCtx(() => [
                _createVNode(MostPopularFilters, {
                  filters: formData.value.mostPopularFilters
                }, null, 8 /* PROPS */, ["filters"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 6,
              title: "Kundenbewertungen",
              collapsible: true,
              "default-open": openAdditionalFilters.value,
              class: "list-filters__additional"
            }, {
              default: _withCtx(() => [
                _createVNode(AdditionalCheckboxFilters, {
                  filters: formData.value.additionalCheckboxFilters
                }, null, 8 /* PROPS */, ["filters"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["default-open"]))
          : _createCommentVNode("v-if", true),
        (showDestinationFilter.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 7,
              title: destinationFilterTitle.value,
              "with-divider": !isOfferList.value
            }, {
              default: _withCtx(() => [
                (destinationFilterLoading.value)
                  ? (_openBlock(), _createBlock(SkeletonLoader, {
                      key: 0,
                      class: "list-filters__geo-skeleton",
                      "loader-width": 31.8,
                      "loader-height": 7,
                      "border-radius": '1rem'
                    }))
                  : (_openBlock(), _createBlock(DestinationFilter, {
                      key: 1,
                      "destination-label": _ctx.destinationLabel,
                      "location-type": locationType.value,
                      disabled: !destinationTypes.value || destinationTypes.value.length == 0
                    }, null, 8 /* PROPS */, ["destination-label", "location-type", "disabled"]))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title", "with-divider"]))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, { title: "Veranstalter" }, {
          default: _withCtx(() => [
            (!tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(OperatorField, {
                  key: 0,
                  class: "list-filters__operator",
                  disabled: !operatorTypes.value || operatorTypes.value.length === 0
                }, null, 8 /* PROPS */, ["disabled"]))
              : _createCommentVNode("v-if", true),
            (tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(SkeletonLoader, {
                  key: 1,
                  class: "list-filters__operator-skeleton",
                  "loader-width": 31.8,
                  "loader-height": 7,
                  "border-radius": '1rem'
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}
}

})