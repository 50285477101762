<template>
	<component
		:is="iconComponent"
	>
		<slot></slot>
	</component>
</template>

<!-- If the slot is used, it is also necessary to add the slot tag to the svg, so it gets correctly passed on. -->
<!-- See star-icon.vue for reference. -->

<script lang="ts" setup>
/* eslint-disable global-require */
import { computed } from 'vue';
import { icons } from './svg';

const props = defineProps({
	name: {
		type: String,
		required: true,
		validator(value: string) {
			return Object.prototype.hasOwnProperty.call(icons, value);
		}
	}
});

const iconComponent = computed(() => icons[props.name]);

</script>
