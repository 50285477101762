<template>
	<div
		class="transfer-type"
		:class="disabled ? 'transfer-type--disabled' : ''"
	>
		<SelectField
			v-if="isDesktop"
			ref="transferSelectFieldDesktop"
			v-model="selected"
			class="transfer"
			:items="types"
			label="Transfer"
			value-prop="value"
			icon="transfer"
			:button-wording="getLocaleString('close')"
			:item-any="true"
			:disabled="disabled"
			@SelectField:Toggle="onToggle"
			@SelectField:Clear="clearInput"
		/>

		<FormField
			v-if="!isDesktop"
			:selected="fieldValue"
			label="Transfer"
			:show-modal="showModal"
			icon="transfer"
			:show-toggle-icon="false"
			:placeholder="disabled ? 'Keine Treffer' : 'Beliebig'"
			@click="toggleModal"
			@FormField:Clear="clearInput(); clearProxyInput()"
		/>

		<modal
			v-model="showModal"
			class="transfer__modal"
			title="Transfer"
			@Modal:Ok="onOk"
			@Modal:Cancel="onReset"
		>
			<SelectField
				ref="transferSelectFieldMobile"
				v-model="proxy"
				class="transfer"
				:items="types"
				:manual="true"
				:show-footer="false"
				:allow-clear="true"
				value-prop="value"
				icon="transfer"
				@SelectField:Clear="clearInput(); clearProxyInput()"
			/>
		</modal>
	</div>
</template>

<script lang="ts" setup>
import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import {
	computed, onBeforeMount, onBeforeUnmount, ref, watch,
} from 'vue';

const store = useStore();

interface Props {
	disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
});

const open = ref(false);

const showModal = ref(false);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const types = computed((): any[] => store.state.types.transfer);

const transferTypes = computed({
	get(): (string | number)[] {
		return store.state.searchMask.transferTypes;
	},
	set(value: (string | number)[]) {
		let transferType = value;
		if (transferType[0] === '') {
			transferType = [];
		}
		store.commit('searchMask/updateFormData', { transferTypes: transferType });
	}
});

const selected = ref<string | number | null>((transferTypes.value.length && transferTypes.value[0]) || null);

// TODO: CHECK FOR REFACTOR
const proxy = ref<string | number | null>(transferTypes.value.length && transferTypes.value[0]) || null;

const fieldValue = computed((): string | undefined => transferTypes.value.map((value: string | number) => types.value.find((x) => x.value === value).label).toString());

const resetInput = (): void => {
	store.dispatch('resetItem', 'transferTypes');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

const clearInput = (): void => {
	selected.value = null;
};

const clearProxyInput = (): void => {
	proxy.value = null;
};

const onToggle = (openBool: boolean): void => {
	open.value = openBool;
};

const onOk = (): void => {
	transferTypes.value = [proxy.value || ''];
	onToggle(false);
};

const onReset = (): void => {
	proxy.value = transferTypes.value[0];
	onToggle(false);
};

const toggleModal = (): void => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

watch(() => selected.value, () => {
	transferTypes.value = [selected.value || ''];
});

watch(() => transferTypes.value, () => {
	selected.value = (transferTypes.value.length && transferTypes.value[0]) || null;
});

onBeforeMount((): void => {
	if (props.disabled) resetInput();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeUnmount((): void => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});

</script>

<style lang="scss" scoped>
.transfer-type {
	.transfer__icon {
		width: 3rem;
		height: 3.5rem;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}
}

.transfer__modal {
	.transfer {
		padding: 2.5rem 1.5rem 0;
	}
}

.transfer-type--disabled {
	:deep(.form-field__icon),
	:deep(.form-field__chevron-icon) {
		fill: $color-state-disabled;
	}

	:deep(.form-field) {
		cursor: default;
		border-color: $color-state-disabled;
	}

	:deep(.form-field__label-text) {
		color: $color-state-disabled;
	}

	:deep(.form-field__input::placeholder) {
		color: $color-state-disabled !important;
	}
}

@include media-query-up($breakpoint-small) {
	.clear-icon {
		fill: $color-primary;
	}
}

@include media-query-up($breakpoint-container) {
	.transfer-type {
		::-webkit-scrollbar {
			width: 0 !important;
		}
	}
}
</style>
