import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  width: "150",
  height: "150",
  viewBox: "-50 -50 300 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "loading-spinner__svg"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { "stroke-width": "20" }
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  props: {
    size: { default: 'big' },
    uniqueKey: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const firstId = computed((): string => {
	if (props.uniqueKey) {
		return `spinner-firstHalf-${props.uniqueKey}`;
	}
	return 'spinner-firstHalf';
});

const secondId = computed((): string => {
	if (props.uniqueKey) {
		return `spinner-secondHalf-${props.uniqueKey}`;
	}
	return 'spinner-secondHalf';
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
			'big' : _ctx.size === 'big',
			'medium' : _ctx.size === 'medium',
			'small' : _ctx.size === 'small',
		}, "loading-spinner"])
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("defs", null, [
        _createElementVNode("linearGradient", { id: secondId.value }, _cache[0] || (_cache[0] = [
          _createElementVNode("stop", {
            offset: "0%",
            "stop-opacity": "0",
            "stop-color": "currentColor"
          }, null, -1 /* HOISTED */),
          _createElementVNode("stop", {
            offset: "100%",
            "stop-opacity": "0.5",
            "stop-color": "currentColor"
          }, null, -1 /* HOISTED */)
        ]), 8 /* PROPS */, _hoisted_2),
        _createElementVNode("linearGradient", { id: firstId.value }, _cache[1] || (_cache[1] = [
          _createElementVNode("stop", {
            offset: "0%",
            "stop-opacity": "1",
            "stop-color": "currentColor"
          }, null, -1 /* HOISTED */),
          _createElementVNode("stop", {
            offset: "100%",
            "stop-opacity": "0.5",
            "stop-color": "currentColor"
          }, null, -1 /* HOISTED */)
        ]), 8 /* PROPS */, _hoisted_3)
      ]),
      _createElementVNode("g", _hoisted_4, [
        _createElementVNode("path", {
          stroke: `url(#${secondId.value})`,
          d: "M 4 100 A 96 96 0 0 1 196 100"
        }, null, 8 /* PROPS */, _hoisted_5),
        _createElementVNode("path", {
          stroke: `url(#${firstId.value})`,
          d: "M 196 100 A 96 96 0 0 1 4 100"
        }, null, 8 /* PROPS */, _hoisted_6),
        _cache[2] || (_cache[2] = _createElementVNode("path", {
          stroke: "currentColor",
          "stroke-linecap": "round",
          d: "M 4 100 A 96 96 0 0 1 4 98"
        }, null, -1 /* HOISTED */))
      ])
    ]))
  ], 2 /* CLASS */))
}
}

})