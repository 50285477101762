import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "destination-field" }

import Autocomplete from '@lmt-rpb/Autocomplete/Autocomplete.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { SuggestionDataType } from '@interfaces/search-form';
import { cloneDeep } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, nextTick, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'DestinationField',
  setup(__props, { expose: __expose }) {


const store = useStore();

const modalAutocomplete = ref<InstanceType<typeof Autocomplete> | null>(null);

const showModal = ref(false);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const destination = computed({
	get() {
		return store.state.searchMask.destination;
	},
	set(value: SuggestionDataType | null) {
		store.commit('searchMask/updateFormData', {
			destination: value,
			filter: '',
		});
	}
});

const destinationValue = ref<SuggestionDataType | null>(destination.value && cloneDeep(destination.value));

const maxTravelDurationError = computed(() => store.state.searchMask.maxTravelDurationError);

const pageType = computed((): string => store.state.config.pageType);

const suggestUrl = computed((): string => {
	const { fluxApi, suggestions } = store.state.config;

	return `${fluxApi}${suggestions}`;
});

const onOk = (): void => {
	if (!maxTravelDurationError.value) {
		if (!destinationValue.value?.label) {
			modalAutocomplete.value?.selectFirst(); // FIXES ISSUE WITH NOT SELECTING THE FIRST ITEM IF ONE PRESSES OK
		}
		destination.value = destinationValue.value && cloneDeep(destinationValue.value);
	}
};

const onClose = (): void => {
	destinationValue.value = destination.value && cloneDeep(destination.value);
	modalAutocomplete.value?.resetTerm(store.state.searchMask.destination);
};

const onClear = (): void => {
	destination.value = null;
	modalAutocomplete.value?.clearInput();
};

const toggleModal = (): void => {
	if (!maxTravelDurationError.value) {
		showModal.value = !showModal.value;

		if (['regionPage', 'hotelPage'].includes(pageType.value) && showModal.value) {
			EventBus.$emit('loadDefaultAutosuggests');
		}
		nextTick(() => {
			modalAutocomplete.value?.openDropdown();
			modalAutocomplete.value?.input?.focus();
			modalAutocomplete.value?.dropdown?.onOpen('autocomplete');
		});
	}
};

watch(destination, () => {
	destinationValue.value = destination.value && cloneDeep(destination.value);
});

__expose({
	destinationValue,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(Autocomplete, {
          key: 0,
          modelValue: destination.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((destination).value = $event)),
          url: suggestUrl.value,
          inline: false,
          "error-duration": maxTravelDurationError.value,
          icon: "search",
          label: "Reiseziel, Hotel, Ort, ..."
        }, null, 8 /* PROPS */, ["modelValue", "url", "error-duration"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(FormField, {
            class: "destination-field__field",
            label: "Reiseziel, Hotel, Ort, …",
            selected: destination.value && destination.value.label,
            "show-modal": showModal.value,
            "show-toggle-icon": false,
            "allow-clear": true,
            icon: "search",
            disabled: maxTravelDurationError.value,
            onClick: toggleModal,
            "onFormField:Clear": onClear
          }, null, 8 /* PROPS */, ["selected", "show-modal", "disabled"]),
          _createVNode(Modal, {
            modelValue: showModal.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showModal).value = $event)),
            title: "Reiseziel auswählen",
            class: "destination-field__modal",
            "initial-render": !isDesktop.value,
            "onModal:Ok": onOk,
            "onModal:Cancel": onClose,
            "onModal:Close": onClose
          }, {
            default: _withCtx(() => [
              _createVNode(Autocomplete, {
                ref_key: "modalAutocomplete",
                ref: modalAutocomplete,
                modelValue: destinationValue.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((destinationValue).value = $event)),
                class: "dest-modal__autocomplete",
                url: suggestUrl.value,
                inline: true,
                label: "Reiseziel, Hotel, Ort, ..."
              }, null, 8 /* PROPS */, ["modelValue", "url"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "initial-render"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})