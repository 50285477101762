import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed } from 'vue';

interface Props {
	rating: number,
	showSuns: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelStars',
  props: {
    rating: {},
    showSuns: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const positiveRating = computed(() => Math.max(0, props?.rating ?? 0));

const count = computed(() => Math.floor(positiveRating.value));
const showHalf = computed(() => positiveRating.value % 1 !== 0);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["hotelrating", {'hotelrating__sun' :_ctx.showSuns }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(count.value, (i) => {
      return (_openBlock(), _createBlock(BaseIcon, {
        key: i,
        name: _ctx.showSuns ? 'sun' : 'star',
        class: "hotelrating__icon"
      }, {
        default: _withCtx(() => [
          _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rating), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["name"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (showHalf.value)
      ? (_openBlock(), _createBlock(BaseIcon, {
          key: 0,
          name: _ctx.showSuns ? 'halfSun' : 'halfStar',
          class: "hotelrating__icon hotelrating__icon--half"
        }, {
          default: _withCtx(() => [
            _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rating), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["name"]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}
}

})