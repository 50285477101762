<template>
	<svg
		width="24"
		height="17"
		viewBox="0 0 24 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Room Symbol</title>
		<path
			fill="#000000"
			d="M1.2 0C1.86375 0 2.4 0.53625 2.4 1.2V10.8H10.8V4.8C10.8 4.13625 11.3363 3.6 12 3.6H20.4C22.3875 3.6 24 5.2125 24 7.2V15.6C24 16.2637 23.4638 16.8 22.8 16.8C22.1363 16.8 21.6 16.2637 21.6 15.6V14.4H13.2H12H2.4V15.6C2.4 16.2637 1.86375 16.8 1.2 16.8C0.53625 16.8 0 16.2637 0 15.6V1.2C0 0.53625 0.53625 0 1.2 0ZM6.6 3.6C7.39565 3.6 8.15871 3.91607 8.72132 4.47868C9.28393 5.04129 9.6 5.80435 9.6 6.6C9.6 7.39565 9.28393 8.15871 8.72132 8.72132C8.15871 9.28393 7.39565 9.6 6.6 9.6C5.80435 9.6 5.04129 9.28393 4.47868 8.72132C3.91607 8.15871 3.6 7.39565 3.6 6.6C3.6 5.80435 3.91607 5.04129 4.47868 4.47868C5.04129 3.91607 5.80435 3.6 6.6 3.6Z"
		/>
	</svg>
</template>
