import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, mergeProps as _mergeProps, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';

interface Props {
	parentRef?: string
	progressValue?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Draggable',
  props: {
    parentRef: { default: 'dragContainer' },
    progressValue: { default: 0 }
  },
  emits: ['update:progressValue', 'Draggable:DragStart', 'Draggable:Drag', 'Draggable:DragEnd'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const model = computed({
	get() {
		if (typeof props.progressValue === 'number') {
			return props.progressValue;
		}
		return 0;
	},
	set(newValue) {
		emit('update:progressValue', newValue);
	}
});

const isDragging = ref(false);

const progress = ref(0);

const offset = ref(0);

const startX = ref(0);

const startProgress = ref(0);

const maxPos = ref(0);

const draggable = ref<HTMLElement | null>(null);

const parent = ref<HTMLElement | null>(null);

const handleStyle = computed((): Record<string, string> => {
	// edge case patch:
	// the parent width (maxPos) is not available if draggable initialized
	// when the parent element is hidden so use progress percent instead
	let position = `${progress.value}%`;
	position = maxPos.value ? `${(maxPos.value / 100) * progress.value}px` : position;
	return {
		left: position
	};
});

// drag events
const onDragStart = (x: number) => {
	startX.value = x - offset.value;
	startProgress.value = progress.value;

	emit('Draggable:DragStart');
};

const onDrag = (x: number) => {
	const deltaX = (x - startX.value);
	isDragging.value = deltaX !== 0;

	if (!isDragging.value) {
		return;
	}

	offset.value = Math.min(maxPos.value, Math.max(0, deltaX));
	progress.value = (offset.value / maxPos.value) * 100;

	emit('Draggable:Drag', progress.value);
};

const onDragEnd = () => {
	const diff = startProgress.value - progress.value;
	isDragging.value = false;

	emit('Draggable:DragEnd', diff);
};

// mouse events
const onMouseMove = (event: MouseEvent) => {
	onDrag(event.clientX);
};

const onMouseUp = () => {
	onDragEnd();

	document.removeEventListener('mousemove', onMouseMove);
	document.removeEventListener('mouseup', onMouseUp);
};

const onMouseDown = (event: MouseEvent) => {
	onDragStart(event.clientX);

	document.addEventListener('mousemove', onMouseMove);
	document.addEventListener('mouseup', onMouseUp);
};

// touch gestures
const onTouchMove = (event: TouchEvent) => {
	if (event.touches && event.touches.length > 0 && event.touches[0].clientX) {
		onDrag(event.touches[0].clientX);
	}
};

const onTouchEnd = () => {
	onDragEnd();

	document.removeEventListener('touchmove', onTouchMove);
	document.removeEventListener('touchend', onTouchEnd);
};

const onTouchStart = (event: TouchEvent) => {
	event.preventDefault();
	if (event.touches && event.touches.length > 0 && event.touches[0].clientX) {
		onDragStart(event.touches[0].clientX);

		document.addEventListener('touchmove', onTouchMove);
		document.addEventListener('touchend', onTouchEnd);
	}
};

const updateSizes = () => {
	if (!draggable.value || !parent.value) {
		return;
	}
	// offsetWidth: width + border + padding
	// clientWidth: width without border and/or padding
	const { width: handleWidth } = draggable.value.getBoundingClientRect();
	const { width: trackWidth } = parent.value.getBoundingClientRect();

	maxPos.value = trackWidth - handleWidth;
	offset.value = progress.value === 100 ? maxPos.value : (maxPos.value / 100) * progress.value;
};

const visibilityChanged = () => {
	if (!maxPos.value) {
		updateSizes();
	}
};

watch(() => model.value, () => {
	if (isDragging.value) {
		return;
	}

	progress.value = model.value;
	offset.value = (maxPos.value / 100) * progress.value;
}, { immediate: true });

// lifecycle events
onMounted(() => {
	parent.value = getCurrentInstance()?.parent?.refs[props.parentRef] as HTMLElement;
	EventBus.$on('window:resize', updateSizes);
});

onBeforeUnmount(() => {
	EventBus.$off('window:resize', updateSizes);
});


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
    ref_key: "draggable",
    ref: draggable,
    class: ["draggable", { 'is-dragging': isDragging.value }],
    type: "button",
    style: handleStyle.value
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
    onTouchstart: onTouchStart,
    onMousedown: _withModifiers(onMouseDown, ["prevent"])
  }), null, 16 /* FULL_PROPS */)), [
    [_unref(vObserveVisibility), { callback: visibilityChanged }]
  ])
}
}

})