<template>
	<div
		ref="swiperRef"
		class="hotel-image-slider"
	>
		<swiper-container
			:modules="[Navigation]"
			:navigation="{
				nextEl: `#${uniqueId}-next`,
				prevEl: `#${uniqueId}-prev`
			}"
			class="hotel-image-slider__container-image"
		>
			<swiper-slide
				v-for="(item, index) in images"
				:key="index"
				:alt="alt"
			>
				<ImageWithPlaceholder
					v-if="index === 0 && itemIndex === 0"
					class="hotel-image-slider__image"
					:src="item + '/m'"
					width="282"
					height="188"
				/>
				<ImageWithPlaceholder
					v-else
					class="hotel-image-slider__image rpb_lazy"
					:src="item + '/m'"
					width="282"
					height="188"
					loading="lazy"
				/>
			</swiper-slide>
		</swiper-container>
		<div
			:id="`${uniqueId}-prev`"
			class="hotel-image-slider__custom-prev-button"
		>
			<span><BaseIcon
				name="chevron"
				class="hotel-image-slider__chevron-icon"
			/></span>
		</div>
		<div
			:id="`${uniqueId}-next`"
			class="hotel-image-slider__custom-next-button"
		>
			<span><BaseIcon
				name="chevron"
				class="hotel-image-slider__chevron-icon"
			/></span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {
 computed, onActivated, onMounted, ref,
} from 'vue';
import { getFluxImageUrl } from '@utils/utils';
import { register } from 'swiper/element/bundle';
import { Navigation } from 'swiper/modules';
import ImageWithPlaceholder from '@lmt-rpb/ImageWithPlaceholder/ImageWithPlaceholder.vue';
import { v4 as uuidv4 } from 'uuid';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

register();

interface Props {
	iffCode: number,
	alt: string,
	numberOfImages: number;
	itemIndex?: number
}

const props = withDefaults(defineProps<Props>(), { itemIndex: -1 });

const imageUrl = getFluxImageUrl() || 'https://images.flux.reisen';

const images = computed(() => {
	const imageArray = [];

	const maxAmountofPictures = props.numberOfImages;

	for (let i = 0; i < maxAmountofPictures; i++) {
		imageArray.push(`${imageUrl}/hotel/${props.iffCode}/${i + 1}`);
	}
	return imageArray;
});

const uniqueId = CSS.escape('id-' + uuidv4());
const swiperRef = ref(null);

onActivated(() => {
	if (swiperRef.value) {
		const swiperEl = swiperRef.value.querySelector('swiper-container');
		if (swiperEl) {
			swiperEl.initialize();
		}
	}
});
</script>

<style lang="scss">
 
.hotel-image-slider {
	position: relative;

	&__container-image {
		border-top-left-radius: $border-radius-medium;
		border-top-right-radius: $border-radius-medium;
		width: 28.2rem;
		background: white;
		height: 18.8rem;
	}

	&__image {
		font-size: 0;
		overflow: hidden;
		position: relative;
		width: 28.2rem;
	}

	&__custom-prev-button,
	&__custom-next-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: hsl(0deg 0% 100% / 50%);
		border: none;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		height: 4rem;
		z-index: 5;
		cursor: pointer;

		span {
			display: contents;

			svg path {
				fill: $color-black;
			}
		}
	}

	&__custom-prev-button {
		left: 10px;
	}

	&__custom-next-button {
		right: 10px;
	}

	&__chevron-icon {
		height: 2rem;
		width: 2rem;
	}

	&__custom-next-button .hotel-image-slider__chevron-icon {
		transform: rotate(90deg);
	}

	&__custom-prev-button .hotel-image-slider__chevron-icon {
		transform: rotate(-90deg);
	}
}

</style>
