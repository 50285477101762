import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "aria-disabled"]

import { computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props{
	direction: string,
	disabled: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Pager',
  props: {
    direction: { default: 'left' || 'right' },
    disabled: { type: Boolean, default: false }
  },
  emits: ['slide'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const axis = computed((): number => (props.direction === 'left' ? -1 : 1));

const pagerClass = computed((): string => (props.direction === 'left' ? 'calendar-pager--revert' : ''));

const slide = () => {
	emit('slide', axis.value);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["calendar-pager", pagerClass.value]),
    disabled: _ctx.disabled,
    "aria-disabled": _ctx.disabled,
    tabindex: "-1",
    onClick: slide
  }, [
    _createVNode(BaseIcon, {
      name: _ctx.direction === 'left' ? 'chevronLeft' : 'chevronRight',
      class: "calendar-pager__icon"
    }, null, 8 /* PROPS */, ["name"])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})