// analytic-utils.ts
import { useStore } from '@/components/common/store';
import { SearchFormDataType } from '@/interfaces/search-form';

const store = useStore();

export const determineTravellerType = (submitData: SearchFormDataType): string => {
	const { adult, children } = submitData.travelers;

	if (children.length > 0) {
		return 'Familien';
	}
	if (adult === 2) {
		return 'Paare';
	}
	if (adult === 1) {
		return 'Singles';
	}
	if (adult > 2) {
		return 'Gruppen';
	}
	return 'not_set';
};

const getAnalyticsDimensions = (offer: { StartDate?: string, EndDate?: string }) => {
	const { StartDate, EndDate } = offer || {};
	const submitData = store.getters['searchMask/submitData'];
	const travellerType = determineTravellerType(submitData);
	if (StartDate && EndDate) {
		return {
			departure_date: new Date(StartDate).getTime(),
			return_date: new Date(EndDate).getTime(),
			traveller_type: travellerType
		};
	}
	return {};
};

export const customAnalyticsDimensions = () => getAnalyticsDimensions(store.state.bestOffer || {});

export const customAnalyticsDimensionsCheckout = () => getAnalyticsDimensions(store.state.checkout.OfferData.Offer || {});
