export default [
	{
		Code: '1',
		Name: 'Flexible Stornierung & Umbuchung',
		Tooltip: 'Angebote, die günstig oder kostenlos stornierbar und umbuchbar sind oder bei denen ein solcher flexibler Tarif zubuchbar ist.'
	},
	{
		Code: '2',
		Name: 'Kostenlose Umbuchung',
		Tooltip: 'Angebote, die kostenlos umgebucht werden können.'
	},
];
