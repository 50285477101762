import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "checkout-hotel-info-gallery" }
const _hoisted_2 = ["data-src", "alt", "onClick"]

import { Carousel, Slide, Navigation as CarouselNavigation } from 'vue3-carousel';
import GalleryModal from '@lmt-rpb/GalleryModal/GalleryModal.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { HotelCatalogueImageType } from '@/interfaces/hotel-catalogue-types';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutHotelInfoGallery',
  props: {
    images: {},
    client: { default: 'at' }
  },
  setup(__props: any) {

const props = __props;

const scroll = ref();

const isMouseDown = ref(false);

const isDragging = ref(false);

const isModalVisible = ref(false);

const modalIndex = ref(0);

const carouselSettings = {
	itemsToShow: 1.5,
	itemsToSlide: 1,
	snapAlign: 'start',
	breakpoints: {
		320: {
			itemsToShow: 1,
		},
		420: {
			itemsToShow: 1.5,
		},
		768: {
			itemsToShow: 2,
		},
		1200: {
			itemsToShow: 2.5,
		},
		1300: {
			itemsToShow: 1.5,
		}
	}
};

const imageArray = computed((): HotelCatalogueImageType[] => {
	const imagesArr: HotelCatalogueImageType[] = [];
	const imagesObj = props.images;

	Object.entries(imagesObj).map((e) => (imagesArr.push(e[1] as HotelCatalogueImageType)));

	return imagesArr;
});

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (): void => {
	setTimeout(() => {
		isMouseDown.value = false;
		isDragging.value = false;
	}, 300); // click tilt time
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const openGallery = (index: number): void => {
	if (isDragging.value) {
		return;
	}

	modalIndex.value = Number(index) - 1;
	isModalVisible.value = true;
};

const closeGallery = (): void => {
	if (isDragging.value) {
		return;
	}

	isModalVisible.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Carousel), _mergeProps({
      ref_key: "scroll",
      ref: scroll
    }, carouselSettings, { class: "checkout-hotel-info-gallery__carousel" }), {
      addons: _withCtx(() => [
        _createVNode(_unref(CarouselNavigation), null, {
          prev: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "checkout-hotel-info-gallery__aria-label" }, "prev", -1 /* HOISTED */)),
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "checkout-hotel-info-gallery__prev-icon"
            })
          ]),
          next: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "checkout-hotel-info-gallery__aria-label" }, "next", -1 /* HOISTED */)),
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "checkout-hotel-info-gallery__next-icon"
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
          return (_openBlock(), _createBlock(_unref(Slide), {
            key: index,
            index: index,
            class: "checkout-hotel-info-gallery__slide"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "checkout-hotel-info-gallery__image rpb_lazy",
                "data-sizes": "auto",
                "data-src": image.URL,
                src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
                alt: `${image.Caption} - ${index}`,
                onClick: ($event: any) => (openGallery(index)),
                onMousedown: onMouseDown,
                onMouseup: onMouseUp,
                onMousemove: onMouseMove
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 16 /* FULL_PROPS */),
    _createVNode(GalleryModal, {
      modelValue: isModalVisible.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isModalVisible).value = $event)),
      images: imageArray.value,
      index: modalIndex.value,
      client: _ctx.client,
      onClose: closeGallery
    }, null, 8 /* PROPS */, ["modelValue", "images", "index", "client"])
  ]))
}
}

})