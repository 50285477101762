<template>
	<div class="dropdown-filter-type">
		<DropdownField
			v-if="isDesktop"
			ref="desktopField"
			class="dropdown-filter-type__desktop"
			:model-value="fieldValue"
			:label="title"
			:placeholder="actualPlaceholder"
			:icon
			:show-footer="showButtons"
			:button-wording="applyButtonText ?? getLocaleString('close')"
			:disabled="disabled"
			:readonly="!searchable || !dropdownOpen"
			:with-clear-button="withClearButton"
			:button-disabled="buttonDisabled"
			:searchable
			@update:model-value="handleUpdate"
			@DropdownField:Ok="handleOk"
			@DropdownField:OutsideClick="handleOutsideClick"
			@DropdownField:open="handleUpdate('')"
			@DropdownField:Toggle="handleToggle"
			@DropdownField:Clear="handleClear"
		>
			<slot
				:close="close"
				:open="openModal"
			></slot>
		</DropdownField>
		<div
			v-else
			class="dropdown-filter-type__mobile"
		>
			<div
				v-if="withMenuHeader"
				class="dropdown-filter-type__mobile-header"
			>
				{{ title }}
			</div>
			<FormField
				class="dropdown-filter-type__mobile-form-field"
				:selected="selectedValue"
				:show-modal="true"
				:label="title"
				:icon
				:placeholder
				:disabled="disabled"
				@click="openModal"
			></FormField>
			<SearchModal
				ref="modal"
				v-model="searchTerm"
				class="dropdown-filter-type__mobile-modal"
				:header="modalHeader"
				:cancel-button="showButtons ? (cancelButtonText ?? 'Abbrechen') : ''"
				:apply-button="showButtons ? (applyButtonText ?? 'Ok') : ''"
				:searchable="searchable"
				:with-clear-button="withClearButton"
				input-clearable
				@SearchModal:applyChanges="handleApply"
				@SearchModal:close="handleAbort"
				@SearchModal:clear="handleClear"
			>
				<slot
					:close="close"
					:open="openModal"
				></slot>
			</SearchModal>
		</div>
	</div>
</template>
<script setup lang="ts">
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import { useStore } from '@/components/common/store';
import { computed, ref } from 'vue';
import { getLocaleString } from '@utils/environmentUtils';

type Props = {
	title: string
	modalTitle?: string
	icon: string
	showButtons?: boolean
	placeholder?: string
	selectedValue?: string
	disabled?: boolean
	buttonDisabled?: boolean
	searchable?: boolean
	emitOnOk?: boolean
	withClearButton?: boolean
	applyButtonText?: string
	cancelButtonText?: string
	searchTerm?: string
	withMenuHeader?: boolean
}

const store = useStore();

const props = withDefaults(
	defineProps<Props>(),
	{
		showButtons: false,
		placeholder: 'Beliebig',
		modalTitle: undefined,
		selectedValue: undefined,
		applyButtonText: undefined,
		cancelButtonText: undefined,
		emitOnOk: false,
		withClearButton: false,
		searchTerm: undefined,
		withMenuHeader: true
	}
);

const emit = defineEmits(['DropdownFilterType:apply', 'DropdownFilterType:abort', 'DropdownFilterType:filter', 'DropdownFilterType:clear', 'update:searchTerm']);

const isDesktop = computed(() => store.state.config.isDesktop);
const desktopField = ref<null | InstanceType<typeof DropdownField>>(null);

const searchTerm = computed({
	get() {
		return props.searchTerm ?? '';
	},
	set(value: string) {
		emit('update:searchTerm', value);
	}
});
const fieldValue = computed(() => {
	if (!props.searchable) {
		return props.selectedValue;
	}
	return dropdownOpen.value ? searchTerm.value : props.selectedValue;
});

const modal = ref<null | InstanceType<typeof SearchModal>>(null);
const modalHeader = computed(() => `${props.modalTitle ?? props.title} auswählen`);
const openModal = () => {
	if (props.disabled) return;
	modal.value?.openModal();
	searchTerm.value = '';
};

const close = () => {
	desktopField.value?.closeDropdown();
	modal.value?.handleClose();
};

const dropdownOpen = ref(false);
const handleToggle = (isOpen: boolean) => {
	dropdownOpen.value = isOpen;
};
const actualPlaceholder = computed(() => {
	if (!props.searchable) {
		return props.placeholder;
	}
	return dropdownOpen.value ? 'Suchen' : props.placeholder;
});

const handleUpdate = (value: string) => {
	if (!props.searchable) {
		return;
	}
	searchTerm.value = value;
};

const handleOk = (e) => {
	if (props.emitOnOk) {
		emit('DropdownFilterType:apply', e);
	}
	close();
};

const handleOutsideClick = (e) => {
	emit('DropdownFilterType:abort', e);
	close();
};

const handleApply = (e) => {
	emit('DropdownFilterType:apply', e);
};
const handleAbort = (e) => {
	emit('DropdownFilterType:abort', e);
};
const handleClear = () => {
	emit('DropdownFilterType:clear');
};

defineExpose({
	close,
	open: openModal
});

</script>
<style lang="scss" scoped>
.dropdown-filter-type {
	&__mobile-header {
		background-color: $header-bar-bg;
		color: $color-filter-type-header-text;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		padding: 1.2rem 2.4rem;
		box-shadow: 0 0.4rem 0.6rem 0 #00000029;
	}

	&__mobile-form-field {
		margin: 1.6rem 0.5rem;
		width: auto;
	}

	:deep(.search-modal__button) {
		font-weight: $font-weight-bold;
	}

	:deep(.search-modal__button--cancel) {
		height: auto;
	}

	:deep(.search-modal__button--apply) {
		height: auto;
		background-color: $color-primary;
	}

	:deep(.dropdown.is-open .dropdown__box) {
		max-height: 45rem !important;
	}

	:deep(.dropdown__box) {
		transform: none;
		left: 0;
	}

	:deep(.dropdown__container) {
		@include customScrollbar($color-primary);

		& {
			overflow: hidden auto;
		}
	}

	:deep(.search-modal__main) {
		@include customScrollbar($color-primary);

		& {
			overflow-x: hidden;
		}
	}
}
</style>
