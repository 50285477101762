import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flight-details__header" }
const _hoisted_2 = { class: "flight-details__headline" }
const _hoisted_3 = { class: "flight-details__sub-headline" }
const _hoisted_4 = { key: 0 }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import CheckoutFlightDetailsSegment from '@lmt-rpb/CheckoutFlightDetailsSegment/CheckoutFlightDetailsSegment.vue';
import { computed } from 'vue';
import { FlightSegment } from '@/interfaces/checkout/offer-data';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetailsSegmentList',
  props: {
    isOutbound: { type: Boolean },
    flights: {}
  },
  setup(__props: any) {

const props = __props;

const stopover = computed((): boolean => props.flights.length > 1);

const flightWording = computed((): string => (props.isOutbound ? 'Hinflug' : 'Rückflug'));

const iconClass = computed((): string => {
	const baseClass = 'flight-details__';

	return props.isOutbound ? `${baseClass}outbound-icon` : `${baseClass}inbound-icon`;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(BaseIcon, {
        name: "planeGo",
        class: _normalizeClass(iconClass.value)
      }, null, 8 /* PROPS */, ["class"]),
      _createElementVNode("h3", _hoisted_2, _toDisplayString(flightWording.value), 1 /* TEXT */)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flights, (segment, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${_ctx.isOutbound ? 'out' : 'in'}-${index}`
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createTextVNode(" Ihre voraussichtlichen Flugzeiten (")),
          (stopover.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(index + 1) + ".", 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createTextVNode(_toDisplayString(flightWording.value) + ") ", 1 /* TEXT */)
        ]),
        _createVNode(CheckoutFlightDetailsSegment, {
          segment: segment,
          "is-right": !_ctx.isOutbound
        }, null, 8 /* PROPS */, ["segment", "is-right"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})