import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-container__header"
}
const _hoisted_2 = {
  key: 0,
  class: "filter-container__header"
}

import Collapse from '@lmt-rpb/Collapse/Collapse.vue';

type Props = {
	title?: string
	collapsible?: boolean
	withDivider?: boolean
	defaultOpen?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterContainer',
  props: {
    title: { default: undefined },
    collapsible: { type: Boolean, default: false },
    withDivider: { type: Boolean, default: true },
    defaultOpen: { type: Boolean, default: false }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-container", { 'filter-container--with-divider': _ctx.withDivider }])
  }, [
    (_ctx.collapsible)
      ? (_openBlock(), _createBlock(Collapse, {
          key: 0,
          class: "filter-container__collapse",
          open: _ctx.defaultOpen
        }, {
          header: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["open"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _renderSlot(_ctx.$slots, "default")
        ], 64 /* STABLE_FRAGMENT */))
  ], 2 /* CLASS */))
}
}

})