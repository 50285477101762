import { filterObjectArray } from '@/js/utils/object-utils';
import { computed, ref, Ref } from 'vue';
import { Primitive } from 'zod';

export function useFilterArray<T extends Record<string, Primitive>>(
	items: Ref<Array<T>>,
	fieldsToFilterBy: Array<keyof T>,
) {
	const filterExpression = ref('');
	const updateFilterExpression = (value: string) => {
		filterExpression.value = value;
	};

	const filteredItems = computed(() => items.value.filter(
		(item) => filterObjectArray(item, fieldsToFilterBy, filterExpression.value)
	));

	const notFoundMessage = computed(() => (filteredItems.value.length === 0 ? 'Für deine Suche gibt es leider keine passenden Filter.' : ''));

	return {
		filterExpression,
		filteredItems,
		notFoundMessage,
		updateFilterExpression
	};
}
