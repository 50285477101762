<template>
	<div
		class="range"
		aria-live="polite"
		:class="{'is-inactive': inactive}"
	>
		<span class="range__helper">{{ minLabel }}</span>
		<div
			ref="dragContainer"
			class="range__slider"
			@click.prevent="onClick"
			@touchstart="onTap"
		>
			<draggable
				v-model:progress-value="progress"
				class="range__handler"
				tabindex="0"
				role="slider"
				aria-label="Slider Handle"
				:aria-valuemin="min"
				:aria-valuemax="max"
				:aria-valuenow="count"
				@keydown.left="decrease"
				@keydown.right="increase"
				@Draggable:DragStart="onDragStart"
				@Draggable:Drag="onDrag($event)"
				@Draggable:DragEnd="onDragEnd"
			/>
			<div
				ref="track"
				class="range__track"
				:style="trackStyle"
			></div>
		</div>
		<span class="range__helper">{{ maxLabel }}</span>
	</div>
</template>

<script lang="ts" setup>
import Draggable from '@lmt-rpb/Draggable/Draggable.vue';
import {
	computed, getCurrentInstance, onMounted, ref, watch,
} from 'vue';

interface Props {
	defaultValue?: number,
	min?: number,
	max?: number,
	minLabel?: string
	maxLabel?: string,
	inactive?: boolean,
	modelValue: number | undefined,
}

const props = withDefaults(defineProps<Props>(), {
	defaultValue: 5,
	min: 1,
	max: 16,
	minLabel: '',
	maxLabel: '',
	inactive: false,
});

const emit = defineEmits(['update:modelValue', 'RangeSlider:DragStart', 'RangeSlider:DragEnd', 'RangeSlider:Select']);

const progress = ref(0);

const dragging = ref(false);

const trackStyle = computed((): Record<string, any> => ({
	width: `${progress.value}%`
}));

const dragContainer = ref<HTMLElement>();

const count = computed((): number => {
	const num = Math.round(((props.max - props.min) / 100) * progress.value);
	return Math.max(Math.min(props.min + num, props.max), props.min);
});

const onDragStart = () => {
	dragging.value = true;
	emit('RangeSlider:DragStart');
};

const onDrag = (progressNum: number): void => {
	progress.value = progressNum;
	emit('RangeSlider:DragStart');
};

const onDragEnd = () => {
	dragging.value = false;
	emit('RangeSlider:DragEnd');
};

const valueInPercent = (val: number): number => (100 / (props.max - props.min)) * (val - props.min);

const setPositionByTap = (x: number) => {
	if (!dragContainer.value) return;
	const { width, left } = dragContainer.value.getBoundingClientRect();
	const posInPx = x - left;
	progress.value = (posInPx / width) * 100;
	emit('RangeSlider:Select', count.value);
};

const onClick = (event: MouseEvent) => {
	setPositionByTap(event.clientX);
};

const onTap = (event: TouchEvent) => {
	setPositionByTap(event.touches[0].clientX);
};

const increase = () => {
	const updated = Math.max(Math.min(count.value + 1, props.max), props.min);
	emit('update:modelValue', updated);
};

const decrease = () => {
	const updated = Math.max(Math.min(count.value - 1, props.max), props.min);
	emit('update:modelValue', updated);
};

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

onMounted(() => {
	emit('update:modelValue', model.value || props.defaultValue);
	dragContainer.value = getCurrentInstance()?.refs.dragContainer as HTMLElement;
});

watch(() => model.value, () => {
	progress.value = valueInPercent(model.value);
}, { immediate: true });

watch(() => progress.value, () => {
	emit('update:modelValue', count.value);
}, { immediate: true });

</script>

<style lang="scss" scoped>
$draggable-size: 3.5rem;
$track-size: 1rem;

.range {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.range__helper {
		margin: 0;
		color: $color-placeholder-text;
		font-size: $font-medium-3;
	}

	.range__slider {
		position: relative;
		width: calc(100% - 1rem);
		border-radius: $track-size;
		background: $color-black-t3;
	}

	.range__handler {
		position: absolute;
		top: calc(#{$track-size} / 2);
		left: 0;
		width: $draggable-size;
		height: $draggable-size;
		margin-top: calc(#{$draggable-size} / -2);
		border: 0.3rem solid $color-primary;
		border-radius: 50%;
		background: $color-white;

		&:active {
			border-color: $color-white;
			background: $color-primary-l2;
		}

		&:hover,
		&:focus {
			background: $color-primary-l6;
		}
	}

	.range__track {
		width: 100%;
		height: $track-size;
		border-radius: $track-size 0 0 $track-size;
		background: $price-slider-track-bg;
	}

	&.is-inactive {
		.range__helper {
			color: $color-black-t3;
		}

		.range__handler {
			border-color: $color-state-disabled;

			&:hover {
				background: $color-light-gray;
			}
		}

		.range__slider {
			background: $color-black-t4;
		}

		.range__track {
			background: $color-state-disabled;
		}
	}
}
</style>
