// eslint-disable-next-line arrow-body-style
export const rangeArray = (min: number, max: number): number[] => {
    return [...Array((max + 1) - min)].map((_: number, index: number) => min + index);
};

export const arraySum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

/** *
 * Splits an array in n parts. The integer remainder is distributed evenly among the first n parts.
 * */
export function chunkArray<T>(arr: T[], n: number): T[][] {
    const chunkLength = Math.floor(arr.length / n);
    const remainder = arr.length % n;
    const chunks: T[][] = [];
    let start = 0;

    for (let i = 0; i < n; i++) {
        const extra = i < remainder ? 1 : 0;
        const end = start + chunkLength + extra;
        chunks.push(arr.slice(start, end));
        start = end;
    }
    return chunks;
}
