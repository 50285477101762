import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

interface Props {
	borderRadius?: string
	loaderWidth: number,
	loaderHeight: number,
	widthUnit?: 'rem' | '%';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SkeletonLoader',
  props: {
    borderRadius: { default: '' },
    loaderWidth: {},
    loaderHeight: {},
    widthUnit: { default: 'rem' }
  },
  setup(__props: any) {

const props = __props;
const styling = computed(() => {
	if (!props.loaderWidth || !props.loaderHeight) {
		return '';
	}

	return {
		'border-radius': `${props.borderRadius}`,
		width: `${props.loaderWidth}${props.widthUnit}`,
		height: `${props.loaderHeight}rem`
	};
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "skeleton-loader animation--wave",
    style: _normalizeStyle(styling.value)
  }, null, 4 /* STYLE */))
}
}

})