<template>
	<component
		:is="label ? 'label' : 'span'"
		class="checkbox"
		:class="{'checkbox--checked': isChecked, 'checkbox--disabled': disabled}"
	>
		<input
			v-model="model"
			:disabled="disabled"
			type="checkbox"
			class="checkbox__input"
			role="checkbox"
			:aria-checked="isChecked"
			:aria-label="label"
			:value="customValue"
		/>
		<span class="checkbox__label">
			<base-icon
				class="checkbox__icon"
				name="check"
			/>
			<slot>{{ label }}</slot>
		</span>
	</component>
</template>

<script lang="ts" setup generic="T">
import { computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	disabled?: boolean,
	label?: string,
	modelValue: boolean | T[],
	customValue?: T,
}

const props = withDefaults(defineProps<Props>(), {
	disabled: false,
	modelValue: false,
	customValue: undefined,
	label: ''
});

const emit = defineEmits<{(event: 'update:modelValue', payload: boolean | T[]): void;}>();

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const isChecked = computed(() => {
	if (Array.isArray(model.value)) {
		return model.value.includes(props.customValue as T);
	}
	return !!model.value || false;
});
</script>

<style lang="scss" scoped>
.checkbox {
	position: relative;
	font-size: $font-small-1;
	font-weight: 200;

	.checkbox__label {
		display: inline-block;
		position: relative;
		padding-left: 2.3em;
		border: none;
		background: none;
		color: $color-black;
		vertical-align: middle;
		cursor: pointer;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			width: 1.5em;
			height: 1.5em;
			transform: translateY(-50%);
			border: 0.2rem solid $color-border;
			border-radius: 0.5rem;
			background: $color-white;
		}
	}

	.checkbox__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0.5rem;
		width: 1.5rem;
		height: 1.5rem;
		display: none;
		z-index: 1;
		cursor: pointer;
		fill: $color-filter-radio-button;
	}

	&--disabled {
		.checkbox__label {
			color: $color-inactive;
			cursor: not-allowed;

			&::before {
				border-color: $color-inactive;
				cursor: not-allowed;
			}
		}

		.checkbox__icon {
			fill: $color-placeholder-text;
		}
	}

	.checkbox__input {
		position: absolute;
		z-index: -1;
		opacity: 0;

		&:focus + .checkbox__label::before {
			box-shadow: 0 0 0 0.1rem $color-primary-l1;
		}
	}
}

.checkbox--checked .checkbox__icon {
	display: block;
}

</style>
