<template>
	<svg width="20" height="31" viewBox="0 0 20 31" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path id="Path_8" d="M9.99954 24.0364L2.94513 30.8221L2.85705 11.5542L17.0547 11.5364L17.1428 30.8221L9.99954 24.0364Z" fill="#2ccbdf"/>
		<ellipse cx="10" cy="10.1083" rx="10" ry="10" fill="#a01b79"/>
		<path d="M15.6597 7.46447C15.6614 7.55343 15.6452 7.64182 15.6121 7.7244C15.579 7.80698 15.5296 7.88206 15.4669 7.94519L9.55258 14.0302C9.49191 14.0929 9.41926 14.1427 9.33895 14.1767C9.25864 14.2107 9.17231 14.2283 9.08508 14.2283C8.99786 14.2283 8.91153 14.2107 8.83122 14.1767C8.75091 14.1427 8.67826 14.0929 8.61758 14.0302L5.19259 10.5066C5.06896 10.377 5 10.2047 5 10.0255C5 9.8464 5.06896 9.67413 5.19259 9.54447L6.12759 8.58233C6.18826 8.51966 6.26091 8.46984 6.34122 8.43581C6.42153 8.40178 6.50786 8.38424 6.59508 8.38424C6.68231 8.38424 6.76864 8.40178 6.84895 8.43581C6.92926 8.46984 7.00191 8.51966 7.06258 8.58233L9.08473 10.6695L13.599 6.0209C13.6597 5.95824 13.7323 5.90841 13.8126 5.87438C13.893 5.84035 13.9793 5.82281 14.0665 5.82281C14.1537 5.82281 14.2401 5.84035 14.3204 5.87438C14.4007 5.90841 14.4733 5.95824 14.534 6.0209L15.4697 6.98304C15.532 7.04649 15.5809 7.1218 15.6135 7.20449C15.6462 7.28719 15.6619 7.37559 15.6597 7.46447Z" fill="white"/>
	</svg>
</template>
<style scoped lang="scss">

	#Path_8 {
		fill: $color-checkout-small-best-price-warranty-ribbon;
	}

	ellipse {
		fill: $color-checkout-small-best-price-warranty-circle;
	}
</style>