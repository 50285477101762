import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flight-details" }
const _hoisted_2 = { class: "flight-details__wrap" }

import CheckoutFlightDetailsSegmentList from '@lmt-rpb/CheckoutFlightDetailsSegmentList/CheckoutFlightDetailsSegmentList.vue';
import { FlightSegment } from '@/interfaces/checkout/offer-data';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutFlightDetails',
  props: {
    inboundFlight: {},
    outboundFlight: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CheckoutFlightDetailsSegmentList, {
        flights: _ctx.outboundFlight,
        "is-outbound": true
      }, null, 8 /* PROPS */, ["flights"]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flight-details__divider-segments" }, null, -1 /* HOISTED */)),
      _createVNode(CheckoutFlightDetailsSegmentList, {
        flights: _ctx.inboundFlight,
        "is-outbound": false
      }, null, 8 /* PROPS */, ["flights"])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flight-details__divider-disclaimer" }, null, -1 /* HOISTED */)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flight-details__legal-text" }, " Der Reiseveranstalter behält sich Änderungen der geplanten Flugzeiten im Rahmen der gesetzlichen und vertraglichen Möglichkeiten vor. ", -1 /* HOISTED */))
  ]))
}
}

})