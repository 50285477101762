import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "map-preview-and-modal"
}
const _hoisted_2 = {
  class: "map-preview-and-modal__fullscreen-btn",
  "aria-label": "Vollbild-Karte anzeigen"
}
const _hoisted_3 = {
  id: "static-map-container",
  class: "map-preview-and-modal__static-map"
}
const _hoisted_4 = ["data-srcset"]
const _hoisted_5 = ["media", "data-srcset"]
const _hoisted_6 = ["data-srcset"]
const _hoisted_7 = ["ga-data-event_label", "data-src", "alt"]
const _hoisted_8 = {
  key: 1,
  class: "map-preview-and-modal__place-holder"
}
const _hoisted_9 = {
  id: "map-usercentrics",
  class: "map-preview-and-modal__place-holder"
}
const _hoisted_10 = {
  key: 2,
  class: "map-preview-and-modal__note"
}

import {
	ref, onMounted, onUnmounted, Ref, reactive, onBeforeMount,
} from 'vue';
import { StaticMaps, MarkerData } from '@interfaces/google-maps';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import MapWithOffers from '@lmt-rpb/MapWithOffers/MapWithOffers.vue';
import Modal from '@/js/modules/modal';
import UserCentrics from '@/js/modules/UserCentrics';
import { ApiDataTypes } from '@/components/common/types';
import { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';

interface Props {
	title: string,
	lat: number,
	lng: number,
	zoom?: number,
	staticMaps: StaticMaps,
	mapsKey: string,
	marker?: boolean,
	customMarkerData?: Array<MarkerData>,
	gaEventLabel?: string,
	showNote?: boolean,
	northwest: {lat: number, lng: number} | null,
	southeast: {lat: number, lng: number} | null,
	hotelRequestBody: ApiDataTypes
	hotelToShow?: ItemType | null
	hotelToShowIffCode?: number
	isHotelPage?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapPreviewAndModal',
  props: {
    title: {},
    lat: {},
    lng: {},
    zoom: { default: 11 },
    staticMaps: {},
    mapsKey: {},
    marker: { type: Boolean, default: true },
    customMarkerData: { default: () => [] },
    gaEventLabel: { default: 'Hotelseite' },
    showNote: { type: Boolean, default: true },
    northwest: {},
    southeast: {},
    hotelRequestBody: {},
    hotelToShow: { default: undefined },
    hotelToShowIffCode: { default: undefined },
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any) {

/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */


const mapModalDiv = ref<HTMLDialogElement>();

const modal: Ref<Modal| null> = ref(null);

const userCentrics = reactive(new UserCentrics('Google Maps'));

const modalOpened = ref(false);

const mapWithOffers: Ref<InstanceType<typeof MapWithOffers> | null> = ref(null);

const loadFullscreen = async() => {
	modal.value?.openModal();
	if (modalOpened.value) {
		mapWithOffers.value?.openDefaultInfoWindow();
	}
	modalOpened.value = true;
};

function handleUserCentrics() {
	userCentrics.init();
	userCentrics.blockElement({ S1pcEj_jZX: '#map-usercentrics' });
}

onBeforeMount(() => {
	handleUserCentrics();
});

onMounted(() => {
	if (mapModalDiv.value) {
		modal.value = new Modal(mapModalDiv.value);
	}
});

onUnmounted(() => {
	modal.value?.closeModal();
});


return (_ctx: any,_cache: any) => {
  return (_ctx.mapsKey)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("dialog", {
          ref_key: "mapModalDiv",
          ref: mapModalDiv,
          class: "map-preview-and-modal__modal"
        }, [
          (modalOpened.value && userCentrics.consentGiven)
            ? (_openBlock(), _createBlock(MapWithOffers, {
                key: 0,
                id: _ctx.title,
                ref_key: "mapWithOffers",
                ref: mapWithOffers,
                lat: _ctx.lat,
                lng: _ctx.lng,
                northwest: _ctx.northwest,
                southeast: _ctx.southeast,
                "hotel-request-body": _ctx.hotelRequestBody,
                "is-hotel-page": _ctx.isHotelPage,
                "hotel-to-show-iff-code": _ctx.hotelToShowIffCode,
                "hotel-to-show": _ctx.hotelToShow
              }, null, 8 /* PROPS */, ["id", "lat", "lng", "northwest", "southeast", "hotel-request-body", "is-hotel-page", "hotel-to-show-iff-code", "hotel-to-show"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            class: "map-preview-and-modal__close-btn",
            "aria-label": "Vollbild-Karte schließen",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (modal.value?.closeModal()), ["prevent"]))
          }, [
            _createVNode(BaseIcon, {
              name: "close",
              class: "map-preview-and-modal__close-icon"
            })
          ])
        ], 512 /* NEED_PATCH */),
        (userCentrics.consentGiven)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "map-usercentrics",
              class: "map-preview-and-modal__preview",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (loadFullscreen()), ["prevent"]))
            }, [
              _createElementVNode("button", _hoisted_2, [
                _createVNode(BaseIcon, {
                  name: "fullscreen",
                  class: "map-preview-and-modal__fullscreen-icon"
                })
              ]),
              _createElementVNode("picture", _hoisted_3, [
                (_ctx.staticMaps.staticgmap_992)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 0,
                      media: "(min-width: 544px)",
                      "data-srcset": _ctx.staticMaps.staticgmap_992
                    }, null, 8 /* PROPS */, _hoisted_4))
                  : _createCommentVNode("v-if", true),
                (_ctx.staticMaps.staticgmap_544)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 1,
                      media: _ctx.staticMaps.staticgmap_420 ? '(min-width: 420px)' : '',
                      "data-srcset": _ctx.staticMaps.staticgmap_544
                    }, null, 8 /* PROPS */, _hoisted_5))
                  : _createCommentVNode("v-if", true),
                (_ctx.staticMaps.staticgmap_420)
                  ? (_openBlock(), _createElementBlock("source", {
                      key: 2,
                      "data-srcset": _ctx.staticMaps.staticgmap_420
                    }, null, 8 /* PROPS */, _hoisted_6))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("img", {
                  "ga-data-event_category": "UX",
                  "ga-data-event_action": "Map",
                  "ga-data-event_label": _ctx.gaEventLabel,
                  "data-src": _ctx.staticMaps.staticgmap_544 || _ctx.staticMaps.staticgmap_420,
                  src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
                  alt: `Google Map von ${_ctx.title}`,
                  class: "rpb_lazy map-preview-and-modal__static-map-img"
                }, null, 8 /* PROPS */, _hoisted_7)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(Loading, { class: "map-preview-and-modal__loading" }),
                _createCommentVNode(" User Centrics replace Container")
              ])
            ])),
        (_ctx.showNote)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(Positionsangaben ohne Gewähr)"))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}
}

})