import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["loading", "data-src", "src", "width", "height", "alt"]
const _hoisted_2 = ["width", "height", "alt"]

import {
	computed, Ref, ref, useAttrs,
} from 'vue';

type Props = {
	src: string
	width?: number | string
	height?: number | string
	alt?: string
	loading?: 'lazy' | 'eager'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageWithPlaceholder',
  props: {
    src: {},
    width: { default: 282 },
    height: { default: 188 },
    alt: { default: undefined },
    loading: { default: undefined }
  },
  setup(__props: any) {



const attrs = useAttrs();

const classList = computed(() => attrs.class);

const imageRef: Ref<HTMLImageElement | null> = ref(null);

const loaded = ref(false);
const onLoad = () => {
	loaded.value = true;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", _mergeProps({
      ref_key: "imageRef",
      ref: imageRef
    }, _ctx.$attrs, {
      loading: _ctx.loading,
      "data-src": _ctx.src,
      src: _ctx.src,
      width: _ctx.width,
      height: _ctx.height,
      alt: _ctx.alt,
      class: {'not-loaded': !loaded.value},
      onLoad: onLoad
    }), null, 16 /* FULL_PROPS */, _hoisted_1),
    (!loaded.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
          class: _normalizeClass(classList.value),
          width: _ctx.width,
          height: _ctx.height,
          alt: _ctx.alt
        }, null, 10 /* CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})