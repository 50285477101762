import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rating"
}
const _hoisted_2 = {
  key: 0,
  class: "rating__icon rating__icon--half",
  viewBox: "0 0 4.9 9.8"
}
const _hoisted_3 = {
  key: 1,
  class: "rating"
}
const _hoisted_4 = {
  key: 0,
  class: "rating__icon rating__icon--half",
  viewBox: "0 0 17 32"
}

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'rating',
  props: {
    rate: {},
    suns: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const count = computed((): number => (props.rate >= 0 ? Math.floor(props.rate) : 0));

const showHalf = computed((): boolean => props.rate % 1 !== 0 && props.rate > 0);


return (_ctx: any,_cache: any) => {
  return (_ctx.suns)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(count.value, (i) => {
          return (_openBlock(), _createElementBlock("svg", {
            key: i,
            class: "rating__icon",
            viewBox: "0 0 9.8 9.8"
          }, [
            _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rate), 1 /* TEXT */),
            _cache[0] || (_cache[0] = _createElementVNode("path", { d: "M4.9 1.5c.2 0 .4-.2.4-.4V.4C5.3.2 5.1 0 4.9 0 4.7 0 4.5.2 4.5.4v.7C4.6 1.4 4.7 1.5 4.9 1.5zM3.2 2c.2-.1.2-.3.1-.5L3 .8C2.9.6 2.7.6 2.5.7S2.2 1 2.3 1.2l.4.6C2.8 2 3.1 2.1 3.2 2zM2 3.2C2.1 3 2 2.8 1.8 2.7L1.2 2.3C1 2.2.8 2.3.7 2.5.6 2.6.7 2.9.8 3l.6.4C1.7 3.4 1.9 3.4 2 3.2zM1.5 4.9c0-.2-.2-.4-.4-.4H.4C.2 4.5 0 4.7 0 4.9c0 .2.2.4.4.4h.7C1.4 5.3 1.5 5.1 1.5 4.9zM2 6.6C1.9 6.4 1.6 6.3 1.4 6.4L.8 6.8C.6 6.9.6 7.2.7 7.3.8 7.5 1 7.6 1.2 7.5l.6-.4C2 7 2.1 6.7 2 6.6zM3.2 7.8C3 7.7 2.8 7.8 2.7 8L2.3 8.6C2.2 8.8 2.3 9 2.5 9.1 2.7 9.2 2.9 9.2 3 9l.4-.6C3.4 8.1 3.4 7.9 3.2 7.8zM4.9 8.3c-.2 0-.4.2-.4.4v.7c0 .2.2.4.4.4.2 0 .4-.2.4-.4V8.7C5.3 8.4 5.1 8.3 4.9 8.3zM6.6 7.8C6.4 8 6.3 8.2 6.4 8.4L6.8 9c.1.2.3.2.5.1C7.5 9 7.6 8.8 7.5 8.6L7.1 8C7 7.8 6.8 7.7 6.6 7.8zM7.8 6.6C7.7 6.8 7.8 7 8 7.1l.6.4c.2.1.4 0 .5-.1C9.2 7.2 9.1 7 9 6.9L8.3 6.5C8.2 6.4 7.9 6.4 7.8 6.6zM8.3 4.9c0 .2.2.4.4.4h.7c.2 0 .4-.2.4-.4 0-.2-.2-.4-.4-.4H8.7C8.5 4.5 8.3 4.7 8.3 4.9zM7.9 3.2C8 3.4 8.2 3.5 8.4 3.4L9 3c.2-.1.2-.3.1-.5C9 2.3 8.8 2.2 8.6 2.3L8 2.7C7.8 2.8 7.7 3.1 7.9 3.2zM6.6 2C6.8 2.1 7 2 7.1 1.8l.4-.6c.1-.2 0-.4-.1-.5C7.2.6 7 .7 6.9.8L6.5 1.5C6.4 1.6 6.4 1.9 6.6 2zM4.9 1.9c-1.7 0-3 1.4-3 3 0 1.7 1.4 3 3 3 1.7 0 3-1.4 3-3C7.9 3.2 6.5 1.9 4.9 1.9z" }, null, -1 /* HOISTED */))
          ]))
        }), 128 /* KEYED_FRAGMENT */)),
        (showHalf.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_2, [
              _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rate), 1 /* TEXT */),
              _cache[1] || (_cache[1] = _createElementVNode("path", { d: "M3.2 2c.2-.1.2-.3.1-.5L3 .8C2.9.6 2.7.6 2.5.7S2.2 1 2.3 1.2l.4.6C2.8 2 3.1 2.1 3.2 2zM2 3.2C2.1 3 2 2.8 1.8 2.7L1.2 2.3C1 2.2.8 2.3.7 2.5.6 2.6.7 2.9.8 3l.6.4C1.7 3.4 1.9 3.4 2 3.2zM1.5 4.9c0-.2-.2-.4-.4-.4H.4C.2 4.5 0 4.7 0 4.9c0 .2.2.4.4.4h.7C1.4 5.3 1.5 5.1 1.5 4.9zM2 6.6C1.9 6.4 1.6 6.3 1.4 6.4L.8 6.8C.6 6.9.6 7.2.7 7.3.8 7.5 1 7.6 1.2 7.5l.6-.4C2 7 2.1 6.7 2 6.6zM3.2 7.8C3 7.7 2.8 7.8 2.7 8L2.3 8.6C2.2 8.8 2.3 9 2.5 9.1S2.9 9.2 3 9l.4-.6C3.4 8.1 3.4 7.9 3.2 7.8zM4.9 1.9L4.9 1.9c-1.7 0-3 1.4-3 3s1.4 2.9 3 2.9V1.9zM4.9 0C4.7 0 4.5.2 4.5.4v.7c0 .2.2.4.4.4V0zM4.9 8.3L4.9 8.3c-.2 0-.4.2-.4.4v.7c0 .2.2.4.4.4V8.3z" }, null, -1 /* HOISTED */))
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(count.value, (i) => {
          return (_openBlock(), _createElementBlock("svg", {
            key: i,
            class: "rating__icon",
            viewBox: "0 0 34 32"
          }, [
            _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rate), 1 /* TEXT */),
            _cache[2] || (_cache[2] = _createElementVNode("path", { d: "M24.502 20.183l2.716 11.817-10.396-6.237-10.396 6.239 2.718-11.816-9.144-7.963 12.077-1.064 4.745-11.158 4.745 11.158 12.077 1.064-9.143 7.961z" }, null, -1 /* HOISTED */))
          ]))
        }), 128 /* KEYED_FRAGMENT */)),
        (showHalf.value)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_4, [
              _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(_ctx.rate), 1 /* TEXT */),
              _cache[3] || (_cache[3] = _createElementVNode("path", { d: "M16.826 25.75l-10.396 6.25 2.704-11.808-9.134-7.962 12.079-1.052 4.747-11.147v25.72z" }, null, -1 /* HOISTED */))
            ]))
          : _createCommentVNode("v-if", true)
      ]))
}
}

})