import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flying-time-slider" }
const _hoisted_2 = { class: "flying-time-slider__header" }
const _hoisted_3 = { class: "flying-time-slider__title" }
const _hoisted_4 = { class: "flying-time-slider__hours" }
const _hoisted_5 = { class: "flying-time-slider__inputs" }

import {
	ref, computed, watch,
} from 'vue';

const minRangeValueGap = 1;

const totalRange = 24;


export default /*@__PURE__*/_defineComponent({
  __name: 'FlyingTimeSlider',
  props: {
	modelValue: {
		type: Object,
		required: true,
		default: () => ({ from: 0, to: 24 }),
	},
	title: {
		type: String,
		default: '',
	},
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const rangeTrack = ref(null);
const trackLeft = ref((props.modelValue.from / totalRange) * 100);
const trackRight = ref(props.modelValue.to);
const trackWidth = computed(() => (trackRight.value / totalRange) * 100 - trackLeft.value);

const minSliderValue = ref(props.modelValue.from);
const maxSliderValue = ref(props.modelValue.to);

const minHours = ref(`${Math.round(props.modelValue.from)}:00`);
const maxHours = ref(`${Math.round(props.modelValue.to)}:00`);
const displayedHours = computed(() => `${minHours.value} - ${maxHours.value}`);

const maxConstraint = computed(() => maxSliderValue.value - minRangeValueGap);
const minConstraint = computed(() => minSliderValue.value + minRangeValueGap);

const updateMin = (value: number) => {
	minHours.value = `${value}:00`;
	trackLeft.value = (value / totalRange) * 100;
};

const updateMax = (value: number) => {
	maxHours.value = `${value}:00`;
	trackRight.value = value;
};

const handleMinMouseUp = (evt) => {
	emit('update:modelValue', { from: Math.min(maxSliderValue.value - minRangeValueGap, evt.target.valueAsNumber), to: maxSliderValue.value });
};
const handleMinInput = (evt) => {
	if (evt.target.valueAsNumber < maxConstraint.value) {
		minSliderValue.value = evt.target.valueAsNumber;
		updateMin(evt.target.valueAsNumber);
		return;
	}
	evt.preventDefault();
	minSliderValue.value = maxConstraint.value;
	updateMin(maxConstraint.value);
};

const handleMaxMouseUp = (evt) => {
	emit('update:modelValue', { from: minSliderValue.value, to: Math.max(evt.target.valueAsNumber, minSliderValue.value + minRangeValueGap) });
};
const handleMaxInput = (evt) => {
	if (evt.target.valueAsNumber > minConstraint.value) {
		maxSliderValue.value = evt.target.valueAsNumber;
		updateMax(evt.target.valueAsNumber);
		return;
	}
	evt.preventDefault();
	maxSliderValue.value = minConstraint.value;
	updateMax(minConstraint.value);
};

const handleKeydown = (evt, type, offset) => {
	const value = evt.target.valueAsNumber;
	updateValue(type, value + offset);
};

const updateValue = (type: 'from' | 'to', value: number) => {
	const updated = Math.max(Math.min(value, 24), 0);
	if (type === 'from') {
		if (updated < maxConstraint.value) {
			updateMin(updated);
			emit('update:modelValue', { to: maxSliderValue.value, from: updated });
		} else {
			updateMin(maxConstraint);
			emit('update:modelValue', { to: maxSliderValue.value, from: maxConstraint.value });
		}
	} else if (type === 'to') {
		if (updated > minConstraint.value) {
			updateMax(updated);
			emit('update:modelValue', { from: minSliderValue.value, to: updated });
		} else {
			updateMax(minConstraint);
			emit('update:modelValue', { from: minSliderValue.value, to: minConstraint.value });
		}
	}
};

watch(() => props.modelValue, (value) => {
	minSliderValue.value = value.from;
	maxSliderValue.value = value.to;
	updateMin(value.from);
	updateMax(value.to);
}, { immediate: true, deep: true });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(displayedHours.value), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", {
        ref_key: "rangeTrack",
        ref: rangeTrack,
        class: "flying-time-slider__range_track",
        style: _normalizeStyle({ left: trackLeft.value + '%', width: trackWidth.value + '%' })
      }, null, 4 /* STYLE */),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((minSliderValue).value = $event)),
        type: "range",
        step: "1",
        min: "0",
        max: "24",
        onMouseup: handleMinMouseUp,
        onInput: handleMinInput,
        onTouchend: handleMinMouseUp,
        onKeyup: [
          _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (handleKeydown($event,'from', -1)), ["exact","stop","prevent"]), ["left"])),
          _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (handleKeydown($event,'from', 1)), ["exact","stop","prevent"]), ["right"]))
        ]
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
        [_vModelText, minSliderValue.value]
      ]),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((maxSliderValue).value = $event)),
        type: "range",
        step: "1",
        min: "0",
        max: "24",
        onMouseup: handleMaxMouseUp,
        onInput: handleMaxInput,
        onTouchend: handleMaxMouseUp,
        onKeyup: [
          _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (handleKeydown($event,'to', -1)), ["stop","prevent"]), ["left"])),
          _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (handleKeydown($event,'to', 1)), ["stop","prevent"]), ["right"]))
        ]
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
        [_vModelText, maxSliderValue.value]
      ])
    ])
  ]))
}
}

})