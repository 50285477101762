import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "recommendation-rate" }
const _hoisted_2 = ["onClick"]

import { ref, watch } from 'vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { useStore } from '@/components/common/store';

interface Props {
	value: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendationRate',
  props: {
    value: { default: 0 }
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const rates = [70, 80, 90, 95];
const rate = ref(props.value);

const onClick = (value: number) => {
	rate.value = rate.value === value ? 0 : value;
	store.commit('searchMask/updateFormData', { minMeanRecommendationRate: rate.value });
};

watch(() => props.value, () => {
	rate.value = props.value;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(rates, (rateValue) => {
      return _createElementVNode("span", {
        key: `recommendation-rate__item-${rateValue}`,
        class: "hotel-category__item",
        onClick: ($event: any) => (onClick(rateValue))
      }, [
        _createVNode(RoundedButton, {
          theme: "outlined",
          class: _normalizeClass(["recommendation-rate__button", {'is-active': rateValue === rate.value}])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(`${rateValue}%`), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"])
      ], 8 /* PROPS */, _hoisted_2)
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})