import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mostPopularFilters" }

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { mostPopularTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'MostPopularFilters',
  setup(__props) {

const store = useStore();

const listOfAvailableFilters = ref(mostPopularTypes);

const hotelFilters = computed({
	get() {
		if (store.state.searchMask.mostPopularFilters?.hotelAttributes) {
			return store.state.searchMask.mostPopularFilters?.hotelAttributes;
		}
		return [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			// eslint-disable-next-line no-use-before-define
			{ mostPopularFilters: { ratingAttributes: ratingFilters.value, hotelAttributes: value } }
		);
	}
});

const ratingFilters = computed({
	get() {
		if (store.state.searchMask.mostPopularFilters?.ratingAttributes) {
			return store.state.searchMask.mostPopularFilters?.ratingAttributes;
		}
		return [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData',
			{ mostPopularFilters: { ratingAttributes: value, hotelAttributes: hotelFilters.value } }
		);
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listOfAvailableFilters.value, (availableFilter, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: "mostPopularFilters__item"
      }, [
        (availableFilter.attrType === 'HotelAttributes')
          ? (_openBlock(), _createBlock(Checkbox, {
              key: 0,
              modelValue: hotelFilters.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hotelFilters).value = $event)),
              label: availableFilter.label,
              "custom-value": availableFilter.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
          : (_openBlock(), _createBlock(Checkbox, {
              key: 1,
              modelValue: ratingFilters.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ratingFilters).value = $event)),
              label: availableFilter.label,
              "custom-value": availableFilter.value
            }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})