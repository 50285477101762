<template>
	<div class="list-filters">
		<h2 class="list-filters__header">
			Suchergebnisse filtern
		</h2>
		<div>
			<div class="list-filters__content">
				<FilterContainer
					v-if="showDealsFilter"
					:title="dealsFilterTitle"
					:with-divider="false"
					class="list-filters__deals-container"
				>
					<DealsFilter class="list-filters__deals" />
				</FilterContainer>
				<FilterContainer :with-divider="showDealsFilter">
					<FlexFilter />
				</FilterContainer>
				<FilterContainer
					v-if="!isHotelOnly"
					title="Flug und Transport"
				>
					<DirectFlightField class="list-filters__direct-flight" />
					<FlyingTimeFilter class="list-filters__flight" />
					<TransferField />
				</FilterContainer>
				<FilterContainer v-if="!isOfferList">
					<PriceSlider
						:default-value="formData.maxPrice"
						:currency="currencyType"
					/>
				</FilterContainer>
				<FilterContainer title="Verpflegung und Zimmer">
					<BoardField
						:is-hotel-page="isOfferList"
						class="list-filters__board-types"
					/>
					<RoomTypeField
						:is-hotel-page="isOfferList"
						class="list-filters__room-type"
					/>
					<SeaViewField class="list-filters__sea-view" />
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
					title="Hotelkategorie ab"
				>
					<HotelCategory
						:value="formData.hotelCategory"
						:show-suns="showSuns"
					/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
					title="Weiterempfehlungsrate ab"
				>
					<RecommendationRate
						:value="formData.minMeanRecommendationRate"
					/>
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
					title="Beliebteste Filter"
					:collapsible="true"
					:default-open="true"
					class="list-filters__most-popular"
				>
					<MostPopularFilters :filters="formData.mostPopularFilters" />
				</FilterContainer>
				<FilterContainer
					v-if="!isOfferList"
					title="Kundenbewertungen"
					:collapsible="true"
					:default-open="openAdditionalFilters"
					class="list-filters__additional"
				>
					<AdditionalCheckboxFilters :filters="formData.additionalCheckboxFilters" />
				</FilterContainer>
				<FilterContainer
					v-if="showDestinationFilter"
					:title="destinationFilterTitle"
					:with-divider="!isOfferList"
				>
					<SkeletonLoader
						v-if="destinationFilterLoading"
						class="list-filters__geo-skeleton"
						:loader-width="31.8"
						:loader-height="7"
						:border-radius="'1rem'"
					/>
					<DestinationFilter
						v-else
						:destination-label="destinationLabel"
						:location-type="locationType"
						:disabled="!destinationTypes || destinationTypes.length == 0"
					/>
				</FilterContainer>
				<FilterContainer title="Veranstalter">
					<OperatorField
						v-if="!tourOperatorLoading"
						class="list-filters__operator"
						:disabled="!operatorTypes || operatorTypes.length === 0"
					/>
					<SkeletonLoader
						v-if="tourOperatorLoading"
						class="list-filters__operator-skeleton"
						:loader-width="31.8"
						:loader-height="7"
						:border-radius="'1rem'"
					/>
				</FilterContainer>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import BoardField from '@lmt-rpb/BoardField/BoardField.vue';
import DirectFlightField from '@lmt-rpb/DirectFlight/DirectFlight.vue';
import FlyingTimeFilter from '@lmt-rpb/FlyingTimeFilter/FlyingTimeFilter.vue';
import RoomTypeField from '@lmt-rpb/RoomTypeField/RoomTypeField.vue';
import SeaViewField from '@lmt-rpb/SeaView/SeaView.vue';
import TransferField from '@lmt-rpb/TransferField/TransferField.vue';
import HotelCategory from '@lmt-rpb/HotelCategory/HotelCategory.vue';
import RecommendationRate from '@lmt-rpb/RecommendationRate/RecommendationRate.vue';
import PriceSlider from '@lmt-rpb/PriceSlider/PriceSlider.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFilters/MostPopularFilters.vue';
import AdditionalCheckboxFilters from '@lmt-rpb/AdditionalCheckboxFilters/AdditionalCheckboxFilters.vue';
import OperatorField from '@lmt-rpb/OperatorField/OperatorField.vue';
import type { SearchFormDataType } from '@interfaces/search-form';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import FilterContainer from '@lmt-rpb/FilterContainer/FilterContainer.vue';
import {
	computed, onMounted, ref,
} from 'vue';
import FlexFilter from '@lmt-rpb/FlexFilter/FlexFilter.vue';
import DealsFilter from '@lmt-rpb/Organisms/DealsFilter/DealsFilter.vue';
import { dealsFilterClientTitle } from '@lmt-rpb/Organisms/DealsFilter/dealsFilterHelper';
import { useStore } from '@/components/common/store';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	forceDesktop?: boolean
	destinationLabel?: string
	listType: 'hotel' | 'region' | 'offer',
}

const props = withDefaults(defineProps<Props>(), {
	forceDesktop: false,
	destinationLabel: '',
});

const dealsFilterTitle = dealsFilterClientTitle();

const store = useStore();
const formData = computed((): SearchFormDataType => store.state.searchMask);
const locationType = computed((): string => store.state.config.locationType);

const showDestinationFilter = computed((): boolean => props.listType === 'hotel' && locationType.value !== 'CITY');
const destinationFilterTitle = computed(() => (locationType.value === 'COUNTRY' || locationType.value === 'REGIONGROUP' ? 'Region' : 'Ort'));
const destinationTypes = computed(() => store.state.types.destination);

const isHotelListPage = computed(() => props.listType === 'hotel');
const isOfferList = computed(() => props.listType === 'offer');
const showDealsFilter = computed((): boolean => isHotelListPage.value);

const openAdditionalFilters = ref(Boolean(formData.value.additionalCheckboxFilters?.ratingAttributes?.length || formData.value.additionalCheckboxFilters?.hotelAttributes?.length));

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const operatorTypes = computed(() => store.state.types.operator);

const showSuns = isClientGermany();

const currencyType = computed(() => (store.getters.getCurrencyType));

const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);

const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onMounted(() => {
	// this is needed for setting the isDesktop property to true in Storybook
	if (props.forceDesktop) {
		store.state.config.isDesktop = true;
	}

	if (isHotelOnly.value) {
		setAccommodationType(true);
	}
});
</script>

<style lang="scss" scoped>

:deep() {
	.range__slider {
		// half of the circle minus slider height
		margin-bottom: 1.2rem;
	}

	.price-slider__value {
		margin-top: 0;
	}

	.checkbox {
		font-weight: $font-weight-regular;
	}

	.flying-time-slider__inputs {
		margin-left: 0.8rem;
	}
}

.list-filters {
	width: 100%;

	@media (min-width: $breakpoint-extralarge) {
		width: unset;
	}
}

.list-filters__header {
	text-align: left;
	background-color: $color-primary;
	color: $color-white;
	font-size: $font-medium-1;
	font-family: $font-family-special;
	font-weight: $font-weight-bold;
	padding: 1.1rem 1.5rem;
	border-top-left-radius: $border-radius-filter;
	border-top-right-radius: $border-radius-filter;

	@media screen and (max-width: $breakpoint-extralarge - 1) {
		display: none;
	}
}

.list-filters__room-type,
.list-filters__board-types {
	margin-bottom: 1.6rem;
}

.list-filters__direct-flight {
	margin-bottom: 2.4rem;
	margin-left: 0.8rem;
}

.list-filters__deals {
	margin-left: 0.8rem;
}

.list-filters__flight {
	margin-bottom: 2.4rem;
}

.list-filters__sea-view {
	display: flex;
	margin: 1.6rem 0 1.6rem 0.7rem;
}

.list-filters__operator-skeleton,
.list-filters__geo-skeleton {
	border: 1px solid $filter-border-color;
}

:deep(.direct-flight-filter),
:deep(.flying-time-filter) {
	padding: 0;
}

:deep(.flying-time-filter__sliders) {
	margin-left: 0;
	padding: 0;
}

@media (min-width: $breakpoint-extralarge - 1px) {
	.list-filters__content {
		padding: 1.5rem;
		border-bottom-left-radius: $border-radius-filter;
		border-bottom-right-radius: $border-radius-filter;

		// TODO: $color-filter-bg variable is #f9f9f9 for kuoni
		// check if it always should be #FFFAF5 because then it is the same as $color-checkout-wrapper and probably some others as well
		background: $color-checkout-wrapper;
		border-top: 0;
	}
}
</style>
