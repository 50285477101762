import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "searchbar__text" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';
import LabeledText from '@lmt-rpb/LabeledText/LabeledText.vue';
import { getFilterCount } from '@/js/utils/search-filter-utils';

type Props = {
	hasMap?: boolean
	hasSort?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchBar',
  props: {
    hasMap: { type: Boolean, default: true },
    hasSort: { type: Boolean, default: true }
  },
  emits: ['SearchBar:openFilterModal'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const store = useStore();
const sortingPricePopular = computed(() => store.state.searchMask.sortingPricePopular);
const sortText = computed(() => (sortingPricePopular.value ? 'Beliebtheit' : 'Preis'),);
const isDesktop = computed(() => store.state.config.isDesktop);
const formData = computed(() => store.state.searchMask);
const filterAmount = computed((): number => getFilterCount(formData.value));

const handleMapClick = () => {
	EventBus.$emit('map:toggle');
};
const handleSortClick = () => {
	store.commit('searchMask/updateFormData', { sortingPricePopular: !sortingPricePopular.value });
};
const handleFilterClick = () => {
	emit('SearchBar:openFilterModal');
};


return (_ctx: any,_cache: any) => {
  return (!isDesktop.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["searchbar", {
			'searchbar--one-column': !_ctx.hasSort && !_ctx.hasMap,
			'searchbar--three-columns': _ctx.hasSort && _ctx.hasMap
		}])
      }, [
        (_ctx.hasMap)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "searchbar__item searchbar__item--map",
              onClick: handleMapClick
            }, [
              _createVNode(BaseIcon, {
                name: "map",
                class: "searchbar__icon searchbar__icon--map"
              }),
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "searchbar__text" }, " Karte ", -1 /* HOISTED */))
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.hasSort)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "searchbar__item searchbar__item--sort",
              onClick: handleSortClick
            }, [
              _createVNode(BaseIcon, {
                name: "sortDesc",
                class: "searchbar__icon"
              }),
              _createVNode(LabeledText, {
                label: "Sortiert nach",
                class: "searchbar__text searchbar__text--sort",
                text: sortText.value
              }, null, 8 /* PROPS */, ["text"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", {
          class: "searchbar__item searchbar__item--filter",
          onClick: handleFilterClick
        }, [
          _createVNode(BaseIcon, {
            name: "filterNew",
            class: "searchbar__icon"
          }),
          _createElementVNode("p", _hoisted_1, " Filter " + _toDisplayString(filterAmount.value ? `(${filterAmount.value})` : ''), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}
}

})