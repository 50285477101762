<template>
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Entertaiment Symbol</title>
		<path
			fill="#000000"
			d="M23.9906 12.5344C22.7672 12.9422 21.4875 13.1812 20.1937 13.2422C20.625 8.30625 18.7172 3.67031 15.3563 0.473438C20.3484 1.92656 24 6.53906 24 12C24 12.1781 23.9953 12.3562 23.9906 12.5344ZM23.8078 14.1609C23.5359 15.6609 22.9828 17.0672 22.2047 18.3187C17.6344 20.5922 12 20.3156 7.58438 17.1703C8.69531 15.4734 10.1812 13.9969 12.0141 12.8672C15.7594 14.8922 19.9875 15.2484 23.8078 14.1609ZM12.7453 11.5594C12.6281 7.29844 10.8188 3.46406 7.96875 0.698437C9.225 0.24375 10.5844 0 12 0C12.1266 0 12.2484 0 12.3703 0.0046875C16.6266 2.82656 19.2 7.84219 18.6844 13.2375C16.6641 13.125 14.6344 12.5766 12.7406 11.5547L12.7453 11.5594ZM6.46875 1.35C7.43437 2.20781 8.28281 3.19688 8.98594 4.28438C4.49531 6.37969 1.43437 10.35 0.342188 14.8547C0.117188 13.9406 0 12.9844 0 12C0 7.36875 2.625 3.35156 6.46875 1.35ZM9.73125 5.59219C10.6453 7.40156 11.1844 9.43125 11.2453 11.5781C7.61719 13.8094 5.19844 17.2922 4.22812 21.1406C3.09375 20.175 2.1375 19.0031 1.425 17.6813C1.73906 12.5859 4.79531 7.84219 9.72656 5.59219H9.73125ZM12 24C9.62344 24 7.40625 23.3109 5.54062 22.1156C5.80312 20.85 6.23438 19.6266 6.825 18.4734C10.8844 21.3094 15.8484 21.975 20.2969 20.6719C18.1406 22.7344 15.2203 24 12 24Z"
		/>
	</svg>
</template>
