import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "counter",
  "aria-live": "polite"
}
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["value"]

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { pluralize } from '@utils/utils';
import { rangeArray } from '@utils/arrayUtils';
import { computed } from 'vue';

interface Props {
	min?: number,
	max?: number,
	singleLabel: string,
	pluralLabel: string
	modelValue: number,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Counter',
  props: {
    min: { default: 1 },
    max: { default: 4 },
    singleLabel: { default: '' },
    pluralLabel: { default: '' },
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const items = computed((): Record<'label'|'value', string|number>[] => rangeArray(props.min, props.max).map((item: number) => ({
	label: pluralize(item, props.singleLabel, props.pluralLabel),
	value: item
})));

const emit = __emit;

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newCount) {
		emit('update:modelValue', newCount);
	}
});

const increase = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue + 1, props.max));
};

const decrease = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue - 1, props.max));
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(RoundedButton, {
      class: "counter__button is-decrease",
      disabled: model.value === _ctx.min,
      onClick: decrease,
      onKeydown: _withKeys(_withModifiers(decrease, ["prevent"]), ["enter"])
    }, {
      default: _withCtx(() => [
        _createVNode(BaseIcon, {
          name: "minus",
          class: "counter__icon"
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled", "onKeydown"]),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
      class: "counter__select",
      "aria-label": _ctx.singleLabel
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: item.value
        }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ], 8 /* PROPS */, _hoisted_2), [
      [_vModelSelect, model.value]
    ]),
    _createVNode(RoundedButton, {
      class: "counter__button is-increase",
      disabled: model.value === _ctx.max,
      onClick: increase,
      onKeydown: _withKeys(_withModifiers(increase, ["prevent"]), ["enter"])
    }, {
      default: _withCtx(() => [
        _createVNode(BaseIcon, {
          name: "plus",
          class: "counter__icon"
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled", "onKeydown"])
  ]))
}
}

})