<template>
	<svg
		width="20"
		height="15"
		viewBox="0 0 20 15"
		fill="#000"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Top Seller</title>
		<path
			d="M11.6313 1.625C11.6313 2.27813 11.2437 2.84375 10.6875 3.1L14 6L17.2625 5.34688C17.0969 5.10625 17 4.8125 17 4.5C17 3.67188 17.6719 3 18.5 3C19.3281 3 20 3.67188 20 4.5C20 5.3125 19.3562 5.97188 18.55 6L15.0312 13.8219C14.7094 14.5406 13.9938 15 13.2063 15H6.79375C6.00625 15 5.29375 14.5375 4.96875 13.8219L1.45 6C0.64375 5.97188 0 5.3125 0 4.5C0 3.67188 0.671875 3 1.5 3C2.32812 3 3 3.67188 3 4.5C3 4.81563 2.90313 5.10625 2.7375 5.34688L6 6L9.31563 3.09687C8.7625 2.8375 8.37813 2.275 8.37813 1.625C8.37813 0.728125 9.10625 0 10.0031 0C10.9 0 11.6281 0.728125 11.6281 1.625H11.6313Z"
			fill="#333333"
		/>
	</svg>
</template>
