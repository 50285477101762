<template>
	<div
		class="counter"
		aria-live="polite"
	>
		<RoundedButton
			class="counter__button is-decrease"
			:disabled="model === min"
			@click="decrease"
			@keydown.enter.prevent="decrease"
		>
			<BaseIcon
				name="minus"
				class="counter__icon"
			/>
		</RoundedButton>
		<select
			v-model="model"
			class="counter__select"
			:aria-label="singleLabel"
		>
			<option
				v-for="(item, index) in items"
				:key="index"
				:value="item.value"
			>
				{{ item.label }}
			</option>
		</select>
		<RoundedButton
			class="counter__button is-increase"
			:disabled="model === max"
			@click="increase"
			@keydown.enter.prevent="increase"
		>
			<BaseIcon
				name="plus"
				class="counter__icon"
			/>
		</RoundedButton>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { pluralize } from '@utils/utils';
import { rangeArray } from '@utils/arrayUtils';
import { computed } from 'vue';

interface Props {
	min?: number,
	max?: number,
	singleLabel: string,
	pluralLabel: string
	modelValue: number,
}

const props = withDefaults(defineProps<Props>(), {
	min: 1,
	max: 4,
	singleLabel: '',
	pluralLabel: '',
});

const items = computed((): Record<'label'|'value', string|number>[] => rangeArray(props.min, props.max).map((item: number) => ({
	label: pluralize(item, props.singleLabel, props.pluralLabel),
	value: item
})));

const emit = defineEmits(['update:modelValue']);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newCount) {
		emit('update:modelValue', newCount);
	}
});

const increase = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue + 1, props.max));
};

const decrease = (): void => {
	model.value = Math.max(props.min, Math.min(props.modelValue - 1, props.max));
};

</script>

<style lang="scss" scoped>
.counter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	border: 0.1rem solid $color-primary-l4;
	border-radius: $border-radius-small;

	.counter__icon {
		width: 2.5rem;
		fill: $color-primary;
		vertical-align: middle;
	}

	.counter__button {
		display: flex;
		align-items: center;
		width: 4.5rem;
		height: 4.5rem;
		padding: 1rem;
		background: $color-white;
		vertical-align: middle;
		box-shadow: 0 2px 5px $color-black-t2;

		&:hover,
		&:focus {
			background: $color-primary-l6;
		}

		&:disabled .counter__icon {
			fill: $color-state-disabled;
		}
	}

	.counter__select {
		appearance: none;
		border: none;
		outline: none;
		background: none;
		color: $color-primary;
		font-size: $font-medium-1;
		font-weight: bold;
		vertical-align: middle;

		&::-ms-expand {
			display: none;
		}
	}
}
</style>
