<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Hotel</title>
		<path
			d="M0 1.25C0 0.558594 0.558594 0 1.25 0H18.75C19.4414 0 20 0.558594 20 1.25C20 1.94141 19.4414 2.5 18.75 2.5V17.5C19.4414 17.5 20 18.0586 20 18.75C20 19.4414 19.4414 20 18.75 20H11.875V18.125C11.875 17.0898 11.0352 16.25 10 16.25C8.96484 16.25 8.125 17.0898 8.125 18.125V20H1.25C0.558594 20 0 19.4414 0 18.75C0 18.0586 0.558594 17.5 1.25 17.5V2.5C0.558594 2.5 0 1.94141 0 1.25ZM3.75 4.375V5.625C3.75 5.96875 4.03125 6.25 4.375 6.25H5.625C5.96875 6.25 6.25 5.96875 6.25 5.625V4.375C6.25 4.03125 5.96875 3.75 5.625 3.75H4.375C4.03125 3.75 3.75 4.03125 3.75 4.375ZM9.375 3.75C9.03125 3.75 8.75 4.03125 8.75 4.375V5.625C8.75 5.96875 9.03125 6.25 9.375 6.25H10.625C10.9688 6.25 11.25 5.96875 11.25 5.625V4.375C11.25 4.03125 10.9688 3.75 10.625 3.75H9.375ZM13.75 4.375V5.625C13.75 5.96875 14.0312 6.25 14.375 6.25H15.625C15.9688 6.25 16.25 5.96875 16.25 5.625V4.375C16.25 4.03125 15.9688 3.75 15.625 3.75H14.375C14.0312 3.75 13.75 4.03125 13.75 4.375ZM4.375 7.5C4.03125 7.5 3.75 7.78125 3.75 8.125V9.375C3.75 9.71875 4.03125 10 4.375 10H5.625C5.96875 10 6.25 9.71875 6.25 9.375V8.125C6.25 7.78125 5.96875 7.5 5.625 7.5H4.375ZM8.75 8.125V9.375C8.75 9.71875 9.03125 10 9.375 10H10.625C10.9688 10 11.25 9.71875 11.25 9.375V8.125C11.25 7.78125 10.9688 7.5 10.625 7.5H9.375C9.03125 7.5 8.75 7.78125 8.75 8.125ZM14.375 7.5C14.0312 7.5 13.75 7.78125 13.75 8.125V9.375C13.75 9.71875 14.0312 10 14.375 10H15.625C15.9688 10 16.25 9.71875 16.25 9.375V8.125C16.25 7.78125 15.9688 7.5 15.625 7.5H14.375ZM12.8125 15C13.332 15 13.7617 14.5742 13.6328 14.0703C13.2188 12.4492 11.75 11.25 10 11.25C8.25 11.25 6.77734 12.4492 6.36719 14.0703C6.23828 14.5703 6.67188 15 7.1875 15H12.8125Z"
			fill="#333333"
		/>
	</svg>
</template>
