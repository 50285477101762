import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout-info-flight-details" }

import { FlightSegment } from '@/interfaces/checkout/offer-data';
import FlightData from '@lmt-rpb/FlightData/FlightData.vue';
import CheckoutInfoButton from '@lmt-rpb/CheckoutInfoButton/CheckoutInfoButton.vue';
import CheckoutFlightDetails from '@lmt-rpb/CheckoutFlightDetails/CheckoutFlightDetails.vue';

type Props = {
	outbound: FlightSegment[]
	inbound: FlightSegment[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutInfoFlightDetails',
  props: {
    outbound: {},
    inbound: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FlightData, {
      "flight-segment": _ctx.outbound,
      outbound: true
    }, null, 8 /* PROPS */, ["flight-segment"]),
    _createVNode(FlightData, {
      "flight-segment": _ctx.inbound,
      outbound: false
    }, null, 8 /* PROPS */, ["flight-segment"]),
    _createVNode(CheckoutInfoButton, {
      class: "checkout-info-flight-details__button",
      "button-text": "Flugdetails anzeigen",
      "modal-title": "Flugdetails"
    }, {
      default: _withCtx(() => [
        _createVNode(CheckoutFlightDetails, {
          "inbound-flight": _ctx.inbound,
          "outbound-flight": _ctx.outbound
        }, null, 8 /* PROPS */, ["inbound-flight", "outbound-flight"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}
}

})