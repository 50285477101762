/* eslint-disable camelcase */
import { ItemType } from '@lmt-rpb/HotelListItem/types';
import { BestPackageOfferForHotelList, BestPackageOffer } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { OfferType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { MergedOfferItemData } from '@/interfaces/offer';
import AnalyticsEvent from './AnalyticsEvent';
import GaItemList from './GaItemList';

interface GaOffer {
	currency: string;
	price: number;
}

interface AnalyticsGeoLocation {
	Country: { CountryName: string; };
	Region: { RegionName: string; };
	City: { CityName: string; };
}

export default class BookmarkAnalytics extends AnalyticsEvent {
	static fromItemType(hotel: ItemType['Hotel'], location: AnalyticsGeoLocation, offer: GaOffer | BestPackageOffer | OfferType): BookmarkAnalytics {
		let gaOffer = offer as GaOffer;
		const packageOffer = offer as BestPackageOffer;

		if (!gaOffer.currency) {
			gaOffer = { currency: packageOffer.CurrencyCode, price: packageOffer.Price };
		}

		const gaItemList: GaItemList = {
			currency: gaOffer.currency,
			value: 0,
			items: [{
				item_id: hotel.IffCode,
				item_name: hotel.Name,
				item_category: location.Country.CountryName,
				item_category2: location.Region.RegionName,
				item_category3: location.City.CityName,
				quantity: 1,
				price: gaOffer.price
			}]
		};

		return new BookmarkAnalytics(gaItemList);
	}

	static fromFavoritedHotel(hotel: FavoritedHotel[]) {
		const gaItemList: GaItemList = {
			value: 0,
			items: hotel.map((h) => ({
				item_id: h.IffCode,
				item_name: h.Name,
				item_category2: h.Region,
				quantity: 1,
				price: h.Price
			}))
		};

		return new BookmarkAnalytics(gaItemList);
	}

	static fromHotelFeatures(hotel: any, location: any, offer: Partial<MergedOfferItemData>): BookmarkAnalytics {
		const gaItemList: GaItemList = {
			currency: offer.CurrencyCode,
			value: 0,
			items: [{
				item_id: hotel.IffCode,
				item_name: hotel.Name,
				item_category: location.Country.Name,
				item_category2: location.Region.Name,
				item_category3: location.City.Name,
				quantity: 1,
				price: offer.Price ?? undefined,
			}]
		};
		return new BookmarkAnalytics(gaItemList);
	}

	static fromHotelBoxData(item: HotelBoxData, currency: string): BookmarkAnalytics {
		const gaItemList: GaItemList = {
			currency,
			value: 0,
			items: [{
				item_id: item.HotelIffCode,
				item_name: item.Name,
				item_category: item.CountryName,
				item_category2: item.RegionName,
				item_category3: item.CityName,
				quantity: 1,
				price: item.Price
			}]
		};

		return new BookmarkAnalytics(gaItemList);
	}

	static fromBestPackageOfferList(offerList: BestPackageOfferForHotelList[]): BookmarkAnalytics {
		const gaItemList: GaItemList = {
			currency: offerList[0].PackageOffer.CurrencyCode,
			value: 0,
			items: offerList.map((offer) => ({
				item_id: offer.Hotel.IffCode,
				item_name: offer.Hotel.Name,
				item_category: offer.Location.Country.CountryName,
				item_category2: offer.Location.Region.RegionName,
				item_category3: offer.Location.City.CityName,
				quantity: 1,
				price: offer.PackageOffer.Price
			}))
		};
		return new BookmarkAnalytics(gaItemList);
	}

	fireViewCart(): void {
		super.fireGoogleAnalyticsEventOrAddListener('view_cart');
	}

	fireAddToCart(): void {
		super.fireGoogleAnalyticsEventOrAddListener('add_to_cart');
	}

	fireRemoveFromCart(): void {
		super.fireGoogleAnalyticsEventOrAddListener('remove_from_cart');
	}
}
