import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { pluralize } from '@utils/utils';
import { getLocaleString } from '@utils/environmentUtils';
import { CountLabel, HOTEL_LABELS } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import { computed, ref, watch } from 'vue';
import type { DestinationElement } from '@/components/common/types';

interface Props {
	destinationLabel: string,
	locationType: string,
	disabled?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DestinationFilter',
  props: {
    destinationLabel: {},
    locationType: {},
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const open = ref(false);

const showModal = ref(false);

const countLabel = ref<CountLabel>(HOTEL_LABELS);

const isDesktop = computed(() => store.state.config.isDesktop);

const hasDealsFilterActive = computed(() => store.getters['searchMask/hasDealsFilter']);

const totalCount = computed((): number => store.state.searchMask.availableHotelCount);

const types = computed((): DestinationElement[] => store.state.types.destination);

const locationIdName = computed((): 'RegionID' | 'CityID' => (props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP' ? 'RegionID' : 'CityID'));

const destinationTypes = computed({
	get() {
		return store.state.searchMask.destinationTypes as number[];
	},

	set(value: number[]) {
		let destinationType: number[];
		let destinationTypeName;
		let selectedGeoInventory;

		const foundType = types.value.find((type) => type[locationIdName.value] === value[0]);

		if (foundType && foundType[locationIdName.value]) {
			destinationType = [foundType[locationIdName.value] as number];
			destinationTypeName = foundType.Name;
			selectedGeoInventory = foundType;
		} else {
			destinationType = [];
			destinationTypeName = '';
			selectedGeoInventory = {
				RegionID: undefined,
				CityID: undefined,
				Name: '',
				Count: 0
			};
		}

		store.commit('searchMask/updateFormData', { destinationTypes: destinationType });
		store.commit('searchMask/updateFormData', { destinationTypeName });
		store.commit('searchMask/updateFormData', { selectedGeoInventory });
	}
});

const selected = ref<number | null>((destinationTypes.value && destinationTypes.value.length && destinationTypes.value[0]) || null);

const proxy = ref<number | null>((destinationTypes.value && destinationTypes.value.length && destinationTypes.value[0]) || null);

const fieldValue = computed((): string => (store.state.searchMask.selectedGeoInventory ? store.state.searchMask.selectedGeoInventory.Name : ''));

const destinationCountAndDescription = computed(() : string => pluralize(totalCount.value, countLabel.value.singular, countLabel.value.plural));

const clearInput = (): void => {
	selected.value = null;
};

const clearProxyInput = ():void => {
	proxy.value = null;
};

const onToggle = (isOpen: boolean): void => {
	if (props.disabled && isOpen) {
		return;
	}
	open.value = isOpen;
};

const onOk = (): void => {
	destinationTypes.value = proxy.value ? [proxy.value] : [];
	onToggle(false);
};

const onReset = (): void => {
	proxy.value = destinationTypes.value[0];
	onToggle(false);
};

const toggleModal = (): void => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

const placeHolderText = (componentName: string): string => {
	let placeHolderString = '';
	const hotelCount = !hasDealsFilterActive.value ? ` (${destinationCountAndDescription.value})` : '';
	if (props.disabled) {
		placeHolderString = 'Keine Treffer';
	} else if ((props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP') && componentName === 'select') {
		placeHolderString = 'Alle Regionen';
	} else if ((props.locationType === 'COUNTRY' || props.locationType === 'REGIONGROUP') && componentName === 'dropdown') {
		placeHolderString = `Alle Regionen${hotelCount}`;
	} else if (componentName === 'select') {
		placeHolderString = 'Alle Orte';
	} else if (componentName === 'dropdown') {
		placeHolderString = `Alle Orte${hotelCount}`;
	}
	return placeHolderString;
};

watch(() => selected.value, () => {
	destinationTypes.value = selected.value ? [selected.value] : [];
});

watch(() => destinationTypes.value, () => {
	selected.value = (destinationTypes.value.length && destinationTypes.value[0]) || null;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["destination-filter", _ctx.disabled ? 'destination-filter--disabled' : ''])
  }, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(SelectField, {
          key: 0,
          ref: "destinationFilterDesktop",
          modelValue: selected.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
          class: "destination-filter__select",
          items: types.value,
          label: _ctx.destinationLabel,
          "label-prop": "Name",
          "value-prop": locationIdName.value,
          icon: "marker",
          placeholder: placeHolderText('select'),
          disabled: _ctx.disabled,
          "offer-count": !hasDealsFilterActive.value,
          "offer-count-class": 'destination-filter__count',
          "count-label": countLabel.value,
          "button-wording": _unref(getLocaleString)('close'),
          "total-count": totalCount.value,
          "item-any": true,
          "label-for-any": placeHolderText('dropdown'),
          "onSelectField:Toggle": onToggle,
          "onSelectField:Clear": clearInput
        }, null, 8 /* PROPS */, ["modelValue", "items", "label", "value-prop", "placeholder", "disabled", "offer-count", "count-label", "button-wording", "total-count", "label-for-any"]))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(FormField, {
          key: 1,
          selected: fieldValue.value,
          label: _ctx.destinationLabel,
          "show-modal": showModal.value,
          placeholder: placeHolderText('select'),
          icon: "marker",
          "show-toggle-icon": false,
          onClick: toggleModal,
          "onFormField:Clear": _cache[1] || (_cache[1] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["selected", "label", "show-modal", "placeholder"]))
      : _createCommentVNode("v-if", true),
    _createVNode(Modal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
      class: "destination-filter__modal",
      title: _ctx.locationType === 'COUNTRY' ? 'Regionen' : 'Orte',
      "onModal:Ok": onOk,
      "onModal:Cancel": onReset
    }, {
      default: _withCtx(() => [
        _createVNode(SelectField, {
          ref: "destinationFilterMobile",
          modelValue: proxy.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((proxy).value = $event)),
          class: "destination-filter__select",
          items: types.value,
          manual: true,
          placeholder: placeHolderText('dropdown'),
          "label-prop": "Name",
          "value-prop": locationIdName.value,
          "show-footer": false,
          "offer-count": !hasDealsFilterActive.value,
          "count-label": countLabel.value,
          "offer-count-class": 'destination-filter__count',
          "allow-clear": true,
          icon: "marker",
          "onSelectField:Clear": _cache[3] || (_cache[3] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["modelValue", "items", "placeholder", "value-prop", "offer-count", "count-label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ], 2 /* CLASS */))
}
}

})