import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  "aria-label": "Close",
  class: /*@__PURE__*/_normalizeClass(['close-button'])
}

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CloseButton',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(BaseIcon, {
      class: "close-button__icon",
      name: "close"
    })
  ]))
}
}

})