import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import SelectField from '@lmt-rpb/SelectField/SelectField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import {
	computed, onBeforeMount, onBeforeUnmount, ref, watch,
} from 'vue';

interface Props {
	disabled?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferField',
  props: {
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const open = ref(false);

const showModal = ref(false);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const types = computed((): any[] => store.state.types.transfer);

const transferTypes = computed({
	get(): (string | number)[] {
		return store.state.searchMask.transferTypes;
	},
	set(value: (string | number)[]) {
		let transferType = value;
		if (transferType[0] === '') {
			transferType = [];
		}
		store.commit('searchMask/updateFormData', { transferTypes: transferType });
	}
});

const selected = ref<string | number | null>((transferTypes.value.length && transferTypes.value[0]) || null);

// TODO: CHECK FOR REFACTOR
const proxy = ref<string | number | null>(transferTypes.value.length && transferTypes.value[0]) || null;

const fieldValue = computed((): string | undefined => transferTypes.value.map((value: string | number) => types.value.find((x) => x.value === value).label).toString());

const resetInput = (): void => {
	store.dispatch('resetItem', 'transferTypes');
};

const maybeResetInput = (isHotelOnly: boolean): void => {
	if (isHotelOnly) resetInput();
};

const clearInput = (): void => {
	selected.value = null;
};

const clearProxyInput = (): void => {
	proxy.value = null;
};

const onToggle = (openBool: boolean): void => {
	open.value = openBool;
};

const onOk = (): void => {
	transferTypes.value = [proxy.value || ''];
	onToggle(false);
};

const onReset = (): void => {
	proxy.value = transferTypes.value[0];
	onToggle(false);
};

const toggleModal = (): void => {
	if (props.disabled) {
		return;
	}
	showModal.value = !showModal.value;
};

watch(() => selected.value, () => {
	transferTypes.value = [selected.value || ''];
});

watch(() => transferTypes.value, () => {
	selected.value = (transferTypes.value.length && transferTypes.value[0]) || null;
});

onBeforeMount((): void => {
	if (props.disabled) resetInput();
	EventBus.$on('HotelOnly:Change', maybeResetInput);
});

onBeforeUnmount((): void => {
	EventBus.$off('HotelOnly:Change', maybeResetInput);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["transfer-type", _ctx.disabled ? 'transfer-type--disabled' : ''])
  }, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(SelectField, {
          key: 0,
          ref: "transferSelectFieldDesktop",
          modelValue: selected.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event)),
          class: "transfer",
          items: types.value,
          label: "Transfer",
          "value-prop": "value",
          icon: "transfer",
          "button-wording": _unref(getLocaleString)('close'),
          "item-any": true,
          disabled: _ctx.disabled,
          "onSelectField:Toggle": onToggle,
          "onSelectField:Clear": clearInput
        }, null, 8 /* PROPS */, ["modelValue", "items", "button-wording", "disabled"]))
      : _createCommentVNode("v-if", true),
    (!isDesktop.value)
      ? (_openBlock(), _createBlock(FormField, {
          key: 1,
          selected: fieldValue.value,
          label: "Transfer",
          "show-modal": showModal.value,
          icon: "transfer",
          "show-toggle-icon": false,
          placeholder: _ctx.disabled ? 'Keine Treffer' : 'Beliebig',
          onClick: toggleModal,
          "onFormField:Clear": _cache[1] || (_cache[1] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["selected", "show-modal", "placeholder"]))
      : _createCommentVNode("v-if", true),
    _createVNode(Modal, {
      modelValue: showModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
      class: "transfer__modal",
      title: "Transfer",
      "onModal:Ok": onOk,
      "onModal:Cancel": onReset
    }, {
      default: _withCtx(() => [
        _createVNode(SelectField, {
          ref: "transferSelectFieldMobile",
          modelValue: _unref(proxy),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(proxy) ? (proxy).value = $event : null)),
          class: "transfer",
          items: types.value,
          manual: true,
          "show-footer": false,
          "allow-clear": true,
          "value-prop": "value",
          icon: "transfer",
          "onSelectField:Clear": _cache[3] || (_cache[3] = ($event: any) => {clearInput(); clearProxyInput()})
        }, null, 8 /* PROPS */, ["modelValue", "items"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ], 2 /* CLASS */))
}
}

})