<template>
	<div
		v-bind="$attrs"
		class="select-field"
	>
		<DropdownField
			ref="dropdown"
			v-model="fieldValue"
			:label="label"
			:board="board"
			:show-footer="showFooter"
			:manual="manual"
			:icon="icon"
			:placeholder="placeholder"
			:disabled="disabled"
			:allow-clear="allowClear"
			:button-wording="buttonWording"
			@DropdownField:Ok="onOk"
			@DropdownField:Cancel="onCancel"
			@DropdownField:Clear="$emit('SelectField:Clear', $event)"
			@DropdownField:OutsideClick="onOk"
			@DropdownField:Toggle="($event) => $emit('SelectField:Toggle', $event)"
		>
			<template #default>
				<ul class="select-field__list">
					<li v-if="loading">
						<Loading class="select-field__loading" />
					</li>
					<li v-if="!loading && items">
						<a
							v-if="isDesktop && itemAny"
							href="#"
							class="select-field__list-item"
							:class="{'is-selected': !proxy, 'is-disabled' : offerCount && totalCount === '0' }"
							@click.prevent="itemAnyClickHandler"
							@keydown.enter="itemAnyClickHandler"
						>
							<span> {{ labelForAny }}</span>
						</a>
					</li>
					<slot v-if="!loading && items">
						<li
							v-for="(item, index) in items"
							:key="index"
						>
							<button
								class="select-field__list-item"
								:class="{'is-selected': proxy === item[valueProp], 'is-disabled' : offerCount && !item[countProp] }"
								@click.prevent="() => select(item)"
								@keydown.enter="() => select(item)"
							>
								{{ item[labelProp] }}
								<span
									v-if="offerCount && item[countProp] !== null"
									:class="offerCountClass"
								>
									({{ pluralizedLabel(item[countProp]) }})
								</span>
								<span
									v-if="item.price"
									style="float: right;"
								>
									ab {{ formatPrice(item.price.Amount, item.price.Currency, false) }}
								</span>
							</button>
						</li>
					</slot>
				</ul>
			</template>
		</DropdownField>
	</div>
</template>

<script lang="ts" setup>

import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import { pluralize } from '@/js/utils/utils';
import { formatPrice } from '@/js/utils/priceUtils';
import { CountLabel } from '@global-js/constants';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import Loading from '@lmt-rpb/Loading/Loading.vue';

interface Props {
	modelValue?: string | number | null,
	label?: string,
	icon?: string,
	valueProp?: string,
	labelProp?: string,
	countLabel?: CountLabel,
	countProp?: string,
	items: any[],
	board?: boolean,
	showFooter?: boolean,
	disabled?: boolean,
	manual?: boolean,
	placeholder?: string,
	offerCount?: boolean,
	offerCountClass?: string,
	noNullOption?: boolean,
	allowClear?: boolean,
	buttonWording?: string,
	itemAny?: boolean,
	labelForAny?: string,
	totalCount?: number | string,
	loading?: boolean;

}

const props = withDefaults(defineProps<Props>(), {
	modelValue: null,
	label: '',
	icon: '',
	valueProp: 'id',
	countLabel: () => ({
		singular: '',
		plural: '',
	}),
	labelProp: 'label',
	countProp: 'Count',
	board: false,
	showFooter: true,
	disabled: false,
	manual: undefined,
	placeholder: 'Beliebig',
	offerCount: false,
	offerCountClass: '',
	noNullOption: false,
	allowClear: false,
	buttonWording: 'Übernehmen',
	itemAny: false,
	labelForAny: 'Beliebig',
	totalCount: '',
	loading: false,
});

const emit = defineEmits(['update:modelValue', 'SelectField:Clear', 'SelectField:Toggle']);

const store = useStore();

const isDesktop = computed(() => store.state.config.isDesktop);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const proxy = ref<string | number | null>(model.value || null);

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const fieldValue = computed((): string => {
	if (!model.value) {
		return '';
	}

	const selectedItem = props.items.find((item) => item[props.valueProp] === model.value);

	if (!selectedItem) {
		return '';
	}

	return selectedItem[props.labelProp];
});

const select = (item: any) => {
	// prevent click if offerCount true and count is zero
	if (props.offerCount && !item[props.countProp]) {
		return;
	}

	if (model.value === item[props.valueProp] && !props.noNullOption) {
		model.value = null;
	} else {
		model.value = item[props.valueProp];
	}
};

const onOk = () => {
	model.value = proxy.value;
	dropdown.value?.closeDropdown();
};

const onCancel = () => {
	proxy.value = model.value;
	dropdown.value?.closeDropdown();
};

const itemAnyClickHandler = (event: Event): void => {
	if (props.offerCount && props.totalCount === '0') {
		return;
	}

	emit('SelectField:Clear', event);
};

const pluralizedLabel = (num: number): string => {
	if (props.countLabel) return pluralize(num, props.countLabel.singular, props.countLabel.plural);
	return String(num);
};

watch(() => model.value, () => {
	proxy.value = model.value;
}, { immediate: true });

defineExpose({
	fieldValue,
	select
});

</script>

<style lang="scss" scoped>
.select-field {
	&__loading {
		margin: 0 auto;
	}

	.select-field__list {
		margin: 0;
		padding: 0;
		overflow: auto;
		list-style: none;
	}

	.select-field__list-item {
		display: block;
		width: 100%;
		padding: 1.2rem 0.5rem 1.2rem 4.4rem;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		text-decoration: none;

		&:active,
		&:focus {
			color: $color-primary-l1;
		}

		&:hover {
			background: $color-primary-l6;
		}

		&.is-selected {
			background: $color-primary-l1;
			color: $dropdown-active-color;
		}

		&.is-disabled {
			cursor: default;
			background: $color-page-background;
			color: $color-state-disabled;

			&:active,
			&:focus {
				background: $color-page-background;
			}

			&:hover {
				background: $color-page-background;
			}
		}
	}
}
</style>
