import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "travelers" }

import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep, pluralize } from '@utils/utils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { Travelers } from '@/components/common/types';
import TravelersFieldContent from './TravelersFieldContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TravelersField',
  setup(__props, { expose: __expose }) {

const store = useStore();

const maxTravelDurationError = computed({
	get() {
		return store.state.searchMask.maxTravelDurationError;
	},
	set(value) {
		store.commit('searchMask/updateFormData', {
			maxTravelDurationError: value,
		});
	}
});

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const showModal = ref(false);

const travelers = computed({
	get() {
		return store.state.searchMask.travelers;
	},
	set(value: Travelers) {
		store.commit('searchMask/updateFormData', { travelers: value, filter: '' });
	}
});
const travelerData = ref<Travelers>(cloneDeep(travelers.value));

const isDesktop = computed(() => store.state.config.isDesktop);

const fieldLabel = computed((): string => {
	const adultLabel = isDesktop.value ? `${travelers.value.adult} Erw.` : pluralize(travelers.value.adult, 'Erwachsener', 'Erwachsene');
	const childLabel = pluralize(travelers.value.children.length, 'Kind', 'Kinder');

	return `${adultLabel} ${childLabel}`;
});

const onOk = () => {
	travelers.value = cloneDeep(travelerData.value);

	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onCancel = () => {
	travelerData.value = cloneDeep(travelers.value);

	if (dropdown.value) {
		dropdown.value.closeDropdown();
	}
};

const onClear = () => {
	travelers.value = { adult: 2, children: [] };
};

watch(() => travelers.value, () => {
	if (JSON.stringify(travelers.value) !== JSON.stringify(travelerData.value)) {
		travelerData.value = cloneDeep(travelers.value);
	}
}, { immediate: true });

__expose({
	travelerData,
	travelers
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isDesktop.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "dropdown",
          ref: dropdown,
          modelValue: fieldLabel.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fieldLabel).value = $event)),
          class: "travelers__dropdown",
          label: "Reisende",
          icon: "group",
          wide: true,
          "max-travel-duration-error": maxTravelDurationError.value,
          "onDropdownField:Ok": onOk,
          "onDropdownField:Cancel": onCancel,
          "onDropdownField:OutsideClick": onOk,
          "onDropdownField:Clear": onClear
        }, {
          default: _withCtx(() => [
            _createVNode(TravelersFieldContent, {
              modelValue: travelerData.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((travelerData).value = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "max-travel-duration-error"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(FormField, {
            class: "travelers__field",
            label: "Reisende",
            selected: fieldLabel.value,
            "show-modal": showModal.value,
            "show-toggle-icon": false,
            icon: "group",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showModal.value = !showModal.value)),
            "onFormField:Clear": onClear
          }, null, 8 /* PROPS */, ["selected", "show-modal"]),
          _createVNode(Modal, {
            modelValue: showModal.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((showModal).value = $event)),
            title: "Reisende",
            class: "travelers-modal",
            "onModal:Ok": onOk,
            "onModal:Cancel": onCancel,
            "onModal:Close": onCancel
          }, {
            default: _withCtx(() => [
              _createVNode(TravelersFieldContent, {
                modelValue: travelerData.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((travelerData).value = $event))
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})