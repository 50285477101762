import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-currency", "data-price"]
const _hoisted_2 = ["href", "title", "target", "data-ga-item_id"]
const _hoisted_3 = { class: "quickpill__headline" }
const _hoisted_4 = {
  key: 0,
  class: "quickpill__price"
}

import { formatPrice } from '@/js/utils/priceUtils';
import { computed } from 'vue';

interface Props {
	destination: string,
	url?: string,
	linkTarget?: string,
	price?: string,
	currency?: string,
	titleTag?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickPill',
  props: {
    destination: {},
    url: { default: '' },
    linkTarget: { default: '' },
    price: { default: '' },
    currency: { default: 'EUR' },
    titleTag: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const priceFormatted = computed((): string => {
	if (Number.isNaN(props.price)) {
		return props.price;
	}
	return formatPrice(Number(props.price), props.currency);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    "data-currency": _ctx.currency,
    "data-price": _ctx.price,
    class: "quickpill"
  }, [
    _createElementVNode("a", {
      href: _ctx.url,
      title: _ctx.titleTag,
      target: _ctx.linkTarget,
      "data-ga-event": "select_content",
      "data-ga-content_type": "Quickpill",
      "data-ga-item_id": _ctx.titleTag
    }, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.destination), 1 /* TEXT */),
      (_ctx.price)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " ab " + _toDisplayString(priceFormatted.value), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 8 /* PROPS */, _hoisted_2)
  ], 8 /* PROPS */, _hoisted_1))
}
}

})