<template>
	<div class="room-type">
		<DropdownField
			v-if="isDesktop"
			ref="dropdown"
			v-model="fieldValue"
			label="Zimmertyp"
			:room-type="true"
			icon="room"
			:button-wording="getLocaleString('close')"
			@DropdownField:Ok="onOk"
			@DropdownField:Cancel="onReset"
			@DropdownField:Clear="clearInput"
			@DropdownField:OutsideClick="onOk"
			@DropdownField:open="isHotelPage && requestUpsellingData()"
		>
			<template #default>
				<div class="room-type__checkbox-list">
					<div
						v-if="isfetchUpsellingList"
					>
						<Loading class="room-type__loading" />
					</div>
					<template
						v-else
					>
						<div
							v-for="type in roomList"
							:key="type.id"
							class="room-type__checkbox-container"
							:class="{'is-disabled' : isEntryDisabled(type)}"
						>
							<Checkbox
								v-model="roomTypes"
								class="room-type__checkbox-item"
								:label="type.label"
								:custom-value="type.value"
							/>
							<span
								v-if="type.price"
								class="room-type__price"
							>ab {{ getPriceCorrectCurrency(type.price) }} </span>
						</div>
					</template>
				</div>
			</template>
		</DropdownField>

		<FormField
			v-if="!isDesktop"
			:selected="fieldValue"
			:show-modal="showModal"
			label="Zimmertyp"
			icon="room"
			:show-toggle-icon="false"
			@click="toggleModal"
			@FormField:Clear="clearInput(); clearProxyInput()"
		/>

		<Modal
			v-model="showModal"
			class="room-type__modal"
			title="Zimmertyp"
			@Modal:Ok="onOk"
			@Modal:Cancel="onReset"
		>
			<div
				v-if="isfetchUpsellingList"
			>
				<Loading class="room-type__loading" />
			</div>
			<template
				v-else
			>
				<div
					v-for="type in roomList"
					:key="type.id"
					class="room-type__checkbox-container"
					:class="{'is-disabled' : isEntryDisabled(type)}"
				>
					<Checkbox
						v-model="roomTypes"
						class="room-type__checkbox-item"
						:label="type.label"
						:custom-value="type.value"
					/>
					<span
						v-if="type.price && isHotelPage"
						class="room-type__price"
					>ab {{ getPriceCorrectCurrency(type.price) }} </span>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script lang="ts" setup>
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import { cloneDeep } from '@utils/utils';
import { getLocaleString } from '@utils/environmentUtils';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import type { UpsellingRoom } from '@/interfaces/search-form';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import { getOfferUpsellingRoom as requestUpsellingRoomList } from '@/components/common/services/bestOfferRoomService';
import { getPriceCorrectCurrency } from '@/js/utils/priceUtils';
import type { RoomTypesType } from '@/components/common/types';

interface Props {
	isHotelPage?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	isHotelPage: false,
});

const store = useStore();

const showModal = ref(false);

const proxy = ref<any[]>([]);

const upsellingList = ref([] as RoomTypesType[]);

const isDesktop = computed((): boolean => store.state.config.isDesktop);

const defaultTypes = computed((): RoomTypesType[] => store.state.types.room);

const roomList = computed(() => (props.isHotelPage ? upsellingList.value : defaultTypes.value));

const dropdown = ref<InstanceType<typeof DropdownField> | null>(null);

const isfetchUpsellingList = ref(false);

const upsellingResponseError = ref(false);

const isEntryDisabled = (entry: RoomTypesType) => !entry.price && props.isHotelPage && !upsellingResponseError.value;

const roomTypes = computed({
	get(): string[] {
		return store.state.searchMask.roomTypes;
	},

	set(value: string[]) {
		store.commit('searchMask/updateFormData', { roomTypes: value });
	}
});

const fieldValue = computed((): string => roomTypes.value.map((value: string) => defaultTypes.value.find((x) => x.value === value).label).join(', '));

const closeDropdown = () => {
	if (dropdown.value) {
		dropdown.value.toggleDropdown();
	}
};

const onOk = () => {
	roomTypes.value = proxy.value;
	closeDropdown();
};

const onReset = () => {
	store.dispatch('searchMask/rollbackToSavedCurrentState');
	closeDropdown();
};

const getUpsellingList = (responseOffers: UpsellingRoom[]) => {
	const roomTypesUpdate: RoomTypesType[] = [];
	cloneDeep(defaultTypes.value).forEach((item) => {
		const responseItem = responseOffers.find((respItem: any) => respItem.Room.Type === item.value);
		if (responseItem) {
			item.price = responseItem?.Price;
			roomTypesUpdate.push(item);
		}
		const selectedItem = fieldValue.value.split(',').find((field) => field.trim() === item.label.trim());
		if (!responseItem && selectedItem) {
			roomTypesUpdate.push(item);
		}
	});
	return roomTypesUpdate;
};

const requestUpsellingData = async() => {
	isfetchUpsellingList.value = true;
	upsellingResponseError.value = false;
	try {
		const responseOffers = await requestUpsellingRoomList();
		if (responseOffers.length > 0) {
			upsellingList.value = getUpsellingList(responseOffers);
		} else {
			upsellingList.value = defaultTypes.value;
		}
	} catch (error) {
		upsellingList.value = defaultTypes.value;
		upsellingResponseError.value = true;
		console.error(error);
	} finally {
		isfetchUpsellingList.value = false;
	}
};

const toggleModal = () => {
	showModal.value = !showModal.value;
	if (showModal.value) {
		store.dispatch('searchMask/saveCurrentState');
		proxy.value = store.state.searchMask.roomTypes;
	}
	if (showModal.value && props.isHotelPage) {
		requestUpsellingData();
	} else {
		upsellingList.value = defaultTypes.value;
	}
};

const clearInput = () => {
	roomTypes.value = [];
};

const clearProxyInput = () => {
	proxy.value = [];
};

watch(() => roomTypes.value, () => {
	proxy.value = roomTypes.value;
}, { immediate: true });

</script>

<style lang="scss" scoped>
.room-type {
	&__loading {
		margin: 0 auto;
	}

	&__price {
		color: $color-extra;
		margin-right: 1rem;
		font-weight: $font-weight-semibold;
		font-size: $font-small-1;
	}

	.room-type__icon {
		width: 3rem;
		height: 3rem;
		margin-right: 1rem;
		fill: $color-primary-l1;
	}

	.room-type__checkbox-list {
		overflow: auto;
		display: flex;
		flex-direction: column;

		:deep(.checkbox__icon) {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	:deep(.dropdown__container) {
		@include customScrollbar($color-primary);
	}

	:deep(.modal__body) {
		@include customScrollbar($color-primary);
	}

	.room-type__checkbox-item {
		margin: 1rem 2rem;
		font-size: $font-small-1;
	}

	.room-type__checkbox-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.room-type__checkbox-container.is-disabled {
		:deep(.checkbox__label) {
			&::before {
				border-color: $color-state-disabled;
			}

			color: $color-state-disabled;
			fill: $color-state-disabled;
		}

		:deep(.checkbox__icon) {
			color: $color-state-disabled;
			fill: $color-state-disabled;
		}

		:deep(.checkbox__input:focus + .checkbox__label::before) {
			box-shadow: 0 0 0 0.1rem $color-state-disabled;
		}
	}
}
</style>
