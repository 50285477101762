import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "hotel-category" }
const _hoisted_2 = ["onClick"]

import { watch, ref, computed } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelCategory',
  props: {
	showSuns: {
		type: Boolean,
		default: false
	},
	value: {
		type: Number,
		default: 0,
		validator: (value: number) => value >= 0 && value <= 5
	}
},
  setup(__props) {

const store = useStore();

const props = __props;

const rate = ref(props.value);

const icon = computed((): 'star' | 'sun' => (props.showSuns ? 'sun' : 'star'));

watch(() =>	props.value, () => {
	rate.value = props.value;
});

const	onClick = (index: number) => {
	rate.value = rate.value === index ? 0 : index;
	store.commit('searchMask/updateFormData', { hotelCategory: rate.value });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (index) => {
      return _createElementVNode("span", {
        key: `hotel-category-icon-${index}`,
        class: "hotel-category__item",
        onClick: ($event: any) => (onClick(index))
      }, [
        _createVNode(BaseIcon, {
          name: icon.value,
          class: _normalizeClass(["hotel-category__icon", {'is-active': index <= rate.value }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("title", null, "Hotelkategorie " + _toDisplayString(index), 1 /* TEXT */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name", "class"])
      ], 8 /* PROPS */, _hoisted_2)
    }), 64 /* STABLE_FRAGMENT */))
  ]))
}
}

})