<template>
	<section class="recommendation-rate">
		<span
			v-for="rateValue in rates"
			:key="`recommendation-rate__item-${rateValue}`"
			class="hotel-category__item"
			@click="onClick(rateValue)"
		>
			<RoundedButton
				theme="outlined"
				class="recommendation-rate__button"
				:class="{'is-active': rateValue === rate}"
			>
				{{ `${rateValue}%` }}
			</RoundedButton>
		</span>
	</section>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { useStore } from '@/components/common/store';

const store = useStore();

interface Props {
	value: number
}

const props = withDefaults(defineProps<Props>(), {
	value: 0,
});

const rates = [70, 80, 90, 95];
const rate = ref(props.value);

const onClick = (value: number) => {
	rate.value = rate.value === value ? 0 : value;
	store.commit('searchMask/updateFormData', { minMeanRecommendationRate: rate.value });
};

watch(() => props.value, () => {
	rate.value = props.value;
});

</script>

<style lang="scss" scoped>
.recommendation-rate {
	display: flex;
	gap: 0.6rem;

	&__button.button {
		padding: 0 1.3rem;
		font-size: $font-medium-1;
		color: $color-text-l24;
		font-weight: $font-weight-semibold;
		border-color: $color-primary-l4;

		&:hover,
		&:focus,
		&:active {
			color: $color-text-l24;
			background: $color-white;
		}

		&.is-active {
			color: $color-white;
			background: $recommendation-rate-active;
		}
	}
}

@media (min-width: $breakpoint-mobilelarge) {
	.recommendation-rate {
		gap: 1rem;
	}
}
</style>
