import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-background" }
const _hoisted_2 = { class: "confirm__modal" }
const _hoisted_3 = { class: "confirm__title" }
const _hoisted_4 = {
  key: 0,
  class: "confirm__title-addition"
}
const _hoisted_5 = { class: "confirm__footer" }
const _hoisted_6 = { class: "confirm__footer-btn" }
const _hoisted_7 = { class: "confirm__footer-btn" }

import {
	onMounted, nextTick, onUnmounted,
} from 'vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';

interface Props {
	title?: string,
	addition?: string,
	ok?: string,
	cancel?: string
}


export default /*@__PURE__*/_defineComponent({
  ...{
	inheritAttrs: false
},
  __name: 'Confirm',
  props: {
    title: { default: 'title' },
    addition: { default: 'addition' },
    ok: { default: 'ok' },
    cancel: { default: 'cancel' }
  },
  emits: ['Confirm:Ok', 'Confirm:Cancel', 'Confirm:BrowserBack'],
  setup(__props: any, { emit: __emit }) {



const props = __props;

const emit = __emit;

const close = () => {
	document.body.style.overflow = 'auto';
	return false;
};

const onOk = () => {
	emit('Confirm:Ok', close());
};

const onCancel = () => {
	emit('Confirm:Cancel', close());
};

const onBack = () => {
	emit('Confirm:BrowserBack', close());
};

const tabNextElement = (event: Event, currentElement: HTMLElement, confirmButton: HTMLElement, cancelButton: HTMLElement) => {
	if (currentElement === confirmButton) {
		cancelButton?.focus();
		event.preventDefault();
	} else {
		confirmButton?.focus();
		event.preventDefault();
	}
};

const handleKeydown = (event: KeyboardEvent) => {
	const currentElement = document?.activeElement as HTMLElement;
	const confirmButton = document.querySelector('.is-ok') as HTMLElement;
	const cancelButton = document.querySelector('.is-cancel') as HTMLElement;

	if ((event.shiftKey && event.key === 'Tab') || event.key === 'Tab') {
		tabNextElement(event, currentElement, confirmButton, cancelButton);
	}
};
const handlePopState = (event: Event) => {
	event.preventDefault();
	onBack();
};

onMounted(() => {
	document.addEventListener('keydown', handleKeydown);
	window.addEventListener('popstate', handlePopState);
	/* eslint no-restricted-globals: ["error", "event", "fdescribe"] */
	if (!window.history.state) {
		history.pushState('true', '');
	}
	document.body.style.overflow = 'hidden';
	nextTick(() => {
		const confirmButton = document.querySelector('.confirm__button.is-cancel');
		(confirmButton as HTMLElement)?.focus();
	});
});

onUnmounted(() => {
	document.removeEventListener('keydown', handleKeydown);
	window.removeEventListener('popstate', handlePopState);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "#confirm-dialog" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, [
          _createTextVNode(_toDisplayString(props.title) + " ", 1 /* TEXT */),
          (props.addition != 'addition')
            ? (_openBlock(), _createElementBlock("p", _hoisted_4))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(RoundedButton, {
              type: "button",
              theme: "outlined",
              class: "confirm__button is-cancel",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (onCancel()), ["stop"])),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (onCancel()), ["stop","prevent"]), ["enter"]))
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "cancel-icon", { class: "icon" }),
                _createTextVNode(" " + _toDisplayString(props.cancel), 1 /* TEXT */)
              ]),
              _: 3 /* FORWARDED */
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(RoundedButton, {
              type: "button",
              theme: "outlined",
              class: "confirm__button is-ok",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (onOk()), ["stop"])),
              onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (onOk()), ["stop","prevent"]), ["enter"]))
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "ok-icon"),
                _createTextVNode(" " + _toDisplayString(props.ok), 1 /* TEXT */)
              ]),
              _: 3 /* FORWARDED */
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})