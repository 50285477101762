import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "price-summary" }
const _hoisted_2 = {
  key: 0,
  class: "price-summary__overview"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "price-summary__row" }
const _hoisted_5 = { class: "price-summary__tooltip" }
const _hoisted_6 = { class: "price-summary__discount-price" }
const _hoisted_7 = {
  key: 2,
  class: "price-summary__row price-summary__storno-price"
}
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "price-summary__row price-summary__row--travel-price" }
const _hoisted_10 = {
  key: 4,
  class: "price-summary__row price-summary__insurance-price"
}
const _hoisted_11 = {
  key: 5,
  class: "price-summary__best-price-badge"
}
const _hoisted_12 = { class: "price-summary__row price-summary__row--total" }
const _hoisted_13 = { class: "checkout-price-__sum-container" }
const _hoisted_14 = { class: "price-summary__sum" }
const _hoisted_15 = {
  key: 0,
  class: "price-summary__sum-converted"
}
const _hoisted_16 = { class: "price-summary__disclaimer" }
const _hoisted_17 = {
  key: 0,
  class: "price-summary__disclaimer price-summary__disclaimer--extra"
}
const _hoisted_18 = {
  key: 6,
  class: "price-summary__payments"
}
const _hoisted_19 = ["src", "title", "alt"]

import { FormattedTravelerPrice, SimplePrice } from '@/interfaces/checkout/offer-data';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import DiscountBadge from '@lmt-rpb/DiscountBadge/DiscountBadge.vue';
import { computed } from 'vue';

type Props = {
	paymentTypes?: Record<string, string>[],
	storno?: SimplePrice | undefined,
	isPackage?: boolean,
	isInfoBox?: boolean,
	isChf?: boolean,
	discount?: number,
	finalPriceWithCurrency: string,
	actualTravellerPrices: FormattedTravelerPrice[],
	withOverviewHeader?: boolean,
	totalFormatted: string,
	flexPrice: string,
	travelPriceWithDiscountFormatted: string,
	insurancePrice: string,
	insurance?: { insuranceName?: string, priceInformation?: SimplePrice }
	travelPriceFormatted: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PriceSummary',
  props: {
    paymentTypes: {},
    storno: {},
    isPackage: { type: Boolean },
    isInfoBox: { type: Boolean },
    isChf: { type: Boolean },
    discount: {},
    finalPriceWithCurrency: {},
    actualTravellerPrices: {},
    withOverviewHeader: { type: Boolean },
    totalFormatted: {},
    flexPrice: {},
    travelPriceWithDiscountFormatted: {},
    insurancePrice: {},
    insurance: {},
    travelPriceFormatted: {}
  },
  setup(__props: any) {

const props = __props;

const showTravelPrice = computed(() => {
	const entries = (props.actualTravellerPrices.length ?? 0) + (props.flexPrice ? 1 : 0);
	return props.insurancePrice && entries >= 2;
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.withOverviewHeader)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Preisübersicht "))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualTravellerPrices, (traveller) => {
      return (_openBlock(), _createElementBlock("div", {
        key: traveller.Label,
        class: "price-summary__row price-summary__traveller-price"
      }, [
        _createElementVNode("p", null, _toDisplayString(traveller.Label), 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString(traveller.Price), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.discount)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(DiscountBadge, { class: "price-summary__discount-badge" }),
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("sup", null, [
                _createVNode(BaseIcon, { name: "info" })
              ]),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.travelPriceFormatted), 1 /* TEXT */)
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.storno)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "flexible Stornierungsoption", -1 /* HOISTED */)),
          _createElementVNode("p", null, _toDisplayString(_ctx.flexPrice), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (showTravelPrice.value)
      ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "price-summary__divider" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_9, [
            _cache[1] || (_cache[1] = _createElementVNode("p", null, " Reisepreis ", -1 /* HOISTED */)),
            _createElementVNode("p", null, _toDisplayString(_ctx.travelPriceWithDiscountFormatted), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.insurancePrice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(_ctx.insurance?.insuranceName), 1 /* TEXT */),
          _createElementVNode("p", null, _toDisplayString(_ctx.insurancePrice), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "price-summary__divider price-summary__divider--total" }, null, -1 /* HOISTED */)),
    (_ctx.isPackage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(BaseIcon, { name: "bestPriceWarranty" }),
          _cache[3] || (_cache[3] = _createTextVNode(" Bestpreisgarantie "))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_12, [
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "price-summary__total" }, " Gesamtpreis ", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.totalFormatted), 1 /* TEXT */),
        (_ctx.isChf)
          ? (_openBlock(), _createElementBlock("p", _hoisted_15, " (" + _toDisplayString(_ctx.finalPriceWithCurrency) + ") ", 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("p", _hoisted_16, [
      _cache[5] || (_cache[5] = _createTextVNode(" Gesamtpreis einschließlich Steuern und Gebühren. Eventuell erfolgt vor Ort die Berechnung einer zu bezahlenden Kurtaxe. ")),
      (_ctx.isChf)
        ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Der Vertrag wird in EURO geschlossen. Die Angabe in CHF dient lediglich Ihrer Information und ist ein Circa-Wert. "))
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.isInfoBox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTypes, (item, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: index,
              class: "price-summary__payment-img",
              src: item.img,
              title: item.name,
              alt: item.name
            }, null, 8 /* PROPS */, _hoisted_19))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})